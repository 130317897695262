import { States } from "../../../app.states";
import {
    Component,
    SessionService,
    AppStateService,
    UrlService
} from "../../../core/index";

import styles from "./payment-header.scss";

@Component({
    selector: "becPaymentHeader",
    template: require("./payment-header.html"),
    bindings: {
        disablePaymentCombobox: "@"
    }
})
export class PaymentHeaderController {
    static $inject = ["SessionService", "AppStateService", "$state", "UrlService"];

    constructor(
        public session: SessionService,
        public appState: AppStateService,
        private $state: ng.ui.IStateService,
        private urlService: UrlService) {
        this.disablePaymentCombobox = !(session.isPaymentCardAvailable && session.isInvoiceAvailable);
    }

    disablePaymentCombobox = false;
    styles = styles;

    showDetails = false;

    toggleDetails() {
        this.showDetails = !this.showDetails;
        const showingDetails = this.showDetails;
        window.setTimeout(function () {
            let buttonId = showingDetails ? "hideDetailsButton" : "showDetailsButton";
            document.getElementById(buttonId).focus();
        }, 0);

    }

    get hasDeclineUrl() {
        return this.urlService.declineUrl != null
    }

    get orderLines() {
        try {
            return this.session.order.lines;
        } catch (e) {
            return [];
        }
    }

    get isAcceptPage() {
        switch (this.$state.current.name) {
            case States.Session.Accept.name:
            case States.Session.PaymentCard.Accept.name:
            case States.Session.Invoice.Accept.name:
            case States.Session.PartPayment.Accept.name:
            case States.Session.Swish.Accept.name:
            case States.Session.ViaBill.Accept.name:
            case States.Session.EkspresBank.Accept.name:
            case States.Session.MobilePay.Accept.name:
            case States.Session.Vipps.Accept.name:
            case States.Session.ApplePay.Accept.name:
            case States.Session.GooglePay.Accept.name:
            case States.Session.NordeaEPaymentFI.Accept.name:
                return true;
        }

        return false;
    }

    get hideHeader() {
        return this.$state.current.name === States.Demo.name || this.$state.current.name === States.Wait.name;
    }

    get hideCancelLink() {
        return !this.hasDeclineUrl || this.isAcceptPage || this.session.isExpired || this.appState.isLoading || this.$state.current.name === States.Session.PaymentRequest.name || this.$state.current.name === States.PaymentRequest.name;
    }
}
