import * as angular from "angular";
import { DeltaFoxService, ConfigReaderService, AppStateService } from "../index";

import Meta = Server.meta;

export interface IExpressCheckoutService {
  setExpressCheckout(setexpresscheckoutrequest: Server.checkoutrequest, apikey: string): ng.
    IPromise<Server.expresstokenresponse>;
  getExpressCheckout(token: string): angular.IPromise<Server.checkoutresponse>;
  getError(token: string, transactionId: string): angular.IPromise<Server.geterrorresponse>;
  authenticateConfirmationCode(token: string, confirmationCode: string): angular.IPromise<Server.confirmresponse>;
  authorizePaymentCard(token: string, paymentcardauthorizerequest: Server.paymentcardauthorizerequest): angular.
    IPromise<Server.authorizeresponse>;
  authorizeTruncatedCard(token: string,
    truncatedpaymentcardauthorizerequest: Server.truncatedpaymentcardauthorizerequest): angular.
    IPromise<Server.authorizeresponse>;
  deleteTruncatedCard(token: string, paymentoptiondeleterequest: Server.truncatedpaymentcarddeleterequest):
    angular.IPromise<Server.confirmresponse>;
  createCustomer(token: string, createcustomerrequest: Server.createcustomerrequest): angular.
    IPromise<Server.createcustomerresponse>;
  deleteCustomer(token: string): angular.IPromise<IResponse>;
  sendPaymentConfirmationSms(token: string): angular.IPromise<any>;
  // creditinfoquestions(token: string): angular.IPromise<Server.creditinfoquestionnarieresponse> ;
  getIpInfo(): angular.IPromise<Server.ipinforesponse>;
  getSessionIpInfo(token: string): angular.IPromise<Server.ipinforesponse>;
  getPaymentType(token: string, cardnumberPrefix: string): angular.IPromise<Server.paymenttyperesponse>;
  authorizeEkspresBank(token: string, ekspresbankauthorizerequest: Server.ekspresbankauthorizerequest): angular.IPromise<Server.authorizeresponse>;
  authorizeSwish(token: string, swishauthorizerequest: Server.swishauthorizerequest): angular.IPromise<Server.authorizeresponse>;
  authorizeViaBill(token: string, viabillauthorizerequest: Server.viabillauthorizerequest): angular.IPromise<Server.authorizeresponse>;
  authorizeNordeaEPaymentFI(token: string, nordeaepaymentfiauthorizerequest: Server.nordeaepaymentfiauthorizerequest): angular.IPromise<Server.authorizeresponse>;
  createWalletSession(token: string, walletname: string, request: Server.createwalletsessionrequest): angular.IPromise<Server.createwalletsessionresponse>;
}

export interface IResponse {
  meta: Meta
}

export class ExpressCheckoutService implements IExpressCheckoutService {
  static $inject = [
    "$http",
    "$cacheFactory",
    "$q",
    "DeltaFoxService",
    "ConfigReaderService",
    "gettextCatalog",
    "AppStateService"
  ];
  constructor(
    private $http: ng.IHttpService,
    $cacheFactory: ng.ICacheFactoryService,
    private $q,
    private _deltaFoxService: DeltaFoxService,
    private configReader: ConfigReaderService,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private appState: AppStateService
  ) {
    $cacheFactory("request-cache");
    this.requestCache = $cacheFactory.get("request-cache");
    this.serverAddr = configReader.webServiceEndpoint;
  }

  private serverAddr: string;

  static deltaIdHeader = "X-EPay-Delta-Id";
  private requestCache: ng.ICacheObject;

  private invalidateCache() {
    this.requestCache.removeAll();
  }

  private invalidatingRequest(uri) {
    this.invalidateCache();
    return this.$http.get(uri);
  }

  private cachableRequest(uri): any {
    const cached = this.requestCache.get(uri);
    if (cached) {

      return cached;
    }
    const promise = this.$http.get(uri);
    this.requestCache.put(uri, promise);
    return promise;

  }

  private updateDeltaId(response: ng.IHttpPromiseCallbackArg<any>) {
    const deltaId = response.headers(ExpressCheckoutService.deltaIdHeader);

    if (deltaId) {
      this._deltaFoxService.setDeltaFoxId(deltaId);
    }
  }

  private request(uri) {
    return this.$http.get(uri);
  }

  get xEpaySystemHeader() {
    let env = this.configReader.environment === "Production" ? "" : "-" + this.configReader.environment;
    let xEpaySystem = "Checkout" + env + "/" + this.configReader.version;

    if (this.appState.options.version) {
      xEpaySystem += " CheckoutSDKWeb/" + this.appState.options.version;
    }

    return xEpaySystem;
  }

  setExpressCheckout(setexpresscheckoutrequest: Server.checkoutrequest, apikey: string): ng.
    IPromise<Server.expresstokenresponse> {
    const config = {
      headers: {
        "Authorization": `Basic ${apikey}`,
        "X-EPay-System": this.xEpaySystemHeader
      },
      data: setexpresscheckoutrequest,
      url: this.serverAddr + "/checkout",
      method: "POST"
    };


    return this.$http<any>(config)
      .then((response) => {
        return response.data;
      },
        (response) => {
          if (response.data && response.data.meta) {
            return this.buildClientResponse(response.data);
          } else {
            return this.buildClientResponse(response.data);
          }
        });
  }

  getExpressCheckout(token: string): angular.IPromise<Server.checkoutresponse> {
    return this.$http.get(this.serverAddr + "/checkout/" + token, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.checkoutresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  getError(token: string, transactionId: string): angular.IPromise<Server.geterrorresponse> {
    return this.$http.get(this.serverAddr + "/checkout/" + token + "/transactionerror/" + transactionId, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.geterrorresponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  authenticateConfirmationCode(token: string, confirmationCode: string): angular.
    IPromise<Server.confirmresponse> {
    return this.$http.get(this.serverAddr + "/checkout/" + token + "/authenticate/" + confirmationCode, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.confirmresponse>) => {
        return this.buildClientResponse(response.data);
      },
        (response) => {

          return this.buildClientResponse(response.data);
        });
  }

  authorizePaymentCard(token: string, paymentcardauthorizerequest: Server.paymentcardauthorizerequest): angular.
    IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/paymentcard",
      paymentcardauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;

      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  authorizeTruncatedCard(token: string,
    truncatedpaymentcardauthorizerequest: Server.truncatedpaymentcardauthorizerequest): angular.
    IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/truncatedcard",
      truncatedpaymentcardauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  authorizeInvoicePaymentOption(token: string, request: Server.invoiceauthorizerequest): angular.
    IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/invoice", request, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  deleteTruncatedCard(token: string, paymentoptiondeleterequest: Server.truncatedpaymentcarddeleterequest):
    angular.IPromise<Server.confirmresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/delete/truncatedcard",
      paymentoptiondeleterequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.confirmresponse>) => {
        return this.buildClientResponse(response.data);
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  createCustomer(token: string, createcustomerrequest: Server.createcustomerrequest): angular.
    IPromise<Server.createcustomerresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/customer", createcustomerrequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.createcustomerresponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  deleteCustomer(token: string, deleteCustomer: boolean = false): angular.IPromise<IResponse> {
    const deleteString = deleteCustomer ? "1" : "0";
    return this.$http.delete(`${this.serverAddr}/checkout/${token}/customer?delete=${deleteString}`, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<IResponse>) => {
        return this.buildClientResponse(response.data);
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  getIpInfo(): angular.IPromise<Server.ipinforesponse> {
    return this.$http.get(this.serverAddr + "/ipinfo", { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.ipinforesponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  getSessionIpInfo(token: string): angular.IPromise<Server.ipinforesponse> {
    return this.$http.get(`${this.serverAddr}/sessions/${token}/ipinfo`, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.ipinforesponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  setSsnAndGetOfficialAddress(token: string, ssnRequest: Server.ssnrequest): angular.
    IPromise<Server.customerinforesponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/ssn", ssnRequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.customerinforesponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  getPaymentType(token: string, cardnumberPrefix: string): angular.
    IPromise<Server.paymenttyperesponse> {
    return this.$http.get(`${this.serverAddr}/checkout/${token}/paymenttypes?cardnumberprefix=${cardnumberPrefix}`, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<IResponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        })
  }

  //getPaymentTypes(token: string): angular.IPromise<Server.merchantpaymenttypesresponse> {
  //    return this.cachableRequest(this.serverAddr + "/checkout/" + token + "/paymenttypes")
  //        .then((response: ng.IHttpPromiseCallbackArg<Server.merchantpaymenttypesresponse>) => {
  //            return this.buildClientResponse(response.data);
  //        }, (response) => {
  //            return this.buildClientResponse(response.data);
  //        });
  //}

  sendPaymentConfirmationSms(token: string): angular.IPromise<Server.smsresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/sms/paymentconfirmation", null, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.smsresponse>) => {
        return this.buildClientResponse(response.data);
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  sendMembershipConfirmationSms(token: string): angular.IPromise<Server.smsresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/sms/membershipconfirmation", null, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.smsresponse>) => {
        return this.buildClientResponse(response.data);
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }

  //Ekspres Bank
  authorizeEkspresBank(token: string, ekspresbankauthorizerequest: Server.ekspresbankauthorizerequest): angular.IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/ekspresbank",
      ekspresbankauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;

      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  //Swish
  authorizeSwish(token: string, swishauthorizerequest: Server.swishauthorizerequest): angular.IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/swish",
      swishauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;

      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  getSwishQrCode(token: string, swishToken: string): angular.IPromise<Server.getswishqrcode> {
    return this.$http.get(this.serverAddr + "/sessions/" + token + "/swish-qr/" + swishToken,
      { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.getswishqrcode>) => {
        return this.buildClientResponse(response.data) as any;
      },
      (response) => {
        return this.buildClientResponse(response.data);
      });
  }

  //ViaBill
  authorizeViaBill(token: string, viabillauthorizerequest: Server.viabillauthorizerequest): angular.IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/viabill",
      viabillauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;

      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  //NordeaEPaymentFI
  authorizeNordeaEPaymentFI(token: string, nordeaepaymentfiauthorizerequest: Server.nordeaepaymentfiauthorizerequest): angular.
    IPromise<Server.authorizeresponse> {
    return this.$http.post(this.serverAddr + "/checkout/" + token + "/authorize/nordeaepaymentfi",
      nordeaepaymentfiauthorizerequest, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.authorizeresponse>) => {
        this.updateDeltaId(response);
        return this.buildClientResponse(response.data) as any;

      },
        (response) => {
          this.updateDeltaId(response);
          return this.buildClientResponse(response.data);
        });
  }

  // Wallet
  createWalletSession(token: string, walletname: string, request: Server.createwalletsessionrequest): angular.IPromise<Server.createwalletsessionresponse> {
    return this.$http.post(this.serverAddr + "/sessions/" + token + "/wallets/" + walletname, request, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.createwalletsessionresponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }
  // end Wallet

  // Payment Request
  setPaymentRequestSession(paymentrequestid: string): angular.IPromise<Server.setpaymentrequestsessionresponse> {
    return this.$http.post(this.serverAddr + "/sessions/paymentrequest", { id: paymentrequestid }, { headers: { "X-EPay-System": this.xEpaySystemHeader } })
      .then((response: ng.IHttpPromiseCallbackArg<Server.setpaymentrequestsessionresponse>) => {
        return this.buildClientResponse(response.data) as any;
      },
        (response) => {
          return this.buildClientResponse(response.data);
        });
  }
  // End Payment Request

  private buildClientResponse(response: IResponse): IResponse {
    if (response && response.meta != undefined) {
      return response;
    } else {
      return this.transformServerErrorToClientResponseError(response);
    }
  }

  private transformServerErrorToClientResponseError(resp: any): IResponse {
    let errorMeta = null;
    let response: string = "Response is null";
    const noAnswerFromServer = this.gettextCatalog.getString("No answer from server");
    try {
      let errorText: string = noAnswerFromServer;

      if (resp) {
        response = resp.toString();
        const domParser = new DOMParser();
        const document = domParser.parseFromString(response, "text/xml");
        const body = document.getElementsByTagName("body");
        errorText = body[0].innerText;
      }

      errorMeta = {
        result: false,
        message: {
          enduser: errorText,
          merchant: response
        },
        action: null,
        paging: null

      };
    } catch (error) {
      errorMeta = {
        result: false,
        message: {
          enduser: noAnswerFromServer,
          merchant: response
        },
        action: null,
        paging: null
      };
    }
    return { meta: errorMeta };
  }
}
