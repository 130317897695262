import {
  AppStateService,
  Component,
  ExpressCheckoutService,
  ExternalAuthService,
  SessionService
} from "../../../core/index";

import { States } from "../../../../app/app.states";

import styles from "./swish-fallback.scss";

@Component({
  selector: "becSwishFallback",
  template: require("./swish-fallback.html")
})
export class SwishFallback {
  static $inject = [
    "SessionService",
    "ExpressCheckoutService",
    "$stateParams",
    "AppStateService",
    "ExternalAuthService"
  ];

  qrCode: string;
  url: string;
  token: string;

  constructor(
    public sessionService: SessionService,
    private expressCheckoutService: ExpressCheckoutService,
    private $stateParams,
    private appStateService: AppStateService,
    private externalAuthService: ExternalAuthService) {
  }

  styles = styles;

  $onInit() {
    this.appStateService.startMinorLoading();

    this.url = this.$stateParams.url;
    const parsedUrl = new URL(this.$stateParams.url);
    this.token = parsedUrl.searchParams.get('token');

    this.expressCheckoutService.getSwishQrCode(this.sessionService.sessionToken, this.token)
      .then(
        response => {
          this.appStateService.cancelLoading();

          if (response.meta.result) {
            this.qrCode = "data:image/svg+xml;base64," + response.image;
          }
        },
        () => {
          this.appStateService.cancelLoading();
        });
  }

  cancel() {
    this.externalAuthService.stopPolling();
    this.appStateService.switchState(States.Session.Start.name);
  }
}
