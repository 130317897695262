import { Component, WindowStateService } from "./core/index";

import app from "./app.scss";

const styles = { app: app };

@Component({
  selector: "checkout",
  template: `
    <div class="${styles.app.checkoutContainer}">
        <bec-payment-header ng-if="!$ctrl.windowState.isInline()"></bec-payment-header>
        <div tabindex="0" id="checkout" class="${styles.app.checkout}" bec-overflow-when-loading bec-focus-by-mouse>
            <bec-test-mode></bec-test-mode>

            <div ui-view class="animate-container-height"></div>
            <bec-giraf-loader></bec-giraf-loader>
        </div>
    </div>

    <bec-shake-view></bec-shake-view>`
})

export class AppComponent {
  static $inject = [
    "WindowStateService"
  ];

  constructor(
    public windowState: WindowStateService
  ) { }
}
