import * as angular from "angular";

import {
    AccessibilityService,
    ErrorService,
    WindowStateService
} from "../index";

export class AppStateService {
    pageIsLoaded: boolean;
    isTestMerchant: boolean;
    stateIsResolved: boolean;
    showLoading: boolean;
    girafVisible: boolean;
    showDemoLoader: boolean;
    private _girafMessage: string = "";
    currentState: any;
    showLogoLoader: boolean;
    loaderType: string = "";
    lastAccordionExpandType: string;
    handshakeId: string;
    options: Partial<{ version: string, demo: boolean, appReturnUrl: string; paymentAppsInstalled: string[] }> = {};

    get girafMessage() {
        return this._girafMessage;
    }

    set girafMessage(value: string) {
        this._girafMessage = value;

        this.accessibility.notify(value);
    }

    getAll() {
        const arrayOfProperties = Object.keys(this).sort();
        const all = {};

        for (let i = 0; i < arrayOfProperties.length; i++) {
            if (typeof (this[arrayOfProperties[i]]) === "boolean") {
                all[arrayOfProperties[i]] = this[arrayOfProperties[i]];
            }
        }

        return all;
    }

    static $inject = [
        "WindowStateService", "$window", "gettextCatalog", "ErrorService", "$state", "$rootScope", "$timeout",
        "$stateParams", "AccessibilityService"
    ];

    public constructor(
        public windowState: WindowStateService,
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private errorService: ErrorService,
        private $state: ng.ui.IStateService,
        private $rootScope: ng.IRootScopeService,
        private $timeout,
        private $stateParams,
        private accessibility: AccessibilityService) {

        this.reset();
    }

    reset() {
        this.showLoading = true;
        this.girafVisible = true;
        this.showLogoLoader = false;
        this.startStateChange();
        this.errorService.reset();
    }

    resolveVisibilityFields() {
        this.cancelLoading();
        return this;
    }

    switchState(state: any, args: any = {}, options: any = {}): ng.IPromise<any> {
        let stateName = state;
        if (stateName.hasOwnProperty(name)) {
            stateName = stateName.name;
        }
        if (stateName === this.$state.current.name) return;
        this.startStateChange();
        if (!this.windowState.isFullScreen()) {
            // Propagate the windowstate param unlesss we are on fullscreen
            args.windowstate = this.windowState.currentState;
        }

        if (this.windowState.isInIFrame()) {
            // Dispatch event when shown in overlay, to tell paymentwindow.js that checkout is presentable
            this.$window["parent"].postMessage({ event: "ready" }, "*");
        }

        return this.$state.go(stateName, args, options);
    }

    currentPaymentMethod() {
        if (this.$state.current) {
            const name = this.$state.current.name;
            if (name.indexOf("invoice") > -1) {
                return "Invoice";
            }
            if (name.indexOf("partpayment") > -1) {
                return "PartPayment";
            }
            if (name.indexOf("paymentCard") > -1) {
                return "Paymentcard";
            }
            if (name.indexOf("mobilepay") > -1) {
                return "MobilePay";
            }
            if (name.indexOf("vipps") > -1) {
                return "Vipps";
            }
            if (name.indexOf("applepay") > -1) {
                return "ApplePay";
            }
            if (name.indexOf("googlepay") > -1) {
                return "GooglePay";
            }
            if (name.indexOf("ekspresbank") > -1) {
                return "EkspresBank";
            }
            if (name.indexOf("swish") > -1) {
                return "Swish";
            }
            if (name.indexOf("nordeaepaymentfi") > -1) {
                return "NordeaEPaymentFI";
            }
        }
        return "";
    }

    isAppSwitchSupported(paymentMethod: "swish" | "mobilepay" | "vipps"): Boolean {
        if (!paymentMethod) return false;

        if (this.options.version == null || !this.options.version.match(/^CheckoutSDK(?:Android|iOS)\/2\./)) return false;

        if (this.options.paymentAppsInstalled == null) return false;

        return this.options.paymentAppsInstalled.map(x => x.toLowerCase()).indexOf(paymentMethod) !== -1;
    }

    stateChangeFinished() {
        this.stateIsResolved = true;

        // fix for scrolled to middle on iPhone
        if (this.showDemoLoader) {
            this.$timeout(() => angular.element(window).scrollTop(0), 300);
        }

        this.showDemoLoader = false;
        this.clearLoadingMessage();
    }

    startStateChange() {
        this.stateIsResolved = false;
    }

    get isFullScreenLoading() {
        return this.girafVisible || !this.stateIsResolved;
    }

    startFullScreenLoadingWithMessage(message: string) {
        this.$timeout(() => {
            this.showLogoLoader = false;
            this.girafVisible = true;
            this.showLoading = true;
            this.girafMessage = message;
        });
    }

    startFullScreenLoadingForAuthorize() {
        this.startFullScreenLoadingWithMessage(this.gettextCatalog.getString("Processing your payment"));
    }

    startMinorLoading() {
        this.showLogoLoader = false;
        this.showLoading = true;
    }

    startFullScreenLoadingFor3DRedirect() {
        this.startFullScreenLoadingWithMessage(this.gettextCatalog.getString("Redirecting to 3D Secure"));
    }

    startFullScreenLoadingForVerification() {
        this.startFullScreenLoadingWithMessage(this.gettextCatalog
            .getString("Awaiting external verification"));
    }

    startFullScreenLoadingForExternalAuthentication(message: string, authenticationType: string) {
        this.girafVisible = true;
        this.showLoading = true;
        this.showLogoLoader = true;
        this.loaderType = authenticationType;
        this.girafMessage = message;
    }

    cancelLoading() {
        this.$timeout(() => {
            this.showLoading = false;
            this.girafVisible = false;
            this.showLogoLoader = false;
            this.clearLoadingMessage();
        },
            1);
    }

    private clearLoadingMessage() {
        if (!this.isFullScreenLoading) {
            this.girafMessage = "";
        }
    }

    get isLoading() {
        return this.girafVisible || this.showLoading;
    }

    get displayTestMode() {

        let display = false;

        if (this.isTestMerchant) {
            // Display if test merchant
            display = true;
        }

        if (this.$state.current.name === "demo") {
            // Hide in demo view
            display = false;
        }

        if (this.$stateParams.demo !== undefined) {
            // Hide in demo mode
            display = false;
        }

        return display;
    }

    reload() {
        return this.$state.transitionTo(this.$state.current,
            this.$stateParams,
            {
                reload: true
            });

    }

    setTitle(title: string) {
        document.title = title ? `Bambora Checkout: ${title}` : "Bambora Checkout";
    }

    resetTitle() {
        document.title = "Bambora Checkout";
    }
}
