import { SessionService } from "../index";


/**
    * NB: This service is incomplete and will only tell if country is Sweden or Finland. And bases the answer on currency only
    */
export class MerchantService {

    static $inject = [
        "SessionService"
    ];


    public constructor(private sessionService: SessionService) {}

    IsCountry(country: Country): boolean {
        if (country === Country.Finland && this.sessionService.order.currency.toUpperCase() === "EUR") return true;
        if (country === Country.Norway && this.sessionService.order.currency.toUpperCase() === "NOK") return true;
        if (country === Country.Sweden && this.sessionService.order.currency.toUpperCase() === "SEK") return true;
        if (country === Country.Denmark && this.sessionService.order.currency.toUpperCase() === "DKK") return true;
        return false;
    }

    get Country() {
        if (this.sessionService.order.currency.toUpperCase() === "EUR") return Country.Finland;
        if (this.sessionService.order.currency.toUpperCase() === "NOK") return Country.Norway;
        if (this.sessionService.order.currency.toUpperCase() === "SEK") return Country.Sweden;
        if (this.sessionService.order.currency.toUpperCase() === "DKK") return Country.Denmark;
        return Country.Other;
    }

}

export const enum Country {
    Sweden = 752,
    Finland = 978,
    Norway = 578,
    Denmark = 345, //TOD: check this
    Other = 0
};