import { SessionService } from "../../../core/index";


export class BecTermsUrl {

    static $inject = ["SessionService"];

    public constructor(private _sessionService: SessionService) {
    }

        
    link = (scope, element, attrs) => {

        const watcher = scope.$watch(this._sessionService.language,
        () => {
            if (this._sessionService.language) {
                scope.termsurl = `assets/terms/terms${this._getTermsUrlLangPostFix()}.html`;
                watcher();
            }
        });
    };

    private _getTermsUrlLangPostFix(): string {
           
        switch (this._sessionService.language.toLowerCase()) {
        case "nb-no": 
            return "-nb";
        case "da-dk":
            return "-da";
        case "sv-se":
            return "-se"
        default:
            return "-en";
        }
             
    }
}