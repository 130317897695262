import * as _ from "underscore";
import {
    Component,
    ExpressCheckoutService,
    AppStateService,
    ConfigReaderService
} from "../core/index";
import {
    MerchantDemoDataService,
    DemoMerchant,
    InitRequestService
} from "./shared/index";

import input from "../shared/components/input/input.scss";

import demo from './demo.scss'

const styles = {
    input: input,
    demo: demo
};

@Component({
    selector: "becDemoInitialization",
    template: require("./demo.html")
})
export class InitializationController  {
    styles = styles;
    request: Server.checkoutrequest;

    merchants: DemoMerchant[];
    selectedMerchant: DemoMerchant;

    languages: any;
    windowStates: any;
    windowState: string;
    subscriptionactions: any;
    captureMultiOptions: any;
    securityLevelOptions: any;
    securityExemptionOptions: any;
    autoProperties: any;

    errorMessage = "";
    status: string;
    endpoint = location.origin;
    advancedMode = false;
    advancedModeButtonText = "Switch to advanced mode";
    goButtonText = "Go!";

    autodemo = false;

    static $inject: string[] = [
        "$scope",
        "ExpressCheckoutService",
        "$location",
        "localStorageService",
        "AppStateService",
        "$state",
        "MerchantDemoDataService",
        "InitRequestService",
        "ConfigReaderService"
    ];
    constructor(
        private $scope,
        private checkoutService: ExpressCheckoutService,
        private $location: ng.ILocationService,
        private localStorageService,
        private appStateService: AppStateService,
        private state,
        private merchantData: MerchantDemoDataService,
        private initRequestData: InitRequestService,
        private configReader: ConfigReaderService
    ) {
        let storedSavedMerchant = localStorageService.get("bec-selected-merchant");

        // http://localhost:55228/demo?prefilled-demo-card=1&auto-start-demo=1&show-demo-cards=1&merchantnumber=T000000003&currency=SEK
        if ($location.search()["merchantnumber"]) {
            this.selectedMerchant = this.merchantData.getMerchant($location.search()["merchantnumber"]);
        }

        if (!this.selectedMerchant) {
            this.merchants = this.merchantData.getDemoMerchants(configReader.environment);
            this.selectedMerchant = _.find<any>(
                this.merchants,
                x => x.name === storedSavedMerchant
            ) || this.merchants[0];
        }

        this.languages = [
            { name: "Danish", languageIso: "da-DK" },
            { name: "Swedish", languageIso: "sv-SE" },
            { name: "Norwegian", languageIso: "nb-NO" },
            { name: "Finnish", languageIso: "fi-FI" },
            { name: "English", languageIso: "en-US" },
            { name: "French", languageIso: "fr-FR" },
            { name: "Deutsch", languageIso: "de-DE" },
            { name: "Polish", languageIso: "pl-PL" }
        ];

        this.windowStates = [
            { name: "Fullscreen", value: "1" },
            { name: "Overlay", value: "2" },
            { name: "Inline", value: "3" },
        ];

        this.windowState = this.localStorageService.get("bec-windowstate") || "1";

        this.subscriptionactions = [
             { name: "None", action: "none"},
             { name: "Create", action: "create"}
         ];

        this.captureMultiOptions = [
            { name: "Enable", value: true },
            { name: "Disable", value: false }
        ];

        this.securityLevelOptions = [
            { name: "Default", value: null },
            { name: "Require3D", value: "Require3D" },
            { name: "None", value: "None"}
        ];

        this.securityExemptionOptions = [
            { name: "Default", value: null },
            { name: "LowValuePayment", value: "lowvaluepayment" }
        ];

        this.status = $location.search().status;
        this.autodemo = $location.search()["auto-start-demo"] === "1" ;

        this.request = this.initRequestData.getDefaultRequest();

        if (this.$location.search()["status"] == null) {
            this.autoProperties = {
                currency: this.$location.search()["currency"],
                amount: this.$location.search()["amount"],
                instantcaptureamount: this.$location.search()["instantcaptureamount"],
                language: this.$location.search()["language"],
                immediateredirecttoaccept: this.$location.search()["immediateredirecttoaccept"],
                subscriptionaction: this.$location.search()["subscriptionaction"],
                acceptUrl: this.$location.search()["accepturl"],
                declineUrl: this.$location.search()["declineurl"],
                capturemulti: this.$location.search()["capturemulti"],
                securitylevel: this.$location.search()["securitylevel"],
                securityexemption: this.$location.search()["securityexemption"]
            };
        }

        if (this.autodemo) {
            this.go();
        } else {
            appStateService.cancelLoading();
            appStateService.stateChangeFinished();
        }

        $scope.$watch(() => {return this.request.customer.email;}, (value) => {
            if(value === "") {
                this.request.customer.email = null;
            }
        });
    }

    useClientBlank = () => {
        this.request.customer = null;
    };

    toggleAdvancedMode = () => {
        this.advancedMode = !this.advancedMode;
        this.advancedModeButtonText = this.advancedMode ?
            "Switch to simple mode" : "Switch to advanced mode";
    }

    go() {

        this.appStateService.isTestMerchant = (this.selectedMerchant &&
        this.selectedMerchant.name.slice(0, 1).toUpperCase().indexOf("T") > -1);

        //Override invoice billing and shipping data based on Merchant country.
        if (this.selectedMerchant.invoiceCountry == "NOR") {
            this.request.order.billingaddress = this.initRequestData.getInvoiceAddressNorway();
            this.request.order.shippingaddress = this.request.order.billingaddress;
        } else if(this.selectedMerchant.invoiceCountry == "FIN") {
            this.request.order.billingaddress = this.initRequestData.getInvoiceAddressFinland();
            this.request.order.shippingaddress = this.request.order.billingaddress;
        } else if(this.selectedMerchant.invoiceCountry == "DNK") {
            this.request.order.billingaddress = this.initRequestData.getInvoiceAddressDenmark();
            this.request.order.shippingaddress = this.request.order.billingaddress;
        }

        this.goButtonText = "Sending request...";

        if (!this.autodemo) {
            this.appStateService.showDemoLoader = true;
        }
        this.appStateService.showLoading = true;
        this.appStateService.girafVisible = true;
        this.appStateService.stateIsResolved = false;

        this.localStorageService.set("bec-selected-merchant", this.selectedMerchant.name);

        this.request.order.currency = this.selectedMerchant.currency;
        let itemCount = this.request.order.lines.length;

        if (this.autoProperties) {
            if (this.autoProperties.amount) {
                this.request.order.amount = this.autoProperties.amount;
            }
            if (this.autoProperties.instantcaptureamount) {
                this.request.instantcaptureamount = this.autoProperties.instantcaptureamount;
            }

            if (this.autoProperties.currency) {
                this.request.order.currency = this.autoProperties.currency;
            }

            if (this.autoProperties.declineUrl) {
                this.request.url.decline = this.autoProperties.declineUrl;
            }

            if (this.autoProperties.acceptUrl) {
                this.request.url.accept = this.autoProperties.acceptUrl;
            }

            if (this.autoProperties.language) {
                this.request.language = this.autoProperties.language;
            }

            if (this.autoProperties.immediateredirecttoaccept) {
                this.request.url
                    .immediateredirecttoaccept = this.autoProperties.immediateredirecttoaccept === "1" ? 1 : 0;
            }

            if (this.autoProperties.subscriptionaction) {
                this.request.subscription.action = this.autoProperties.subscriptionaction;

            }

            if(this.autoProperties.capturemulti) {
                this.request.capturemulti = this.autoProperties.capturemulti;
            }

            if(this.autoProperties.securitylevel) {
                this.request.securitylevel = !this.autoProperties.securitylevel ? null : this.autoProperties.securitylevel;
            }

            if(this.autoProperties.securityexemption) {
                this.request.securityexemption = !this.autoProperties.securityexemption ? null : this.autoProperties.securityexemption;
            }

        }

        if(this.request.order.amount != this.initRequestData.getDefaultRequest().order.amount)
        {
            let totalUnitPrice = Math.floor(this.request.order.amount / itemCount);

            _.each<any>(
                this.request.order.lines,
                (item) => {
                    item.totalprice = totalUnitPrice;
                }
            );

            _.last<any>(this.request.order.lines).totalprice = totalUnitPrice + this.request.order.amount % itemCount;

            _.each<any>(
                this.request.order.lines,
                (item) => {
                    item.totalpricevatamount = 0;
                    item.totalpriceinclvat = item.totalprice + item.totalpricevatamount;
                    item.quantity = 1;
                    item.unitprice = item.totalprice;
                    item.unitpriceinclvat = item.unitprice;
                    item.unitpricevatamount = 0;
                }
            );
        }

        // Make currency be taken from merchant
        // $scope.request.order.currency = $scope.selectedMerchant.currency;

        this.checkoutService.setExpressCheckout(this.request, this.selectedMerchant.apikey)
            .then((response): any => {
                if (response.meta.result) {
                    if (response.token !== undefined) {
                        this.goButtonText = "Opening Checkout...";
                    }

                    this.state.go("session.start", {
                        token: response.token,
                        "show-demo-cards": this.$location.search()["show-demo-cards"],
                        "prefilled-demo-card": this.$location.search()["prefilled-demo-card"],
                        windowstate: this.windowState
                    });
                } else {
                    this.appStateService.cancelLoading();
                    this.appStateService.stateChangeFinished();
                    this.setError(response);
                    this.goButtonText = "Error Opening Checkout...";
                }
            });
    }

    onLanguageChange() {
        this.localStorageService.set("bec-language", this.request.language);
    };

    onWindowStateChange() {
        this.localStorageService.set("bec-windowstate", this.windowState);
    };

    setError(response: Server.expresstokenresponse) {

        if (response && response.meta && response.meta.message) {
            this.errorMessage = response.meta.message.enduser;
        } else {
            this.errorMessage = "Unknown error with an unknown message.";
        }
    }

    useClientRandom = (event) => {
        if (event) {
            event.preventDefault();
        }

        let chance = new Chance();

        if (!this.request.customer) {
            this.request.customer = {
                email: chance.email(),
                phonenumber: "",
                phonenumbercountrycode: ""
            };
        } else {
            this.request.customer = {
                email: chance.email(),
                phonenumber: this.request.customer.phonenumber,
                phonenumbercountrycode: this.request.customer.phonenumbercountrycode
            };
        }
    }
}
