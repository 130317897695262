import * as _ from "underscore";
import { States } from "../../app.states";
import {
    AppStateService,
    SessionService,
    AuthorizeService,
    CustomerService,
    UrlService,
    ErrorService,
    MerchantService,
    StateHelperService,
    Country
} from "../../core/index";


export class InvoiceService {
    static $inject = [
        "$rootScope",
        "SessionService",
        "AuthorizeService",
        "AppStateService",
        "CustomerService",
        "UrlService",
        "ErrorService",
        "gettextCatalog",
        "MerchantService",
        "numberToLocaleFilter",
        "StateHelperService"
    ];
    private static distributionMethod = "Email";

    private _selectedPaymentOption: Server.paymentoption;
    private _customerIsRejectedAsCustomer = false;
    private _invoiceEmail: string;

    paymentOptions: Array<Server.paymentoption> = [];
    officialAddress: Server.address;
    billingAddress: Server.address;
    _lastAuthorizeMeta: Server.meta;
    invoiceContext: "invoice" | "partpayment" = "invoice";
    useMobileBankId: boolean = false;

    
    public constructor(
        private $rootScope: ng.IRootScopeService,
        private sessionService: SessionService,
        private authorizeService: AuthorizeService, 
        private appState: AppStateService,
        private customer: CustomerService,
        private urlService: UrlService,
        private errorService: ErrorService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private merchantService: MerchantService,
        private numberToLocaleFilter,
        private stateHelper: StateHelperService
    ) {

        $rootScope.$watch(() => {
            if (this.sessionService.invoiceOptions && this.sessionService.invoiceOptions.paymentoptions) {
                return this.sessionService.invoiceOptions.paymentoptions;
            }

        },
        (value) => {
            if (!value) return;
            this.paymentOptions = this.sessionService.invoiceOptions.paymentoptions;
            if (this.customer.lastUsedPaymentType === "invoice") {
                this.selectedPaymentOption = _.find<any>(
                    this.sessionService.invoiceOptions.paymentoptions,
                    (p: Server.paymentoption) => p.type === "Invoice"
                );
            } else if(this.customer.lastUsedPaymentType === "partpayment") {
                this.selectedPaymentOption = _.find<any>(
                    this.sessionService.invoiceOptions.paymentoptions,
                    (p: Server.paymentoption) => p.type === "InterestFreeInvoice" || p.type === "AnnuityInvoice"
                );
            }

        });
    }

    setInvoiceContext(context: "invoice" | "partpayment") {
        this.invoiceContext = context;
    }


    /// Properties
    get invoiceEmail() {
        if (this._invoiceEmail === null || typeof this._invoiceEmail === "undefined") {
            this._invoiceEmail = this.customer.details.email;
        }

        return this._invoiceEmail;
    }

    set invoiceEmail(value: string) {
        this._invoiceEmail = value;
    }

    get invoicableAddress() {
        return this.officialAddress || this.billingAddress || this.sessionService.order.billingaddress;
    }

    
    get customerIsRejectedAsCustomer() {
        return this._customerIsRejectedAsCustomer;
    }

    get selectedPaymentOption(): Server.paymentoption {
        return this._selectedPaymentOption;
    }

    set selectedPaymentOption(paymentOption: Server.paymentoption) {
        this._selectedPaymentOption = paymentOption;
    }

    get creditTermsUrl(): string {
        return this.sessionService.invoiceOptions.termsurl;
    }

    get ssnSecuredPartLength(): number {
        if(this.merchantService.IsCountry(Country.Sweden)) { return 4;}
        if(this.merchantService.IsCountry(Country.Norway)) { return 5;}
    }

    /// METHODS
    retry() {
        this.stateHelper.$state.go(States.Session.Invoice.Start.name);
    }

    continueWithSelectedPaymentPlan() {
        this.confirmPayment();
    }

    // Switch Methods, Switches view
    switchToCardPayment() {
        this.appState.switchState(States.Session.PaymentCard.Start.name);
    }

    switchToSsnVerify() {
        this.appState.switchState(States.Session.Invoice.SSNVerify.name);
    }

    get ssn() {
        return this.customer.ssn;
    }

    set ssn(value) {
        this.customer.ssn = value;
    }

    verifySsn() {
        if (this.ssn.length === this.ssnSecuredPartLength) {
            this.confirmPayment();
        }

    }

    confirmPayment() {
        this.appState.startFullScreenLoadingForAuthorize();
        this.authorizeService
            .authorizeInvoicePaymentOption(this.customer.ssn,
                this.selectedPaymentOption.paymentoptionid,
                InvoiceService.distributionMethod,
                this.invoiceContext,
                null,
                this.customer.details,
                this.invoiceEmail,
                null,
                this.selectedPaymentOption.type,
                this.useMobileBankId)
            .then((res: Server.authorizeresponse) => {
                this.urlService.setUrlFromAuthorize(res, this.sessionService.sessionToken);
                if (res.meta.action
                    .code ===
                    Server.ActionCodeTypeEnum.AuthorizeRequiresSsnVerification.toString()) {
                    this.errorService.displayErrorMessage(this.gettextCatalog.getString("SSN is requried, please try again."), this.getErrorContext());
                    this.appState.cancelLoading();
                    return;
                }

                if (res.meta.action.code === Server.ActionCodeTypeEnum.SsnVerificationFailed.toString()) {
                    this.errorService.displayErrorMessage(this.gettextCatalog.getString("Invalid SSN, please try again."), this.getErrorContext());
                    this.appState.cancelLoading();

                    return res;
                }

                if (res.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString() ||
                    res.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                    return res;
                }

                if (res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPostValidation.toString() ||
                    res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresRedirectValidation.toString() ||
                    res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPollValidation.toString()) {
                    return res;
                }

                if (!res.meta.result) {
                    if (res.meta.action.code === "1003") {
                        this._customerIsRejectedAsCustomer = true;
                    }
                    this.errorService.displayErrorMessage(res.meta.message.enduser, this.getErrorContext());
                    this._lastAuthorizeMeta = res.meta;
                    this.appState.cancelLoading();
                    if(this.invoiceContext === "invoice") {
                         this.appState.switchState(States.Session.Invoice.Start.name);
                    } else {
                        this.appState.switchState(States.Session.PartPayment.Start.name);
                    }

                    return res;
                }
                // Here we assume success*

                this.sessionService.status = "authorized";
                this.appState.switchState(States.Session.Invoice.Accept.name);
                this.appState.cancelLoading();
            });
    }

    get lastEndUserLabel() {
        if (this._lastAuthorizeMeta && this._lastAuthorizeMeta.message)
            return this._lastAuthorizeMeta.message.enduser;
        return null;
    }

    getErrorContext() {
        if (this.invoiceContext === "invoice") {
            return States.Session.Invoice.name;
        }
        return States.Session.PartPayment.name;
    }
}