import {
    AppStateService,
    AuthorizeService,
    ErrorService,
    SessionService
} from "../../core/index";

import { DeepLinker } from "../../core/utilities/deep-linker"
import { States } from "../../app.states";

export class SwishService {

    static $inject: string[] = [
        "AuthorizeService",
        "AppStateService",
        "ErrorService",
        "SessionService"
    ];

    public constructor(
        private authorizeService: AuthorizeService,
        private appStateService: AppStateService,
        private errorService: ErrorService,
        private sessionService: SessionService
    ) { }

    authorize(phoneNumber: string, deepLinkerOptions: { onReturn?: any; onFallback?: any; onIgnored?: any; }) {
        let deepLinker: DeepLinker = undefined;

        this.appStateService.startFullScreenLoadingForAuthorize();

        let mode: "poll" | "app" = "poll";
        let returnTo: "browser" | "app" = "browser";

        if (this.appStateService.isAppSwitchSupported("swish")) {
            mode = "app"
            returnTo = "app"
        }
        else if (phoneNumber === null) {
          mode = "app"
          returnTo = "browser"
          deepLinker = new DeepLinker(deepLinkerOptions)
        }

        let authorizePromise: ng.IPromise<Server.authorizeresponse> = this.authorizeService.authorizeSwish(phoneNumber, returnTo, mode, deepLinker);

        return authorizePromise.then((res: Server.authorizeresponse) => {
            if (res.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                return res;
            }

            if (!res.meta.result) {
                this.appStateService.cancelLoading();
                this.errorService.displayErrorMessage(res.meta.message.enduser, States.Session.Swish.name);
                if (mode === "app" && returnTo === "browser" ) {
                  this.appStateService.switchState(States.Session.Swish.Decline.name);
                }
                return res;
            }

            if (res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPostValidation.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresRedirectValidation.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPollValidation.toString()) {
                return res;
            }

            this.sessionService.status = "authorized";
            this.appStateService.switchState(States.Session.Swish.Accept.name);

            this.appStateService.cancelLoading();
            return res;

        });
    }
}
