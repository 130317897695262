import { AsYouTypeFormatter, PhoneNumberUtil } from "google-libphonenumber";
import * as _ from "underscore";


export interface ICountryInfo {
    name: string;
    dialCode: string;
    countryCode: string;
}

export interface IPhoneNumberInfo {
    nationalNumber: string;
    countryInfo: ICountryInfo;
    formatted: string;
    isValid: boolean;
}

export class PhoneNumberService {

    phoneUtil: PhoneNumberUtil;


    constructor() {
        this.phoneUtil = PhoneNumberUtil.getInstance();
    }


    parse(phonenumber: string, coutrycodeOrDial: string): IPhoneNumberInfo {

        const countryInfo = this.deductCountryInfo(coutrycodeOrDial);
        if (!phonenumber && countryInfo) {
            return {
                countryInfo: countryInfo,
                isValid: false,
                formatted: countryInfo.dialCode,
                nationalNumber: ""
            };
        }
        return this.parseUsingCountryInfo(phonenumber, countryInfo);
    }



    getCountryCodeFromDialCode(input: string): string {
        const result = this.deductCountryInfo(input);
        return result != null ? result.countryCode : "";
    }

    getCallingCodeFromCountryCode(input: string): string {
        const result = this.deductCountryInfo(input);
        return result != null ? result.dialCode : "";
    }


    isDialCode(countryCodeOrDialCode): boolean {
        return !isNaN(countryCodeOrDialCode);
    }

    private deductCountryInfo(phoneInput: string): ICountryInfo {

        if (!this.isDialCode(phoneInput)) {
            return this.findCodeObjectFromCountryCode(phoneInput);
        }

        phoneInput = this.cleanPhoneNumber(phoneInput);

        let res = null;
        let i = Math.min(phoneInput.length, 3);

        while (!res && i > 0) {
            res = this.findCodeObjectFromDialCode(phoneInput.substring(0, i));
            i--;
        }
        return res;


    }

    private parseUsingCountryInfo(phoneInput: string, countryInfo: ICountryInfo): IPhoneNumberInfo {

        phoneInput = this.cleanPhoneNumber(phoneInput);

        const nationalNumber = phoneInput;
        const isValid = this.isValid(nationalNumber, countryInfo);
        const formatted = this.formatNumber(phoneInput, nationalNumber, countryInfo);

        return {
            countryInfo: countryInfo,
            isValid: isValid,
            formatted: formatted,
            nationalNumber: nationalNumber
        };

    }


    private formatNumber(original: string, nationalNumber: string, countryInfo: ICountryInfo): string {
        if (!countryInfo) return original;
        try {
            var result = "";
            var formatter = new AsYouTypeFormatter(countryInfo.countryCode);
            // We use the AsYouTypeFormatter here as the format method can only format correct numbers, while this formats invalid and
            nationalNumber.split("")
                .forEach((c: string) => {
                    result = formatter.inputDigit(c);
                });

            if (result) {
                return result;
            }

        } catch (err) {
            return original;
        }
        return original;
    }

    private isValid(nationalNumber: string, countryInfo: ICountryInfo): boolean {
        if (!countryInfo) {
            return false;
        }
        try {

            var number = (this.phoneUtil as any).parseAndKeepRawInput(nationalNumber, countryInfo.countryCode)
            return (this.phoneUtil as any).isValidNumberForRegion(number, countryInfo.countryCode)
        } catch (err) {
            return false;
        }
    }


    private cleanPhoneNumber(phone) {
        if (!phone) {
            return ""
        }
        return phone.replace(/\D/g, "");
    }

    private findCodeObjectFromDialCode(dialCode: string): ICountryInfo {
        return _.find(this.codes, (e) => { return e.dialCode === (dialCode) });
    }

    private findCodeObjectFromCountryCode(countryCode: string): ICountryInfo {
        return _.find(this.codes, (e) => { return e.countryCode === (countryCode) });
    }


    private codes: Array<ICountryInfo> = [
        { "name": "Afghanistan", "dialCode": "93", "countryCode": "AF" },
        { "name": "Albania", "dialCode": "355", "countryCode": "AL" },
        { "name": "Algeria", "dialCode": "213", "countryCode": "DZ" },
        { "name": "AmericanSamoa", "dialCode": "1 684", "countryCode": "AS" },
        { "name": "Andorra", "dialCode": "376", "countryCode": "AD" },
        { "name": "Angola", "dialCode": "244", "countryCode": "AO" },
        { "name": "Anguilla", "dialCode": "1 264", "countryCode": "AI" },
        { "name": "Antarctica", "dialCode": "672", "countryCode": "AQ" },
        { "name": "Antigua and Barbuda", "dialCode": "1268", "countryCode": "AG" },
        { "name": "Argentina", "dialCode": "54", "countryCode": "AR" },
        { "name": "Armenia", "dialCode": "374", "countryCode": "AM" },
        { "name": "Aruba", "dialCode": "297", "countryCode": "AW" },
        { "name": "Australia", "dialCode": "61", "countryCode": "AU" },
        { "name": "Austria", "dialCode": "43", "countryCode": "AT" },
        { "name": "Azerbaijan", "dialCode": "994", "countryCode": "AZ" },
        { "name": "Bahamas", "dialCode": "1 242", "countryCode": "BS" },
        { "name": "Bahrain", "dialCode": "973", "countryCode": "BH" },
        { "name": "Bangladesh", "dialCode": "880", "countryCode": "BD" },
        { "name": "Barbados", "dialCode": "1 246", "countryCode": "BB" },
        { "name": "Belarus", "dialCode": "375", "countryCode": "BY" },
        { "name": "Belgium", "dialCode": "32", "countryCode": "BE" },
        { "name": "Belize", "dialCode": "501", "countryCode": "BZ" },
        { "name": "Benin", "dialCode": "229", "countryCode": "BJ" },
        { "name": "Bermuda", "dialCode": "1 441", "countryCode": "BM" },
        { "name": "Bhutan", "dialCode": "975", "countryCode": "BT" },
        { "name": "Bolivia, Plurinational State of", "dialCode": "591", "countryCode": "BO" },
        { "name": "Bosnia and Herzegovina", "dialCode": "387", "countryCode": "BA" },
        { "name": "Botswana", "dialCode": "267", "countryCode": "BW" },
        { "name": "Brazil", "dialCode": "55", "countryCode": "BR" },
        { "name": "British Indian Ocean Territory", "dialCode": "246", "countryCode": "IO" },
        { "name": "Brunei Darussalam", "dialCode": "673", "countryCode": "BN" },
        { "name": "Bulgaria", "dialCode": "359", "countryCode": "BG" },
        { "name": "Burkina Faso", "dialCode": "226", "countryCode": "BF" },
        { "name": "Burundi", "dialCode": "257", "countryCode": "BI" },
        { "name": "Cambodia", "dialCode": "855", "countryCode": "KH" },
        { "name": "Cameroon", "dialCode": "237", "countryCode": "CM" },
        { "name": "Canada", "dialCode": "1", "countryCode": "CA" },
        { "name": "Cape Verde", "dialCode": "238", "countryCode": "CV" },
        { "name": "Cayman Islands", "dialCode": " 345", "countryCode": "KY" },
        { "name": "Central African Republic", "dialCode": "236", "countryCode": "CF" },
        { "name": "Chad", "dialCode": "235", "countryCode": "TD" },
        { "name": "Chile", "dialCode": "56", "countryCode": "CL" },
        { "name": "China", "dialCode": "86", "countryCode": "CN" },
        { "name": "Christmas Island", "dialCode": "61", "countryCode": "CX" },
        { "name": "Cocos (Keeling) Islands", "dialCode": "61", "countryCode": "CC" },
        { "name": "Colombia", "dialCode": "57", "countryCode": "CO" },
        { "name": "Comoros", "dialCode": "269", "countryCode": "KM" },
        { "name": "Congo", "dialCode": "242", "countryCode": "CG" },
        { "name": "Congo, The Democratic Republic of the", "dialCode": "243", "countryCode": "CD" },
        { "name": "Cook Islands", "dialCode": "682", "countryCode": "CK" },
        { "name": "Costa Rica", "dialCode": "506", "countryCode": "CR" },
        { "name": "Cote d'Ivoire", "dialCode": "225", "countryCode": "CI" },
        { "name": "Croatia", "dialCode": "385", "countryCode": "HR" },
        { "name": "Cuba", "dialCode": "53", "countryCode": "CU" },
        { "name": "Cyprus", "dialCode": "357", "countryCode": "CY" },
        { "name": "Czech Republic", "dialCode": "420", "countryCode": "CZ" },
        { "name": "Denmark", "dialCode": "45", "countryCode": "DK" },
        { "name": "Djibouti", "dialCode": "253", "countryCode": "DJ" },
        { "name": "Dominica", "dialCode": "1 767", "countryCode": "DM" },
        { "name": "Dominican Republic", "dialCode": "1 849", "countryCode": "DO" },
        { "name": "Ecuador", "dialCode": "593", "countryCode": "EC" },
        { "name": "Egypt", "dialCode": "20", "countryCode": "EG" },
        { "name": "El Salvador", "dialCode": "503", "countryCode": "SV" },
        { "name": "Equatorial Guinea", "dialCode": "240", "countryCode": "GQ" },
        { "name": "Eritrea", "dialCode": "291", "countryCode": "ER" },
        { "name": "Estonia", "dialCode": "372", "countryCode": "EE" },
        { "name": "Ethiopia", "dialCode": "251", "countryCode": "ET" },
        { "name": "Falkland Islands (Malvinas)", "dialCode": "500", "countryCode": "FK" },
        { "name": "Faroe Islands", "dialCode": "298", "countryCode": "FO" },
        { "name": "Fiji", "dialCode": "679", "countryCode": "FJ" },
        { "name": "Finland", "dialCode": "358", "countryCode": "FI" },
        { "name": "France", "dialCode": "33", "countryCode": "FR" },
        { "name": "French Guiana", "dialCode": "594", "countryCode": "GF" },
        { "name": "French Polynesia", "dialCode": "689", "countryCode": "PF" },
        { "name": "Gabon", "dialCode": "241", "countryCode": "GA" },
        { "name": "Gambia", "dialCode": "220", "countryCode": "GM" },
        { "name": "Georgia", "dialCode": "995", "countryCode": "GE" },
        { "name": "Germany", "dialCode": "49", "countryCode": "DE" },
        { "name": "Ghana", "dialCode": "233", "countryCode": "GH" },
        { "name": "Gibraltar", "dialCode": "350", "countryCode": "GI" },
        { "name": "Greece", "dialCode": "30", "countryCode": "GR" },
        { "name": "Greenland", "dialCode": "299", "countryCode": "GL" },
        { "name": "Grenada", "dialCode": "1 473", "countryCode": "GD" },
        { "name": "Guadeloupe", "dialCode": "590", "countryCode": "GP" },
        { "name": "Guam", "dialCode": "1 671", "countryCode": "GU" },
        { "name": "Guatemala", "dialCode": "502", "countryCode": "GT" },
        { "name": "Guernsey", "dialCode": "44", "countryCode": "GG" },
        { "name": "Guinea", "dialCode": "224", "countryCode": "GN" },
        { "name": "Guinea-Bissau", "dialCode": "245", "countryCode": "GW" },
        { "name": "Guyana", "dialCode": "595", "countryCode": "GY" },
        { "name": "Haiti", "dialCode": "509", "countryCode": "HT" },
        { "name": "Holy See (Vatican City State)", "dialCode": "379", "countryCode": "VA" },
        { "name": "Honduras", "dialCode": "504", "countryCode": "HN" },
        { "name": "Hong Kong", "dialCode": "852", "countryCode": "HK" },
        { "name": "Hungary", "dialCode": "36", "countryCode": "HU" },
        { "name": "Iceland", "dialCode": "354", "countryCode": "IS" },
        { "name": "India", "dialCode": "91", "countryCode": "IN" },
        { "name": "Indonesia", "dialCode": "62", "countryCode": "ID" },
        { "name": "Iran, Islamic Republic of", "dialCode": "98", "countryCode": "IR" },
        { "name": "Iraq", "dialCode": "964", "countryCode": "IQ" },
        { "name": "Ireland", "dialCode": "353", "countryCode": "IE" },
        { "name": "Isle of Man", "dialCode": "44", "countryCode": "IM" },
        { "name": "Israel", "dialCode": "972", "countryCode": "IL" },
        { "name": "Italy", "dialCode": "39", "countryCode": "IT" },
        { "name": "Jamaica", "dialCode": "1 876", "countryCode": "JM" },
        { "name": "Japan", "dialCode": "81", "countryCode": "JP" },
        { "name": "Jersey", "dialCode": "44", "countryCode": "JE" },
        { "name": "Jordan", "dialCode": "962", "countryCode": "JO" },
        { "name": "Kazakhstan", "dialCode": "7 7", "countryCode": "KZ" },
        { "name": "Kenya", "dialCode": "254", "countryCode": "KE" },
        { "name": "Kiribati", "dialCode": "686", "countryCode": "KI" },
        { "name": "Korea, Democratic People's Republic of", "dialCode": "850", "countryCode": "KP" },
        { "name": "Korea, Republic of", "dialCode": "82", "countryCode": "KR" },
        { "name": "Kuwait", "dialCode": "965", "countryCode": "KW" },
        { "name": "Kyrgyzstan", "dialCode": "996", "countryCode": "KG" },
        { "name": "Lao People's Democratic Republic", "dialCode": "856", "countryCode": "LA" },
        { "name": "Latvia", "dialCode": "371", "countryCode": "LV" },
        { "name": "Lebanon", "dialCode": "961", "countryCode": "LB" },
        { "name": "Lesotho", "dialCode": "266", "countryCode": "LS" },
        { "name": "Liberia", "dialCode": "231", "countryCode": "LR" },
        { "name": "Libyan Arab Jamahiriya", "dialCode": "218", "countryCode": "LY" },
        { "name": "Liechtenstein", "dialCode": "423", "countryCode": "LI" },
        { "name": "Lithuania", "dialCode": "370", "countryCode": "LT" },
        { "name": "Luxembourg", "dialCode": "352", "countryCode": "LU" },
        { "name": "Macao", "dialCode": "853", "countryCode": "MO" },
        { "name": "Macedonia, The Former Yugoslav Republic of", "dialCode": "389", "countryCode": "MK" },
        { "name": "Madagascar", "dialCode": "261", "countryCode": "MG" },
        { "name": "Malawi", "dialCode": "265", "countryCode": "MW" },
        { "name": "Malaysia", "dialCode": "60", "countryCode": "MY" },
        { "name": "Maldives", "dialCode": "960", "countryCode": "MV" },
        { "name": "Mali", "dialCode": "223", "countryCode": "ML" },
        { "name": "Malta", "dialCode": "356", "countryCode": "MT" },
        { "name": "Marshall Islands", "dialCode": "692", "countryCode": "MH" },
        { "name": "Martinique", "dialCode": "596", "countryCode": "MQ" },
        { "name": "Mauritania", "dialCode": "222", "countryCode": "MR" },
        { "name": "Mauritius", "dialCode": "230", "countryCode": "MU" },
        { "name": "Mayotte", "dialCode": "262", "countryCode": "YT" },
        { "name": "Mexico", "dialCode": "52", "countryCode": "MX" },
        { "name": "Micronesia, Federated States of", "dialCode": "691", "countryCode": "FM" },
        { "name": "Moldova, Republic of", "dialCode": "373", "countryCode": "MD" },
        { "name": "Monaco", "dialCode": "377", "countryCode": "MC" },
        { "name": "Mongolia", "dialCode": "976", "countryCode": "MN" },
        { "name": "Montenegro", "dialCode": "382", "countryCode": "ME" },
        { "name": "Montserrat", "dialCode": "1664", "countryCode": "MS" },
        { "name": "Morocco", "dialCode": "212", "countryCode": "MA" },
        { "name": "Mozambique", "dialCode": "258", "countryCode": "MZ" },
        { "name": "Myanmar", "dialCode": "95", "countryCode": "MM" },
        { "name": "Namibia", "dialCode": "264", "countryCode": "NA" },
        { "name": "Nauru", "dialCode": "674", "countryCode": "NR" },
        { "name": "Nepal", "dialCode": "977", "countryCode": "NP" },
        { "name": "Netherlands", "dialCode": "31", "countryCode": "NL" },
        { "name": "Netherlands Antilles", "dialCode": "599", "countryCode": "AN" },
        { "name": "New Caledonia", "dialCode": "687", "countryCode": "NC" },
        { "name": "New Zealand", "dialCode": "64", "countryCode": "NZ" },
        { "name": "Nicaragua", "dialCode": "505", "countryCode": "NI" },
        { "name": "Niger", "dialCode": "227", "countryCode": "NE" },
        { "name": "Nigeria", "dialCode": "234", "countryCode": "NG" },
        { "name": "Niue", "dialCode": "683", "countryCode": "NU" },
        { "name": "Norfolk Island", "dialCode": "672", "countryCode": "NF" },
        { "name": "Northern Mariana Islands", "dialCode": "1 670", "countryCode": "MP" },
        { "name": "Norway", "dialCode": "47", "countryCode": "NO" },
        { "name": "Oman", "dialCode": "968", "countryCode": "OM" },
        { "name": "Pakistan", "dialCode": "92", "countryCode": "PK" },
        { "name": "Palau", "dialCode": "680", "countryCode": "PW" },
        { "name": "Palestinian Territory, Occupied", "dialCode": "970", "countryCode": "PS" },
        { "name": "Panama", "dialCode": "507", "countryCode": "PA" },
        { "name": "Papua New Guinea", "dialCode": "675", "countryCode": "PG" },
        { "name": "Paraguay", "dialCode": "595", "countryCode": "PY" },
        { "name": "Peru", "dialCode": "51", "countryCode": "PE" },
        { "name": "Philippines", "dialCode": "63", "countryCode": "PH" },
        { "name": "Pitcairn", "dialCode": "872", "countryCode": "PN" },
        { "name": "Poland", "dialCode": "48", "countryCode": "PL" },
        { "name": "Portugal", "dialCode": "351", "countryCode": "PT" },
        { "name": "Puerto Rico", "dialCode": "1 939", "countryCode": "PR" },
        { "name": "Qatar", "dialCode": "974", "countryCode": "QA" },
        { "name": "Romania", "dialCode": "40", "countryCode": "RO" },
        { "name": "Russia", "dialCode": "7", "countryCode": "RU" },
        { "name": "Rwanda", "dialCode": "250", "countryCode": "RW" },
        { "name": "Réunion", "dialCode": "262", "countryCode": "RE" },
        { "name": "Saint Barthélemy", "dialCode": "590", "countryCode": "BL" },
        { "name": "Saint Helena, Ascension and Tristan Da Cunha", "dialCode": "290", "countryCode": "SH" },
        { "name": "Saint Kitts and Nevis", "dialCode": "1 869", "countryCode": "KN" },
        { "name": "Saint Lucia", "dialCode": "1 758", "countryCode": "LC" },
        { "name": "Saint Martin", "dialCode": "590", "countryCode": "MF" },
        { "name": "Saint Pierre and Miquelon", "dialCode": "508", "countryCode": "PM" },
        { "name": "Saint Vincent and the Grenadines", "dialCode": "1 784", "countryCode": "VC" },
        { "name": "Samoa", "dialCode": "685", "countryCode": "WS" },
        { "name": "San Marino", "dialCode": "378", "countryCode": "SM" },
        { "name": "Sao Tome and Principe", "dialCode": "239", "countryCode": "ST" },
        { "name": "Saudi Arabia", "dialCode": "966", "countryCode": "SA" },
        { "name": "Senegal", "dialCode": "221", "countryCode": "SN" },
        { "name": "Serbia", "dialCode": "381", "countryCode": "RS" },
        { "name": "Seychelles", "dialCode": "248", "countryCode": "SC" },
        { "name": "Sierra Leone", "dialCode": "232", "countryCode": "SL" },
        { "name": "Singapore", "dialCode": "65", "countryCode": "SG" },
        { "name": "Slovakia", "dialCode": "421", "countryCode": "SK" },
        { "name": "Slovenia", "dialCode": "386", "countryCode": "SI" },
        { "name": "Solomon Islands", "dialCode": "677", "countryCode": "SB" },
        { "name": "Somalia", "dialCode": "252", "countryCode": "SO" },
        { "name": "South Africa", "dialCode": "27", "countryCode": "ZA" },
        { "name": "South Georgia and the South Sandwich Islands", "dialCode": "500", "countryCode": "GS" },
        { "name": "Spain", "dialCode": "34", "countryCode": "ES" },
        { "name": "Sri Lanka", "dialCode": "94", "countryCode": "LK" },
        { "name": "Sudan", "dialCode": "249", "countryCode": "SD" },
        { "name": "Suriname", "dialCode": "597", "countryCode": "SR" },
        { "name": "Svalbard and Jan Mayen", "dialCode": "47", "countryCode": "SJ" },
        { "name": "Swaziland", "dialCode": "268", "countryCode": "SZ" },
        { "name": "Sweden", "dialCode": "46", "countryCode": "SE" },
        { "name": "Switzerland", "dialCode": "41", "countryCode": "CH" },
        { "name": "Syrian Arab Republic", "dialCode": "963", "countryCode": "SY" },
        { "name": "Taiwan", "dialCode": "886", "countryCode": "TW" },
        { "name": "Tajikistan", "dialCode": "992", "countryCode": "TJ" },
        { "name": "Tanzania, United Republic of", "dialCode": "255", "countryCode": "TZ" },
        { "name": "Thailand", "dialCode": "66", "countryCode": "TH" },
        { "name": "Timor-Leste", "dialCode": "670", "countryCode": "TL" },
        { "name": "Togo", "dialCode": "228", "countryCode": "TG" },
        { "name": "Tokelau", "dialCode": "690", "countryCode": "TK" },
        { "name": "Tonga", "dialCode": "676", "countryCode": "TO" },
        { "name": "Trinidad and Tobago", "dialCode": "1 868", "countryCode": "TT" },
        { "name": "Tunisia", "dialCode": "216", "countryCode": "TN" },
        { "name": "Turkey", "dialCode": "90", "countryCode": "TR" },
        { "name": "Turkmenistan", "dialCode": "993", "countryCode": "TM" },
        { "name": "Turks and Caicos Islands", "dialCode": "1 649", "countryCode": "TC" },
        { "name": "Tuvalu", "dialCode": "688", "countryCode": "TV" },
        { "name": "Uganda", "dialCode": "256", "countryCode": "UG" },
        { "name": "Ukraine", "dialCode": "380", "countryCode": "UA" },
        { "name": "United Arab Emirates", "dialCode": "971", "countryCode": "AE" },
        { "name": "United Kingdom", "dialCode": "44", "countryCode": "GB" },
        { "name": "United States", "dialCode": "1", "countryCode": "US" },
        { "name": "Uruguay", "dialCode": "598", "countryCode": "UY" },
        { "name": "Uzbekistan", "dialCode": "998", "countryCode": "UZ" },
        { "name": "Vanuatu", "dialCode": "678", "countryCode": "VU" },
        { "name": "Venezuela, Bolivarian Republic of", "dialCode": "58", "countryCode": "VE" },
        { "name": "Viet Nam", "dialCode": "84", "countryCode": "VN" },
        { "name": "Virgin Islands, British", "dialCode": "1 284", "countryCode": "VG" },
        { "name": "Virgin Islands, U.S.", "dialCode": "1 340", "countryCode": "VI" },
        { "name": "Wallis and Futuna", "dialCode": "681", "countryCode": "WF" },
        { "name": "Yemen", "dialCode": "967", "countryCode": "YE" },
        { "name": "Zambia", "dialCode": "260", "countryCode": "ZM" },
        { "name": "Zimbabwe", "dialCode": "263", "countryCode": "ZW" },
        { "name": "Åland Islands", "dialCode": "358", "countryCode": "AX" }
    ];
}
