/// <reference path="../../../node_modules/autonumeric/typings.d.ts" />

import * as _ from "underscore";
import * as angular from "angular";

import { SessionService } from "../../core/index";
import { cultures } from "@bambora/cultures";

export class BecCurrency {
    static $inject = ["SessionService"];
    constructor(private _session: SessionService) {}

    require = "?ngModel";
    restrict = "A";
    scope = {
        becCurrency: "=",
        variableOptions: "=",
        cultureOptions: "="
    };

    compile(tElem: any, tAttrs: any) {
        var isInputText = tElem.is("input:text");

        return (scope: ng.IScope, elem: JQuery, attrs: ng.IAttributes, controller: ng.INgModelController) => {
            let options: AutoNumericOptions;

            const getOptions = (cultureOptions: { currencyCode?: string, languageCode?: string }): AutoNumericOptions => {
                cultureOptions = cultureOptions || {};

                cultureOptions.currencyCode =
                    cultureOptions.currencyCode || this._session.order.currency;

                cultureOptions.languageCode =
                    cultureOptions.languageCode || this._session.language;

                const culture = _.find(
                    cultures,
                    (c: any) => c.language.code === cultureOptions.languageCode &&
                        c.currency.isoSymbol === cultureOptions.currencyCode
                    ) || _.find(
                    cultures,
                    (c: any) => c.currency.isoSymbol === cultureOptions.currencyCode
                );

                const symbol = culture && culture.language.code === cultureOptions.languageCode ?
                    culture.currency.format.symbol : cultureOptions.currencyCode;

                const fallbackAutoNumericOptions: AutoNumericOptions = {
                    aSign: ` ${symbol}`,
                    pSign: "s"
                };

                if (culture) {
                    fallbackAutoNumericOptions.aDec = culture.currency.format.decimalSeparator;
                    fallbackAutoNumericOptions.aSep = culture.currency.format.groupSeparator;
                    fallbackAutoNumericOptions.dGroup = culture.currency.format.groupSizes[0];
                    fallbackAutoNumericOptions.mDec = culture.currency.format.decimalDigits;
                }

                return angular.extend({},
                    fallbackAutoNumericOptions,
                    scope["becCurrency"] || {}
                );
            }

            options = getOptions(scope["cultureOptions"]);
            elem.autoNumeric("init", options);

            if (scope["cultureOptions"]) {
                scope.$watch("cultureOptions", newValue => {
                    updateOptions(newValue);
                });
            }

            if (scope["variableOptions"] === true) {
                scope.$watch("becCurrency", newValue => {
                    updateOptions(newValue);
                });
            }

            function updateOptions(newValue: any) {
                options = getOptions(newValue);
                elem.autoNumeric("update", options);
            }

            if (controller && isInputText) {
                scope.$watch(tAttrs.ngModel, () => {
                    controller.$render();
                });

                controller.$render = () => {
                    updateElement(controller.$viewValue);
                };

                elem.on("keyup", () => {
                    scope.$applyAsync(() => {
                        controller.$setViewValue(elem.autoNumeric("get"));
                    });
                });

                elem.on("change", () => {
                    scope.$applyAsync(() => {
                        controller.$setViewValue(elem.autoNumeric("get"));
                    });
                });
            } else {
                if (isInputText) {
                    attrs.$observe("value", val => {
                        updateElement(val);
                    });
                }
            }

            function updateElement(newVal: any) {
                if (!isNaN(parseFloat(newVal)) && isFinite(newVal)) {
                    elem.autoNumeric("set", newVal);
                }
            };
        };
    }
}


export const formatCurrencyFilter = () =>
    (input: string, options?: AutoNumericOptions): string => {
        const element = $("<div></div>");
        element.autoNumeric("init", options);
        element.autoNumeric("set", input);

        const value = element.html();

        return value;
    };


export const formatCurrencyByCultureFilter = [
    "SessionService", "becFormatCurrencyFilter",
    (
        sessionService: SessionService,
        becFormatCurrencyFilter: (input: string, options?: AutoNumericOptions) => string
    ) => (
        input: string,
        cultureOptions: { currencyCode?: string, languageCode?: string },
        autoNumericOptions?: AutoNumericOptions
    ): string => {
        cultureOptions = cultureOptions || {};

        cultureOptions.currencyCode =
            cultureOptions.currencyCode || sessionService.order.currency;

        cultureOptions.languageCode =
            cultureOptions.languageCode || sessionService.language;

        const culture = _.find<any>(
            cultures,
            c => c.language.code === cultureOptions.languageCode &&
                c.currency.isoSymbol === cultureOptions.currencyCode
        ) || _.find<any>(
            cultures,
            c => c.currency.isoSymbol === cultureOptions.currencyCode
        );

        const symbol = cultureOptions.currencyCode;

        const fallbackAutoNumericOptions: AutoNumericOptions = {
            aSign: ` ${symbol}`,
            pSign: "s"
        };

        if (culture) {
            fallbackAutoNumericOptions.aDec = culture.currency.format.decimalSeparator;
            fallbackAutoNumericOptions.aSep = culture.currency.format.groupSeparator;
            fallbackAutoNumericOptions.dGroup = culture.currency.format.groupSizes[0];
            fallbackAutoNumericOptions.mDec = culture.currency.format.decimalDigits;
        }

        autoNumericOptions = angular.extend({},
            fallbackAutoNumericOptions,
            autoNumericOptions || {}
        );

        return becFormatCurrencyFilter(input, autoNumericOptions);
    }
];


export class BecToMinor {
    static $inject = ["$parse"];
    constructor(private _$parse: ng.IParseService) {}

    require = "ngModel";
    restrict = "A";

    link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel: ng.INgModelController) => {

        scope.$watch(attrs["becToMinor"], (newValue: string, oldValue: string) => {
            const oldDecimals = getDecimals(oldValue);
            const newDecimals = getDecimals(newValue);
            const newModelValue = (ngModel.$modelValue / Math.pow(10, newDecimals));

            ngModel.$setViewValue(newModelValue);
            ngModel.$render();
        });

        ngModel.$parsers.unshift((value) => {
            const currencyCode = scope.$eval(attrs["becToMinor"]);
            const number = Math.round(value * Math.pow(10, getDecimals(currencyCode)));

            return value && !Number.isNaN(number) ? number : null;
        });

        ngModel.$formatters.unshift((value) => {
            const currencyCode = scope.$eval(attrs["becToMinor"]);
            const decimals = getDecimals(currencyCode);
            const number = parseFloat((value / Math.pow(10, decimals)).toFixed(decimals));

            return value && !Number.isNaN(number) ? number : null;
        });

        function getDecimals(currencyCode: string) {
            let decimals = 2;

            if (currencyCode) {
                const culture = _.find(
                    cultures,
                    (c: any) => c.currency.isoSymbol === currencyCode
                );

                if (culture) decimals = culture.currency.format.decimalDigits;
            }

            return decimals;
        }
    };
}


export const toMajorFilter = [
    "SessionService", (sessionService: SessionService) => (
        input: number,
        currencyCode?: string
    ): number => {
        const culture = currencyCode
            ? _.find<any>(cultures, c => c.currency.isoSymbol === currencyCode)
            : _.find<any>(cultures, c => c.currency.isoSymbol === sessionService.order.currency);

        const decimals = culture ? culture.currency.format.decimalDigits : 2;

        if(input === 0) {
            return parseFloat((0.00001).toFixed(decimals));
        }

        const number = parseFloat((input / Math.pow(10, decimals)).toFixed(decimals));

        return input && !Number.isNaN(number) ? number : null;
    }
];
