import "angular-gettext";
import "angular-ui-router";
import "ngstorage";

import * as angular from "angular";
import * as core from "../core/core.module";
import * as shared from "./index";

import { addComponentToModule, getFactoryFor } from "../core/index";

export const moduleName = "shared";
export const module = angular.module(moduleName, [
    "gettext",
    "ui.router",
    "ngStorage",
    core.moduleName
]);


// Components
addComponentToModule(module, shared.CheckmarkComponent);
addComponentToModule(module, shared.BackButtonController);
addComponentToModule(module, shared.CancelSessionButton);
addComponentToModule(module, shared.FailmarkComponent);
addComponentToModule(module, shared.FeeDisplay);
addComponentToModule(module, shared.ErrorMessage);
addComponentToModule(module, shared.FooterComponent);
addComponentToModule(module, shared.PaymentHeaderController);
addComponentToModule(module, shared.PaymentMethodSelectorController);
addComponentToModule(module, shared.PaymentMethodsComponent);
addComponentToModule(module, shared.GirafLoader);
addComponentToModule(module, shared.OrderStatus);
addComponentToModule(module, shared.OrderlinesComponent);
addComponentToModule(module, shared.SessionExpiredComponent);
addComponentToModule(module, shared.Wait);

module
    .directive("becGhostButton", getFactoryFor(shared.GhostButton))
    .directive("becWhiteButton", getFactoryFor(shared.WhiteButton))
    .directive("becPrimaryButton", getFactoryFor(shared.PrimaryButton))
    .directive("becEscapeButton", getFactoryFor(shared.EscapeButton))
    .directive("becSvg", getFactoryFor(shared.BecSvg))
    .directive("becCheckbox", getFactoryFor(shared.BecCheckbox))
    .directive("becCircularLoader", getFactoryFor(shared.BecCircularLoader))
    .directive("becDropdown", getFactoryFor(shared.BecDropdown))
    .directive("becDropdownOption", getFactoryFor(shared.BecDropdownOption))
    .directive("becGirafAnimation", getFactoryFor(shared.GirafAnimation))
    .directive("becInput", getFactoryFor(shared.BecInput))
    .directive("becRadioGroup", getFactoryFor(shared.BecRadioGroup))
    .directive("becRadioButton", getFactoryFor(shared.BecRadioButton))
    .directive("becNoRadioButton", getFactoryFor(shared.BecNoRadioButton))
    .directive("becTooltip", getFactoryFor(shared.BecTooltip))

    // Services
    .service("DropdownIdGeneratorService", shared.DropdownIdGeneratorService)

    // Directives
    .directive("becAccordionList", getFactoryFor(shared.BecAccordionList))
    .directive("becAccordionPanel", getFactoryFor(shared.BecAccordionPanel))
    .directive("clickElsewhere", getFactoryFor(shared.clickElsewhere))
    .directive("becCurrency", getFactoryFor(shared.BecCurrency))
    .directive("becToMinor", getFactoryFor(shared.BecToMinor))
    .directive("becFocusByMouse", getFactoryFor(shared.BecFocusByMouse))
    .directive("becFocusIf", getFactoryFor(shared.FocusIf))
    .directive("becMaxValue", getFactoryFor(shared.MaxValue))
    .directive("becMinValue", getFactoryFor(shared.MinValue))
    .directive("becOverflowWhenLoading", getFactoryFor(shared.BecOverflowWhenLoading))
    .directive("becUpdateLangAttribute", getFactoryFor(shared.BecUpdateLangAttribute))
    .directive("becMatchOffsetTarget", getFactoryFor(shared.MatchOffsetTarget))
    .directive("becOffsetBroadcast", getFactoryFor(shared.OffsetBroadcast))
    .directive("becRipple", getFactoryFor(shared.BecRipple))
    .directive("becSession", getFactoryFor(shared.BecSessionInject))
    .directive("becShowOnCtrlKey", getFactoryFor(shared.ShowOnCtrlKey))
    .directive("becShowOnShake", getFactoryFor(shared.ShowOnShake))
    .directive("becSingleCharInput", getFactoryFor(shared.SingleCharInput))
    .directive("becWindowState", getFactoryFor(shared.BecWindowState))

    // Filters
    .filter("phonenumber", shared.phoneNumberFilter)
    .filter("cardnumber", shared.cardNumberFilter)
    .filter("ssn", shared.ssnFilter)
    .filter("numberToLocale", shared.numberToLocaleFilter)
    .filter("ccAmountPlaceHolder", shared.ccAmountPlaceholder)
    .filter("becFormatCurrency", shared.formatCurrencyFilter)
    .filter("becFormatCurrencyByCulture", shared.formatCurrencyByCultureFilter)
    .filter("toMajor", shared.toMajorFilter);
