import styles from "./checkbox.scss";

export class BecCheckbox {
    static $inject = [];
    restrict = "E";
    require = "ngModel";
    transclude = true;
    scope = {};

    template = `
        <bec-checkbox-container>
            <bec-checkbox-outer></bec-checkbox-outer>
            <bec-checkbox-inner></bec-checkbox-inner>
        </bec-checkbox-container>
        <label ng-transclude></label>
    `;

    link(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes,
        ngModel: ng.INgModelController,
        transclude: ng.ITranscludeFunction
    ) {
        const formElement = element.closest("form");
        const id = Math.random().toString(36).substring(2);
        const labelId = `label_${id}`;
        const checkboxId = element.attr("id") || `checkbox_${id}`;

        element.attr({
            "role": "checkbox",
            "tabindex": element.attr("tabindex") || "0",
            "id": checkboxId
        });

        element.children("label").attr({
            "id": labelId,
            "for": checkboxId
        });

        scope.$watch(() => ngModel.$viewValue, render);

        element.click(
            (event: JQueryEventObject) => {
                ngModel.$setViewValue(!ngModel.$viewValue);
                ngModel.$commitViewValue();
            }
        );

        element.mousedown((event: JQueryMouseEventObject) => {
            element.addClass("focus-by-mouse");
        });

        element.blur((event: JQueryEventObject) => {
            element.removeClass("focus-by-mouse");
        });

        element.keydown((event: JQueryKeyEventObject) => {
            if (event.target !== event.currentTarget) return;

            switch (event.which) {
                // Spacebar
                case 32: {
                    event.preventDefault();
                    element.click();
                    break;
                }

                // Enter
                case 13: {
                    if (element.is(":focus")) event.preventDefault();
                    if (formElement) {
                        formElement.triggerHandler("submit");
                    }
                    break;
                }
            }
        });

        ngModel.$validators["required"] = (modelValue: any, viewValue: boolean): boolean => {
            if (attrs["required"]) return viewValue;
            return true;
        };

        function render() {
            if (ngModel.$viewValue) {
                element.addClass(styles.checked);
                element.attr("aria-checked", "true");
            } else {
                element.removeClass(styles.checked);
                element.attr("aria-checked", "false");
            }
        }
    };
}
