import { PhoneNumberService } from "../../index";


export class Countrycode {
    require = "ngModel";
    scope = {
        countrycode: "=",
    };

    static $inject = ["$timeout", "$compile", "PhoneNumberService"];

    constructor(private $timeout, private $compile, private phoneutil: PhoneNumberService) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {

        ngModel.$formatters.push((value: string) => {
            if (!value) return "";
            if (this.phoneutil.isDialCode(value)) { ;
                ngModel.$modelValue = this.phoneutil.getCountryCodeFromDialCode(value);
            }
            return this.phoneutil.getCallingCodeFromCountryCode(value.toUpperCase());
        });

        ngModel.$parsers.push((value) => {
            if (!value) return "";
            return this.phoneutil.getCountryCodeFromDialCode(value.toUpperCase());
        });

        ngModel.$validators.countrycode = (modelValue, viewValue) => {


            if (this.phoneutil.getCountryCodeFromDialCode(viewValue.toUpperCase())) {
                return true;
            } else {
                return false;
            }
        };
    };

    static Factory() {

        const directive = ($timeout, $compile, PhoneNumberService) => {
            return new Countrycode($timeout, $compile, PhoneNumberService);
        };
        directive.$inject = ["$timeout", "$compile", "PhoneNumberService"];
        return directive;
    }
}