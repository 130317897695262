import {
    Component,
    SessionService,
    WindowStateService
} from "../../core/index";


@Component({
    selector: "becCancelSessionButton",
    template: `<bec-ghost-button ng-mouseup="$ctrl.cancel($event)" ng-if="!$ctrl.windowState.isInline()">{{$ctrl.text}}</bec-ghost-button>`
})
export class CancelSessionButton {
    static $inject = [
        "SessionService",
        "gettextCatalog",
        "WindowStateService"
    ];

    constructor(
        private sessionService: SessionService,
        private gettext: ng.gettext.gettextCatalog,
        public windowState: WindowStateService
    ) { }

    get text() {
        return this.gettext.getString("Cancel");
    }

    cancel(event) {
        this.sessionService.cancel(event);
    }
}