import { WindowStateService } from "../../core/index";


export class BecWindowState {
    static $inject = ["WindowStateService"];

    public constructor(private windowStateService: WindowStateService) {}
    
    link = (scope, element, attrs) => {
        scope["windowStateService"] = this.windowStateService;
    };
}