export class CreditCardNumber {

    require = "ngModel";
    scope = {
        countrycode: "=",
    };

    static $inject = ["$timeout"];

    constructor(private $timeout) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {
        ngModel.$formatters.push((value: string) => {
            if (!value) return "";

            return (jQuery.payment as JQueryPayment.ExpressPayment).formatCardNumber(value);
        });

        ngModel.$parsers.push((value) => {
            if (!value) return "";
            var formatted = (jQuery.payment as JQueryPayment.ExpressPayment).formatCardNumber(value).trim();

            ngModel.$setViewValue(formatted);
            ngModel.$render();

            return formatted.replace(/ /g, "");
        });

        ngModel.$validators.invalid = (modelValue, viewValue) => {
            if (!modelValue) return true;

            const cardType = (jQuery.payment as JQueryPayment.ExpressPayment).cardType(modelValue);

            if (cardType) return jQuery.payment.validateCardNumber(modelValue);

            return true;
        };
        ngModel.$validators.empty = (modelValue, viewValue) => {

            if (!modelValue) {
                return false
            }
            return true;

        };
    };

    static Factory() {

        const directive = ($timeout) => {
            return new CreditCardNumber($timeout);
        };

        directive.$inject = ["$timeout"];
        return directive;
    }
}