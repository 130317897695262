import * as angular from "angular";
import { reflector } from "../index";

export function Component(
    options: {
        selector: string,
        controllerAs?: string,
        template?: string,
        templateUrl?: string,
        bindings?: any,
        transclude?: any,
        scope?: any,
        require?: any,
    }) {
    return (controller: Function) => {
        reflector.addComponentOptions(controller, options);
    };
}

