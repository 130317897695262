import { AppStateService } from '../../core/state';
import * as angular from "angular";

export class FocusIf {
    restrict = "A";

    static $inject = [
        "$timeout",
        "$rootScope",
        "AppStateService"
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $rootScope: ng.IRootScopeService,
        private appState: AppStateService
    ) { }

    link(scope, element: ng.IAugmentedJQuery, attrs) {
        if (element[0].tagName.toLowerCase() !== "input") {
            scope.$watch(attrs.becFocusIf, value => {
                setTimeout(() => {
                    if (value) {
                        element[0].focus();
                    }
                }, 100);
            });
        }
        this.$rootScope.$on("paymentMethodSelected", (event, paymentMethod) => {
            if (this.appState.lastAccordionExpandType !== "mouse") return;

            const { focusIfPaymentMethod } = attrs;
            if (!focusIfPaymentMethod) return;
            
            if (focusIfPaymentMethod === "invoice|partpayment") {
                if (paymentMethod.toLowerCase() === "invoice") {
                    setTimeout(() => angular.element(`bec-invoice-start[context="'invoice'"] #invoice_ssnInputField`).focus(), 150);
                    return;
                } else if(paymentMethod.toLowerCase() === "partpayment") {
                    setTimeout(() => angular.element(`bec-invoice-start[context="'partpayment'"] #partpayment_ssnInputField`).focus(), 150);
                    return;
                }
                 
            }

            if (focusIfPaymentMethod.toLowerCase() === paymentMethod.toLowerCase()) {
                setTimeout(() => element[0].focus(), 150);
            }
        });
    };
}