import { Component, SessionService, UrlService, WindowStateService } from "../../core/index";

import buttons from "../../styles/buttons.scss";
import app from "../../app.scss";

import { default as IconWarningMessage } from 'icons/icon-warning-message.svg';

const styles = {
    buttons: buttons,
    app: app
};

@Component({
    selector: "becSessionExpired",
    template: `
        <div class="${styles.app.errorMark}">
            <bec-svg class="icon ${styles.app.errorIcon}">${IconWarningMessage}</bec-svg>
        </div>

        <h2 class="${styles.app.expiredText}">{{$ctrl.expiredText}}</h2>

        <bec-ghost-button id="backBtn" ng-click="$ctrl.session.cancel()" ng-if="!$ctrl.windowState.isInline() && $ctrl.hasDeclineUrl">
            <translate>Back to {{$ctrl.session.merchant.name}}</translate>
        </bec-ghost-button>
    `
})
export class SessionExpiredComponent {
    static $inject = [
        "gettextCatalog",
        "SessionService",
        "WindowStateService",
        "UrlService"
    ];

    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        public session: SessionService,
        public windowState: WindowStateService,
        private urlService: UrlService
    ) {}

    get expiredText() {
        return this.gettextCatalog.getString("Session expired, please start over");
    }

    get hasDeclineUrl() {
        return this.urlService.declineUrl != null;
    }
}
