import { States } from "../../../app.states";
import {
    Component,
    AppStateService,
    SessionService,
    FeeService
} from "../../../core/index";

import orderlines from "./orderlines.scss";

const styles = {
    orderlines: orderlines,
};

@Component({
    selector: "becOrderlines",
    template: require("./orderlines.html")
})
export class OrderlinesComponent {
    static $inject = [
        "AppStateService",
        "SessionService",
        "FeeService"
    ];

    constructor(
        private appState: AppStateService,
        public session: SessionService,
        public fee: FeeService
    ) { }

    styles = styles;

    get orderLines() {
        try {
            return this.session.order.lines;
        } catch (e) {
            return [];
        }
    }
}
