export class BecFocusByMouse {
    link(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes
    ) {
        $element.mousedown(event => {
            $element.addClass("focus-by-mouse");
        });

        $element.blur(event => {
            $element.removeClass("focus-by-mouse");
        });
    };
}