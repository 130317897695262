export class BecUpdateLangAttribute {
    restrict = "A";

    static $inject = ["gettextCatalog"];

    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog
    ) { }
        
    link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs) => {        
        scope.$watch(() => this.gettextCatalog.getCurrentLanguage(), newValue => {
            element.attr("lang", newValue || "en");
        });        
    };
}