import { CustomerService, ErrorService } from "../../../core/index";
import {
    Component
} from "../../../core/index";

import userRegistrationForm from "../../shared/components/user-registration-form/user-registration-form.scss";

const styles = {
    userRegistrationForm: userRegistrationForm
};

@Component({
    selector: "becEnterManually",
    template: require("./enter-manually.html")
})
export class EnterManuallyComponent {
    static $inject = ["CustomerService", "ErrorService"];

    styles = styles;

    constructor(private customerService: CustomerService, private errorService: ErrorService) { }

    enterDetailsClicked() {
        this.errorService.hideError();
        this.customerService.deleteCustomer();
    }
}
