import { SessionExpiredComponent } from "../../shared";
import { States } from "../../app.states";
import {
    AuthorizeService,
    ErrorService,
    SessionService,
    AppStateService
} from "../../core/index";

export class ViaBillService {

    static $inject: string[] = [
        "AuthorizeService",
        "AppStateService",
        "ErrorService",
        "SessionService"
        ];

    public constructor(
        private authorizeService: AuthorizeService,
        private appStateService: AppStateService,
        private errorService: ErrorService,
        private sessionService: SessionService
    ) { }

    authorize() {
        this.appStateService.startFullScreenLoadingForAuthorize();
        let authorizePromise: ng.IPromise<Server.authorizeresponse> = this.authorizeService.authorizeViaBill();

        return authorizePromise.then((res: Server.authorizeresponse) => {
            if (res.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                return res;
            }
            
            if (!res.meta.result) {
                this.errorService.displayErrorMessage(res.meta.message.enduser, States.Session.ViaBill.name);
                this.appStateService.cancelLoading();
                return res;
            }

            if (res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPostValidation.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresRedirectValidation.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPollValidation.toString()) {
                return res;
            }

            this.sessionService.status = "authorized";
            this.appStateService.switchState(States.Session.ViaBill.Accept.name);

            this.appStateService.cancelLoading();
            return res;

        });
    }
}