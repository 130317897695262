import * as angular from "angular";
import "angular-gettext";
import { AppStateService, ConfigReaderService } from "../index";
import { DeepLinker } from "../utilities/deep-linker";

type ValidationResponse = Server.authorizeresponse & { finalRedirect: boolean };

export class ExternalAuthService {
  static $inject = [
    "AppStateService",
    "$window",
    "$timeout",
    "$q",
    "gettextCatalog",
    "$http",
    "ConfigReaderService"
  ];

  public constructor(
    private _appState: AppStateService,
    private _$window: ng.IWindowService,
    private _$timeout: ng.ITimeoutService,
    private _$q: ng.IQService,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private _$http: ng.IHttpService,
    private _configReaderService: ConfigReaderService
  ) { }

  cancelPolling: boolean = false;

  public stopPolling() {
    this.cancelPolling = true;
  }

  checkIfExtraValidationIsNeeded(response: Server.authorizeresponse, forceRedirect: boolean = false, deepLinker?: DeepLinker): ng.IPromise<ValidationResponse> {
    if (response.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPostValidation.toString()) {
      if (forceRedirect) {
        this._appState.startFullScreenLoadingForVerification();
        return this.postValidation(response);
      } else {
        this._appState.startFullScreenLoadingFor3DRedirect();
        return this.postOverlay(response);
      }
    }

    if (response.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresRedirectValidation.toString()) {
      this._appState.startFullScreenLoadingForVerification();
      return this.getRedirect(response, deepLinker);
    }

    if (response.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresPollValidation.toString()) {
      return this.pollAuthorize(response);
    }

    return this._$q.when({ ...response, finalRedirect: true });
  }

  getRedirect(response: Server.authorizeresponse, deepLinker?: DeepLinker): ng.IPromise<ValidationResponse> {
    if (deepLinker !== undefined && response.authorizeresponseurl.match(/^https?:\/\//) === null) {
      deepLinker.setAuthorizeResponse(response);

      // Parse URL
      const appSwitchUrl = new URL(response.authorizeresponseurl);
      const callback = appSwitchUrl.searchParams.get('callbackurl');
      const calbackUrl = new URL(callback);
      const ePayReturn = calbackUrl.searchParams.get('epayreturn');
      const ePayReturnUrl = new URL(ePayReturn);

      const pathArray = ePayReturnUrl.pathname.split("/");
      const pollToken = pathArray[pathArray.length - 1];

      const pollUrl = this._configReaderService.authorizePollEndpoint + pollToken;

      const modifiedAuthorizeResponse: Server.authorizeresponse = {
        accepturl: response.accepturl,
        authorizeresponseurl: pollUrl,
        declineurl: response.declineurl,
        meta: response.meta,
        method: "poll",
        parameters: response.parameters
      };

      deepLinker.openURL(response.authorizeresponseurl);

      return this.pollAuthorize(modifiedAuthorizeResponse, true);
    } else {
      this._$window.top.location.href = response.authorizeresponseurl;
    }

    return this._$q.when(response as ValidationResponse);
  }

  overlayIsActive = false;

  postOverlay(response: Server.authorizeresponse): ng.IPromise<ValidationResponse> {
    if (this.overlayIsActive) return;

    const self = this;
    self.overlayIsActive = true;

    const container = document.createElement("section");
    const title = document.createElement("h1");
    const formElement = document.createElement("form");
    const iframeContainer = document.createElement("div");
    const iframe = document.createElement("iframe");
    const preFocusTrap = document.createElement("div");
    const postFocusTrap = document.createElement("div");
    const backdrop = document.createElement("div");
    const acsBackdrop = document.createElement("div");

    container.id = "acsOverlay";
    container.className = "acsOverlay";
    container.setAttribute("role", "dialog");
    container.setAttribute("aria-labelledby", "acsOverlayTitle");

    title.id = "acsOverlayTitle";
    title.className = "offscreen";
    title.textContent = "3D Secure";
    title.tabIndex = 0;

    iframeContainer.tabIndex = 0;

    const iframeId = "validationFrame" + Math.floor((Math.random() * 100000) + 1);

    iframe.id = iframeId;
    iframe.name = iframeId;
    iframe.className = "validationFrame";
    iframe.style.opacity = "0";
    iframe.style.transform = "scale(0)";

    preFocusTrap.tabIndex = 0;
    preFocusTrap.addEventListener("focus", event => iframeContainer.focus());

    postFocusTrap.tabIndex = 0;
    postFocusTrap.addEventListener("focus", event => iframeContainer.focus());

    backdrop.className = "backdrop";
    backdrop.style.opacity = "0";

    acsBackdrop.className = "acsBackdrop";
    acsBackdrop.style.opacity = "0";
    acsBackdrop.style.transform = "scale(0)";

    formElement.target = iframeId;
    formElement.method = "POST";
    formElement.action = response.authorizeresponseurl;
    formElement.style.display = "none";

    response.parameters.forEach(x => {
      const inputElement = document.createElement("input");

      inputElement.value = x.value;
      inputElement.name = x.key;
      inputElement.type = "hidden";

      formElement.appendChild(inputElement);
    });

    // Create backdrop overlay
    document.body.appendChild(backdrop);
    backdrop.appendChild(acsBackdrop);

    // Create ACS overlay
    document.body.appendChild(container);
    container.appendChild(title);
    container.appendChild(preFocusTrap);
    container.appendChild(iframeContainer);
    iframeContainer.appendChild(iframe);
    container.appendChild(postFocusTrap);
    container.appendChild(formElement);

    formElement.submit();

    this._$timeout(() => {
      backdrop.style.opacity = "1";
      iframe.style.opacity = "1";
      iframe.style.transform = "scale(1)";
      acsBackdrop.style.opacity = "1";
      acsBackdrop.style.transform = "scale(1)";

      this._$timeout(() => iframeContainer.focus());
    }, 500);

    const errorMessage = this.gettextCatalog
      .getString("Authentication using 3D Secure failed, please try again.");

    const promise = this._$q<Server.authorizeresponse>((resolve, reject) => {
      window.addEventListener("message", onMessageReceived);

      function onMessageReceived(event: MessageEvent) {
        const origin = event.origin || (event as any).originalEvent.origin;
        if (origin !== location.origin) return;

        if (event.data && event.data.action === "returnfrom3d") {
          window.removeEventListener("message", onMessageReceived);

          const newResponse: ValidationResponse = angular.extend({}, response);

          const regex = /returnfrom3d(accept|decline)/;
          const result = regex.exec(event.data.href);

          if (result && result[1] === "accept") {
            newResponse.authorizeresponseurl = event.data.href;
            newResponse.finalRedirect = true;

            newResponse.meta.action.code = "20000";
            newResponse.meta.action.type = "success";
            newResponse.meta.result = true;

            resolve(newResponse);
          } else {
            newResponse.meta.action.code = "40010";
            newResponse.meta.action.type = "PaymentOptionCouldNotBeAuthorized";
            newResponse.meta.message.enduser = errorMessage;
            newResponse.meta.message.merchant = errorMessage;
            newResponse.meta.result = false;
            reject(newResponse);
          }
        }
      }
    }).then(response => {
      hideAndRemoveOverlay();
      return response;
    }).catch(response => {
      hideAndRemoveOverlay();
      return response;
    });

    return promise;

    function hideAndRemoveOverlay() {
      backdrop.style.opacity = "0";
      iframe.style.opacity = "0";
      iframe.style.transform = "scale(0)";
      acsBackdrop.style.opacity = "0";
      acsBackdrop.style.transform = "scale(0)";

      setTimeout(() => {
        document.body.removeChild(container);
        document.body.removeChild(backdrop);

        self.overlayIsActive = false;
      }, 500);
    }
  }

  postValidation(response: Server.authorizeresponse) {
    const formElement = document.createElement("form");

    formElement.target = "_top";
    formElement.method = "POST";
    formElement.action = response.authorizeresponseurl;
    formElement.style.display = "none";

    response.parameters.forEach(x => {
      const inputElement = document.createElement("input");

      inputElement.value = x.value;
      inputElement.name = x.key;
      inputElement.type = "hidden";

      formElement.appendChild(inputElement);
    });

    document.body.appendChild(formElement);

    formElement.submit();

    return this._$q.when(response as ValidationResponse);
  }

  pollAuthorize(response: Server.authorizeresponse, skipLoader: boolean = false, returnDeclineUrl: boolean = false): ng.IPromise<ValidationResponse> {
    this.cancelPolling = false;
    const self = this;
    var retryCount = 0;

    function poll(pollUrl: string): ng.IPromise<ValidationResponse> {
      if (self.cancelPolling) {
        return;
      }

      let errorMessage = self.gettextCatalog.getString("Mobile BankID verification failed.");
      const newResponse: ValidationResponse = angular.extend({}, response);

      return self._$http({
        method: 'GET',
        url: pollUrl
      }).then(function successCallback(resp: any): ng.IPromise<ValidationResponse> {
        if (self.cancelPolling) {
          return;
        }

        const zeroPollResponse: Zero.pollresponse = resp.data;

        if (zeroPollResponse.messages && zeroPollResponse.messages[zeroPollResponse.messages.length - 1]) {
          const pollMessage = zeroPollResponse.messages[zeroPollResponse.messages.length - 1].message;
          const realMessage = self.getPollMessage(pollMessage);

          if (pollMessage.toLowerCase().indexOf("requiresidentification") > -1) {
            const typeRegex = /<([a-zA-Z.]*)>/;
            const authenticationType = typeRegex.exec(pollMessage);
            if (!skipLoader) {
              if (authenticationType && authenticationType[1]) {
                self._appState.startFullScreenLoadingForExternalAuthentication(realMessage, authenticationType[1]);
              } else {
                self._appState.startFullScreenLoadingWithMessage(realMessage);
              }
            }
          } else if (pollMessage.indexOf("[Swish]") > -1) {
            errorMessage = self.gettextCatalog.getString("Swish verification failed.");
            if (!skipLoader) {
              self._appState.startFullScreenLoadingForExternalAuthentication(self.gettextCatalog.getString("Please complete the payment in the Swish app"), "swish");
            }
          } else {
            if (!skipLoader) {
              self._appState.startFullScreenLoadingWithMessage(realMessage);
            }
          }
        }

        if (zeroPollResponse.wait) {
          return poll(zeroPollResponse.pollurl);
        }
        else {
          if (self.cancelPolling) {
            return;
          }

          if (zeroPollResponse.authorizeresult && zeroPollResponse.redirecturl) {
            newResponse.meta.action.code = "20000";
            newResponse.meta.action.type = "success";
            newResponse.authorizeresponseurl = zeroPollResponse.redirecturl;
            newResponse.meta.result = true;
            newResponse.finalRedirect = true;
          } else {
            newResponse.meta.action.code = "40011";
            newResponse.meta.action.type = "paymentoptioncouldnotbeauthorized";
            newResponse.meta.message.enduser = errorMessage;
            newResponse.meta.message.merchant = errorMessage;
            if (returnDeclineUrl) {
              newResponse.authorizeresponseurl = zeroPollResponse.redirecturl;
            }
            newResponse.meta.result = false;
          }

          return self._$q.when(newResponse);
        }
      }, function errorCallback(resp: any): ng.IPromise<ValidationResponse> {
        if (self.cancelPolling) {
          return;
        }

        if (retryCount < 3) {
          retryCount++;
          return self._$timeout(() => { }, 1000 * Math.pow(retryCount, 2)).then(() => { return poll(pollUrl) });
        }

        newResponse.meta.action.code = "40011";
        newResponse.meta.action.type = "paymentoptioncouldnotbeauthorized";
        newResponse.meta.message.enduser = errorMessage;
        newResponse.meta.message.merchant = errorMessage;
        newResponse.meta.result = false;

        return self._$q.when(newResponse);
      });
    }

    if (self.cancelPolling) {
      return;
    }

    return poll(response.authorizeresponseurl);
  }

  private getPollMessage(pollResponseMessage: string): string {
    const regex = /([^<]*)/;
    const regexResult = regex.exec(pollResponseMessage);
    if (!regexResult || !regexResult[1]) {
      return " ";
    }
    const messageType = regexResult[1].toLowerCase();

    switch (messageType) {
      case "requiresidentification":
        return this.gettextCatalog.getString("Please verify using Mobile BankID.");
      case "identificationsuccess":
        return this.gettextCatalog.getString("Verification successful, waiting for Collector Bank.");
      case "identificationfailed":
        return " ";//this.gettextCatalog.getString("Authentication failed.");
      case "authorizesuccess":
        return " ";//this.gettextCatalog.getString("Payment authorized by Collector Bank.");
      case "authorizefailed":
        return " ";//this.gettextCatalog.getString("Payment declined by Collector Bank");
      default:
        return " ";
    }

  }
}
