import { PhoneNumberService } from "../index";


export interface IPhonenumberAttributes extends ng.IAttributes {
    phonenumber: string;
}

export interface IPhonenumberScope extends ng.IScope {
    countrycode: string;
}

export class Phonenumber {
    restrict = "A";
    require = "ngModel";
    scope = {
        countrycode: "=",

    };


    static $inject = ["$timeout", "$compile", "PhoneNumberService"];

    constructor(private $timeout, private $compile, private phoneutil: PhoneNumberService) {
    }


    link = (scope: IPhonenumberScope, element: ng.IAugmentedJQuery, attrs: IPhonenumberAttributes, ngModel) => {
        if (!ngModel) return;

        element.on("focus",
        () => {
            if (!scope.countrycode) {
                $("#countrycode").focus();
            }
        });
        scope.$watch("countrycode",
        () => {
            if (!ngModel.$viewValue || !scope.countrycode) return "";

            element.val(this.phoneutil.parse(ngModel.$viewValue, scope.countrycode).formatted);
            ngModel.$validate();
        });
        ngModel.$formatters.push((value: string) => {
            if (!value || !scope.countrycode) return "";
            return this.phoneutil.parse(value, scope.countrycode).formatted;
        });

        ngModel.$parsers.push((value) => {
            if (!value || !scope.countrycode) return "";
            element.val(this.phoneutil.parse(value, scope.countrycode).formatted);

            return this.phoneutil.parse(value, scope.countrycode).nationalNumber;
        });

        ngModel.$validators.phonenumber = (modelValue, viewValue) => {

            if (ngModel.$isEmpty(viewValue)) {
                return true;
            }
            return this.validateRawPhoneNumber(viewValue, scope.countrycode);
        };
    };

    private validateRawPhoneNumber(phoneNumberRaw: string, countrycode: string): boolean {
        const phoneInfo = this.phoneutil.parse(phoneNumberRaw, countrycode);
        return phoneInfo.isValid;
    }
    
    static Factory() {

        const directive = ($timeout, $compile, PhoneNumberService) => {
            return new Phonenumber($timeout, $compile, PhoneNumberService);
        };
        directive.$inject = ["$timeout", "$compile", "PhoneNumberService"];
        return directive;
    }
}