import * as angular from "angular";

export class ShowOnCtrlKey {
    restrict = "A";
    replace = false;
    static $inject = ["$window"];
    
    public constructor(private $window) {}

    link = (scope, element, attr) => {
        scope.show = false;
        var key = attr.becShowOnCtrlKey || 81;

        angular
            .element(this.$window)
            .on("keydown",
                e => {
                    if (e.ctrlKey && e.keyCode == key) {
                        scope.show = !scope.show;
                        scope.$apply();
                    }
                });
    };
}