import {
    Component,
    FeeService,
    SessionService
} from "../../core/index";

import paymentHeader from "./payment-header/payment-header.scss";

const styles = {
    paymentHeader: paymentHeader
};

@Component({
    selector: "becFeeDisplay",
    template: require("./fee-display.html")
})
export class FeeDisplay {
    static $inject = ["FeeService", "SessionService"];
    styles = styles;

    constructor(private _feeService: FeeService, private _sessionService: SessionService) { }

    get displayFee() {
        return this._feeService.displayFee;
    }

    get feeAmount() {
        return this._feeService.feeAmount;
    }

    get currency() {
        return this._sessionService.order.currency;
    }
}
