import * as angular from "angular";

import styles from "./tooltip.scss";

export class BecTooltip {
    static $inject = ["$animate", "$timeout"];

    constructor(private _$animate: ng.animate.IAnimateService, private _$timeout: ng.ITimeoutService) {}

    restrict = "E";
    scope = false;
    priority = 1000;

    link = ($scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $ctrl: Array<ng.INgModelController>,
        $transclude: ng.ITranscludeFunction) => {
        const parent = $element.parent();
        const $animate = this._$animate;
        const $timeout = this._$timeout;
        const $document = angular.element(document);
        const $window = angular.element(window);

        let isHidden = true;
        let isAnimatingHide = false;

        function init() {
            parent.bind("click", onShowTooltip);
            $document.bind("click", onHideTooltip);
            $document.bind("scroll", onScroll);
            $window.bind("resize", onResize);
            $scope.$on("$destroy", onDestroy);
            $element.detach();
        }

        function onDestroy(event: ng.IAngularEvent) {
            parent.unbind("click", onShowTooltip);
            $document.unbind("click", onHideTooltip);
            $document.unbind("scroll", onScroll);
            $window.unbind("resize", onResize);
            $element.remove();
        }

        function onShowTooltip(event: JQueryEventObject) {
            if (!isHidden) return;
            isHidden = false;

            $element.css("visibility", "hidden");
            angular.element(document.body).append($element);

            positionElement();

            $timeout(() => {
                $element.css("visibility", "");
                $animate.addClass($element, "animate-height");
            });
        }

        function positionElement() {
            const parentRect = parent[0].getBoundingClientRect() as ClientRect;
            const bodyRect = document.body.getBoundingClientRect() as ClientRect;

            const scrollPos = {
                top: $window.scrollTop(),
                left: $window.scrollLeft()
            };
            $element.css({
                position: "absolute",
                top: parentRect.bottom + 5 + scrollPos.top,
                left: getLeftPosition(parentRect, parent.outerWidth(), bodyRect, $element.outerWidth())
            });
        }

        function getLeftPosition(parentRect: ClientRect,
            parentWidth: number,
            bodyRect: ClientRect,
            elementWidth: number) {
            const bodyPadding = 10;
            const parentCenter = (parentWidth / 2);
            const elementCenter = (elementWidth / 2);
            const minLeftPosition = bodyRect.left + bodyPadding;
            const maxLeftPosition = bodyRect.right - (elementWidth + bodyPadding);

            let leftPosition = parentRect.left + (parentCenter - elementCenter);

            if (leftPosition < minLeftPosition) {
                leftPosition += minLeftPosition - leftPosition;
            } else if (leftPosition > maxLeftPosition) {
                leftPosition -= leftPosition - maxLeftPosition;
            }

            return leftPosition;
        }

        function onHideTooltip(event?: JQueryEventObject) {
            if (event) {
                const target = angular.element(event.target);
                const isRelatedToTargetOrElement = parent.is(target) ||
                    parent.has(target[0]).length ||
                    $element.is(target) ||
                    $element.has(target[0]).length;

                if (!isRelatedToTargetOrElement) {
                    hideTooltip();
                }
            } else {
                hideTooltip();
            }
        }

        function hideTooltip() {
            if (!isAnimatingHide && !isHidden) {
                isAnimatingHide = true;

                $timeout(() => {
                    $animate.removeClass($element, "animate-height")
                        .then(() => {
                            $element.detach();
                            isAnimatingHide = false;
                            isHidden = true;
                        });
                });
            }
        }

        function onResize(event: JQueryEventObject) {
            onHideTooltip();
        };

        function onScroll(event: JQueryEventObject) {
            onHideTooltip();
        };

        init();
    };
}
