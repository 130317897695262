import { States } from "../../../app.states";
import { Component } from "../../../core/index";
import { WalletService } from "../../index";

const styles = {
    base: require("../../../styles/base.scss"),
    buttons: require("../../../styles/buttons.scss")
};

@Component({
    selector: "becGooglePayStart",
    template: require("./googlepay-start.html")
})
export class GooglePayStart {
    styles = styles;
    paymentTypeName = "GooglePay";

    static $inject = [
        "WalletService", "gettextCatalog"
    ];

    constructor(
        private walletService: WalletService,
        private gettextCatalog: ng.gettext.gettextCatalog,
    ) { }

    $onInit() {
        if (this.walletService.isGooglePayAvailable) {
            const container = document.getElementById("googlePayButtonContainer");

            this.walletService.googlePayWallet.createButton(container, {
                buttonColor: "default",
                buttonType: "plain",
                buttonSizeMode: "fill",
                buttonLocale: this.gettextCatalog.getCurrentLanguage(),
                onClick: () => this.walletService.openWallet("expressoption", this.walletService.googlePayWallet.start, States.Session.GooglePay.Accept.name)
            });
        }
    }
}
