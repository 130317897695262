import {
    AppStateService,
    PaymentCardService,
    ExternalAuthService
} from "../../core/index";


export class DemoVisibility {
    static $inject = [
        "AppStateService", "SessionService", "CustomerService", "ErrorService", "PaymentCardService",
        "ExternalAuthService"
    ];
    
    restrict = "AE";
    template = require("./demo-visibility.html");
    replace = false;
    
    constructor(private appState: AppStateService,
        private session,
        private customer,
        private errors,
        private pcService: PaymentCardService,
        private externalAuth: ExternalAuthService) {
    }

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {
        scope.customer = this.customer;
        scope.errors = this.errors;
        scope.appState = this.appState;
        scope.pcService = this.pcService;

        scope.changeState = (state: string) => {
            this.appState[state] = !this.appState[state];
        };
        scope.changeErrorState = (state: string) => {
            this.errors[state] = !this.errors[state];
        };
        scope.simulate3d = () => {
            this.appState.startFullScreenLoadingFor3DRedirect();
            var request = {
                method: "POST",
                parameters: [{ key: "val", value: "ddfdf" }],
                declineurl: "",
                accepturl: "",
                authorizeresponseurl: "",
                customer: null,
                meta: null
            };
            // this.externalAuth.postRedirect(request, true);
        };
    };
}