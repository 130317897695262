import { States } from "../../app.states";

export class ErrorService {

    static $inject = ["$state", "gettextCatalog"];


    // Errors
    errorMessage: string = "";
    errorMessageVisible: boolean = false;
    smsErrorMessageVisible: boolean = false;
    errorContext: string = "";

    public constructor(private $state, private gettextCatalog: ng.gettext.gettextCatalog) {
    }


    // Methods
    getAll() {
        const arrayOfProperties = Object.keys(this).sort();
        const all = {};

        for (let i = 0; i < arrayOfProperties.length; i++) {
            if (typeof (this[arrayOfProperties[i]]) === "boolean") {
                all[arrayOfProperties[i]] = this[arrayOfProperties[i]];
            }
        }

        return all;
    }

    // todo: context should be requried
    displayErrorMessage(msg: string, context: string = "") {
        this.errorMessage = msg;
        this.errorMessageVisible = true;
        this.errorContext = context;
    }

    displayExternalErrorMessage(msg: string, context: string = "") {

        if(msg) {
            this.displayErrorMessage(msg, context);
        }
        else {
            this.displayErrorMessage(this.gettextCatalog
                .getString("The payment could not be processed, please try again or choose another payment method."), context);
        }
    }

    hideError() {
        this.errorMessageVisible = false;
        this.smsErrorMessageVisible = false;
    }

    showSmsError() {
        this.smsErrorMessageVisible = true;
    }

    reset() {
        if (this.$state.current.name !== States.Session.name &&
             this.$state.current.name !== States.Session.PartPayment.Start.name) {
            this.hideError();
            this.errorContext = "";
        }
        if (this.$state.current.name === States.Session.PaymentCard.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using 3D Secure failed, please try again."), States.Session.PaymentCard.name);
        }
        if (this.$state.current.name === States.Session.Expired.name) {
            this.displayErrorMessage(this.gettextCatalog.getString("Session expired, please start over"), States.Session.PaymentCard.name);
        }

        if (this.$state.current.name === States.Session.EkspresBank.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using Ekspres Bank failed, please try again."), States.Session.EkspresBank.name);
        }

        if (this.$state.current.name === States.Session.Vipps.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using Vipps failed, please try again."), States.Session.Vipps.name);
        }

        if (this.$state.current.name === States.Session.MobilePay.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using MobilePay failed, please try again."), States.Session.MobilePay.name);
        }

        if (this.$state.current.name === States.Session.ApplePay.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using Apple Pay failed, please try again."), States.Session.ApplePay.name);
        }

        if (this.$state.current.name === States.Session.GooglePay.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using Google Pay failed, please try again."), States.Session.GooglePay.name);
        }

        if (this.$state.current.name === States.Session.PartPayment.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("The payment could not be processed, please try again or choose another payment method."), States.Session.PartPayment.name);
        }

        if (this.$state.current.name === States.Session.Invoice.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("The payment could not be processed, please try again or choose another payment method."), States.Session.Invoice.name);
        }

        if (this.$state.current.name === States.Session.NordeaEPaymentFI.Decline.name) {
            this.displayErrorMessage(this.gettextCatalog
                .getString("Authentication using Direct Banking failed, please try again."), States.Session.NordeaEPaymentFI.name);
        }

        if (this.$state.current.name === States.Session.Swish.Decline.name) {
          this.displayErrorMessage(this.gettextCatalog
            .getString("The payment could not be processed, please try again or choose another payment method."), States.Session.Swish.name);
        }

    }
}
