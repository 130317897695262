import { Component } from "../../../core/index";

import nameEmailEdit from "./name-email-edit.scss";
import app from "../../../app.scss";
import base from "../../../styles/base.scss";
import input from "../../../shared/components/input/input.scss";
import buttons from "../../../styles/buttons.scss";

const styles = {
    nameEmailEdit: nameEmailEdit,
    app: app,
    base: base,
    input: input,
    buttons: buttons
};

@Component({
    selector: "becNameEmailEdit",
    template: require("./name-email-edit.html"),
    bindings: {
        email: "<",
        onEmailChange: "&",
        context: "<"
    }
})
export class NameEmailEditController {
    styles = styles;
    email: string;
    onEmailChange;
    name: string;
    context: "invoice" | "partpayment" = "invoice";

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }

    onChange = () => {
        this.onEmailChange({ email: this.email });
    };
}
