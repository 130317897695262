export class DeltaFoxService {
    private _deltaFoxId = null;

    static $inject = ["$rootScope"]; 

    static NewDFIdBroadcaseIdentifier = "bec-new-delta-fox-id";

    constructor(private $rootScope: ng.IRootScopeService) {
            
    }

    setDeltaFoxId(id) {
        // todo: replace the broadcast here with RXJs or Angular2 Event emitter or sil     
        if (this._deltaFoxId !== id) {
            this._deltaFoxId = id;
            //[BOC-3364] Removes ThreatMetrix
            //this.$rootScope.$emit(DeltaFoxService.NewDFIdBroadcaseIdentifier, id);
        }
    }

    getDeltaFoxId() {
        return this._deltaFoxId;
    }

}