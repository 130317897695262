import { Component } from "../../../../core/index";
import {
    CustomerService,
    SessionService
} from "../../../../core/index";
import { SmsService } from "../../index";
import { States } from "../../../../app.states";

import base from "../../../../styles/base.scss";
import userRegistrationForm from "./user-registration-form.scss";
import input from "../../../../shared/components/input/input.scss";
import icons from "../../../../styles/icons.scss";
import buttons from "../../../../styles/buttons.scss";

const styles = {
    base: base,
    userRegistrationForm: userRegistrationForm,
    input: input,
    icons: icons,
    buttons: buttons
};

@Component({
    selector: "becUserRegistrationForm",
    template : require("./user-registration-form.html"),
    require: {
        form: "^form"
    }
})
export class UserRegistrationForm {
    static $inject = [
        "CustomerService",
        "SessionService",
        "SmsService",
        "$state"
    ];

    isSavingUser = false;
    styles = styles;

    constructor(
        public customer: CustomerService,
        public session: SessionService,
        public sms: SmsService,
        private $state: ng.ui.IStateService
    ) { }

    createCustomer() {
        this.isSavingUser = true;
        this.customer.createCustomer().then(() => {
            this.isSavingUser = false;
        });
    }

    getPhoneNumberInputContainerClasses(phoneNumberInvalid, focusPhone) {
        return `${styles.userRegistrationForm.countryCodeInputContainer} ${
            phoneNumberInvalid ? styles.input.invalid : ""
        } ${
            focusPhone ? styles.input.inputField : ""
        }`;
    }

    getRememberMeAlignmentClasses() {
        return this.$state.current.name !== States.Session.PaymentCard.Start.name ?
            styles.userRegistrationForm.remember : "";
    }

    get showUserRegistration(): boolean {
        if (this.session.immediateRedirectToAccept) {
            return this.session.delayedRedirectToAcceptWasCancelled;
        }

        if (this.customer.hasBeenRememberedForPaymentCard && this.customer.keepClientDetails) {
            return false;
        }

        return true;
    }
}
