export class BecSvg {
    static $inject = ["$cacheFactory", "$http", "$log", "$compile"];

    constructor(
        private $cacheFactory: ng.ICacheFactoryService,
        private $http: ng.IHttpService,
        private $log: ng.ILogService,
        private $compile: ng.ICompileService
    ) {
        this._svgCache = this.$cacheFactory.get("svg-cache");
    }

    restrict = "E";
    transclude = true;

    private _svgCache: ng.ICacheObject;

    compile(tElement, tAttrs, transclude: ng.ITranscludeFunction) {
        return {
            pre: (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
                if (attrs["becSvgSrc"] || attrs["becSvgSrc"] === "") {
                    scope.$watch(attrs["becSvgSrc"],
                        (newSrc: string) => {
                          if(!newSrc)
                          {
                            return;
                          }

                          const icon = attrs["raw"] == "true" ? require(`!!raw-loader!../../../assets/icons/${newSrc}.svg`) : require(`../../../assets/icons/${newSrc}.svg`);

                          this.renderSvg(icon.default, attrs["raw"] as boolean, element);
                        });
                    attrs.$observe("becSvgSrc",
                        (src: string) => {
                            const icon = attrs["raw"] == "true" ? require(`!!raw-loader!../../../assets/icons/${src}.svg`) : require(`../../../assets/icons/${src}.svg`);

                            this.renderSvg(icon.default, attrs["raw"] as boolean, element)
                        });
                } else {
                    transclude(scope,
                        (clone) => {
                          if(attrs["raw"] === "true") {
                            const icon = require(`!!raw-loader!../../../assets/icons/${clone[0].textContent.trim()}.svg`)
                            this.renderSvg(icon.default, true, element);
                          } else {
                            this.renderSvg(clone[0].textContent.trim(), false, element);
                          }
                        });
                }
            }
        };
    }

    private renderSvg(base64svg: string, raw: boolean, element: ng.IAugmentedJQuery): void {
        if (!base64svg) {
            element.html("");
            return;
        }

        element.html("");
        element.append(raw ? base64svg : `<img src="${base64svg}" />`);
    }
}
