import { States } from '../../app.states';
import {
    Component,
    ErrorService
} from "../../core/index";
import { InvoiceService } from "../index";

import invoiceDecline from "./invoice-decline.scss"
import buttons from "../../styles/buttons.scss";

const styles = {
    invoiceDecline: invoiceDecline,
    buttons: buttons
};

@Component({
    selector: "becInvoiceDecline",
    template: require("./invoice-decline.html")
})
export class InvoiceDecline {
    static $inject = [
        "InvoiceService",
        "ErrorService"
    ];

    styles = styles;
    context: string = States.Session.Invoice.Decline.name + States.Session.Invoice.Decline.name;
    constructor(
        private invoice: InvoiceService,
        private errorService: ErrorService
    ) {}

    $onInit() {
        const label = this.invoice.lastEndUserLabel;
        if(label) {
            this.errorService.displayErrorMessage(label, this.context);
        }
    }

    retry() {
        this.invoice.retry();
    }

    switchToCardPayment() {
        this.invoice.switchToCardPayment();
    }

    get customerIsRejectedAsCustomer() {
        return this.invoice.customerIsRejectedAsCustomer;
    }

    get hasErrorMessage() {
        return !!this.errorService.errorMessage;
    }

    hasError() {
        return this.errorService.errorContext === this.context;
    }
}
