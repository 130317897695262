import { ICustomerDetails } from "../../../core";
import { Component, SessionService, CustomerService, AppStateService } from "../../../core/index";
import { WalletService } from "../../index";
import { States } from "../../../app.states";
import { WalletConstants } from "../../";
import { Vipps } from "@bambora/wallet";
import { IVippsRequestData } from "@bambora/wallet/dist/types/clients/vipps";

import base from "../../../styles/base.scss";
import buttons from "../../../styles/buttons.scss";

const styles = {
    base: base,
    buttons: buttons
};

@Component({
    selector: "becVippsStart",
    template: require("./vipps-start.html")
})

export class VippsStart {
    styles = styles;
    customer: ICustomerDetails;
    phonePlaceholder: string;
    paymentTypeName = "Vipps";

    static $inject = [
        "WalletService", "SessionService", "CustomerService", "gettextCatalog", "$state", "AppStateService"
    ];

    constructor(
        private walletService: WalletService,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private $state,
        public appState: AppStateService
    ) {
        this.customer = customerService.details;
        this.phonePlaceholder = this.gettextCatalog.getString("Phone number");
    }

    isDemo() {
        return this.sessionService.isDemo();
    }

    hasError() {
        return this.walletService.showErrorMessage(States.Session.Vipps.name);
    }

    continue() {
        this.openWallet();
    }

    private openWallet() {
        const acceptUrl: string = this.$state.href(States.Session.Vipps.Accept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });
        const declineUrl: string = this.$state.href(States.Session.Vipps.Decline.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });

        const additionalData: Server.data[] = [
            {
                key: "CustomerMobileNumber",
                value: this.customer.phonenumber
            }
        ];

        if (this.appState.isAppSwitchSupported("vipps")) {
            additionalData.push({ key: "IsApp", value: "true" })
        }

        const vippsWallet = Vipps.create({ target: "_top" }, {
            sessionProvider: {
                fetchSession: async () => {
                    return this.walletService.sessionProvider<IVippsRequestData>(
                        States.Session.Vipps.name,
                        WalletConstants.VIPPS,
                        acceptUrl,
                        declineUrl,
                        false,
                        this.appState.options.appReturnUrl,
                        additionalData);
                }
            }
        });

        this.walletService.openWallet(States.Session.Vipps.name, vippsWallet.start, States.Session.Vipps.Accept.name);
    }
}
