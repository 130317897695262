import { Component, WindowStateService } from "../../core/index";

import buttons from "../../styles/buttons.scss";

const styles = {
    buttons: buttons
};

export class WhiteButton {
    restrict = "E";
    static $inject = [];
    replace = true;
    transclude = true;
    template = `
        <button type="button" class="${styles.buttons.white}" bec-ripple="rgba(0,0,0,0.2)" bec-focus-by-mouse>
            <ng-transclude></ng-transclude>
        </button>
    `;
}

export class GhostButton {
    restrict = "E";
    static $inject = [];
    replace = true;
    transclude = true;
    template = `
        <button type="button" class="${styles.buttons.ghost} ${styles.buttons.wide} animate-height" bec-ripple="rgba(0,0,0,0.2)" bec-focus-by-mouse>
            <ng-transclude></ng-transclude>
        </button>
    `;
}

export class PrimaryButton {
    static $inject = ["gettextCatalog"];

    constructor(private gettextCatalog: ng.gettext.gettextCatalog) {}

    restrict = "E";
    replace = true;
    transclude = true;
    template = `
        <button type="submit" class="${styles.buttons.wide} ${styles.buttons.primary}" bec-ripple="rgba(255,255,255,0.2)" bec-focus-by-mouse>
            <ng-transclude>{{primaryButtonText}}</ng-transclude>
        </button>
    `;
    link = (scope, element: ng.IAugmentedJQuery, attrs) => {
        scope["primaryButtonText"] = this.gettextCatalog.getString("Continue");
    };
}

@Component({
    selector: "becBackButton",
    bindings: { merchantName: "@" },
    template: `<bec-ghost-button id="backBtn" ng-if="!$ctrl.windowState.isInline()">{{$ctrl.text}}</bec-ghost-button>`
})
export class BackButtonController {
    static $inject = ["gettextCatalog", "WindowStateService"];
    merchantName = "webshop";

    get text() {
        return this.gettextCatalog.getString("Back to {{merchantName}}", { merchantName: this.merchantName });
    }

    constructor(
        public gettextCatalog: ng.gettext.gettextCatalog,
        public windowState: WindowStateService
    ) {}
}
