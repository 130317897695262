import { AppStateService, Component} from "../../core/index";

import shakeView from "./shake-view.scss";
import icons from "../../styles/icons.scss";

const styles = {
    shakeView: shakeView,
    icons: icons
};

@Component({
    selector: "becShakeView",
    template: require("./shake-view.html")
})
export class ShakeView {
    static $inject = ["AppStateService"];

    styles = styles;

    constructor(private appStateService: AppStateService) { }

    get showDemoLoader() {
        return this.appStateService.showDemoLoader;
    }

    get isFullScreenLoading() {
        return this.appStateService.isFullScreenLoading;
    }

    get isTestMerchant() {
        return this.appStateService.isTestMerchant;
    }

    get onMobile() {
        return (window.innerWidth || screen.width) < 750;
    }
}


export function AnimateShakeView($animateCss, $timeout: ng.ITimeoutService, $animate): any {
    return {
        enter: (element: JQuery, doneFn) => {
            element
                .find(`.${styles.shakeView.circle}, .${styles.shakeView.circleImageContainer}, .${styles.shakeView.circleImage}, .${styles.shakeView.text}`)
                .removeClass("ng-leave")
                .addClass("ng-enter");

            return $animateCss(element,
            {
                event: "enter",
                structural: true
            });
        },
        leave: (element: JQuery, doneFn) => {
            element
                .find(`.${styles.shakeView.circle}, .${styles.shakeView.circleImageContainer}, .${styles.shakeView.circleImage}, .${styles.shakeView.text}`)
                .removeClass("ng-enter")
                .addClass("ng-leave");

            return $animateCss(element,
            {
                event: "leave",
                structural: true
            });
        }
    };
}
