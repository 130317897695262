import { Component } from "../../../core/index";

import footer from "./footer.scss";
import icons from "../../../styles/icons.scss";

import WorldlineLogo from '../../../../assets/icons/logo-worldline.svg';

const styles = {
    footer: footer,
    icons: icons
};

@Component({
    selector: "becFooter",
    template: `
        <div class="${styles.footer.footer}">
            <div ng-transclude="prepend"></div>
            <div class="${styles.footer.logoText}">
                {{$ctrl.footerLogoText}}
                <bec-svg class="${styles.footer.logoImage}">${WorldlineLogo}</bec-svg>
            </div>
            <div ng-transclude="append"></div>
        </div>
    `,
    scope: {},
    transclude: {
        prepend: "?becFooterPrepend",
        append: "?becFooterAppend"
    }
})
export class FooterComponent {
    static $inject = ["gettextCatalog"];

    constructor(private gettextCatalog: ng.gettext.gettextCatalog) {}

    get footerLogoText() {
        return this.gettextCatalog.getString("Secure payments provided by");
    }
}
