import $ from "jquery";

import styles from "./input.scss";

export class BecInput {
    scope = false;
    restrict = "E";

    static $inject = ["$timeout", "$compile"];
    require = "^^form";

    constructor(private $timeout, private $compile: ng.ICompileService) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, form) => {
        const inputField = element.find("input").last();

        inputField.addClass(styles.inputField);
        inputField.attr("size", inputField.attr("size") || 1);

        attrs.$observe("becDisabled", shouldDisable => {
            const ngModel: ng.INgModelController = form[inputField.attr("name")];

            if (shouldDisable === "true") {
                inputField.attr("disabled", "disabled");

                ngModel.$setViewValue("");
                ngModel.$render();

                Object.keys(ngModel.$validators).map(
                    type => ngModel.$setValidity(type, true)
                );
            } else if (shouldDisable === "false") {
                if (inputField.attr("disabled")) {
                    inputField.removeAttr("disabled");
                    ngModel.$validate();
                }
            }
        });

        scope.$watch(() =>  inputField.attr("name"),  // We need to use watch, as expressions might needs to get evaluated
        (val) => {
            const ngModel: ng.INgModelController = form[val];

            if (!ngModel) {
                return;
            }

            ngModel.$parsers.unshift((val) => {
                (ngModel as any).$becChanged = true;
                return val;
            });

            scope.$watch(
                $scope => {
                    return ngModel.$valid &&
                        ngModel.$viewValue &&
                        ngModel.$viewValue.length > 0;
                },
                (newValue, oldValue, $scope) => {
                    if (newValue) {
                        element.addClass(styles.valid);
                    } else {
                        element.removeClass(styles.valid);
                    }
                });

            scope.$watch(
                $scope => {
                    return ngModel.$invalid &&
                        !(ngModel as any).$becChanged &&
                        ngModel.$touched &&
                        ngModel.$viewValue &&
                        ngModel.$viewValue.length > 0;
                },
                (newValue, oldValue, $scope) => {
                    this.setValidity(ngModel, element, newValue);
                });


            inputField.blur(event => {
                element.removeClass(styles.focus);
                if (ngModel) {
                    (ngModel as any).$becChanged = false;
                    scope.$apply();
                }

            });
        });


        inputField.focus(event => {
            element.addClass(styles.focus);
        });

        element
            .find("bec-input-addon")
            .each(
            (index, elem) => {
                $(elem)
                    .click(
                        event => {
                            $(elem).siblings("input").focus();
                        });
            });

        const validationElement = $(`
            <div class="${styles.validation}">
                <bec-svg aria-hidden="true" class="${styles.validationSuccess}" raw="true">icon-input-checkmark</bec-svg>
                <bec-svg aria-hidden="true" class="${styles.validationError}" raw="true">icon-input-error</bec-svg>
            </div>
        `);

        element.append(this.$compile(validationElement)(scope));
    };

    private setValidity(ngModel, element, state) {
        if (state) {
            element.addClass(styles.invalid);
            if (ngModel) {
                ngModel.$showInvalid = true;
            }
        } else {
            element.removeClass(styles.invalid);
            if (ngModel) {
                ngModel.$showInvalid = false;
            }
        }
    }
}
