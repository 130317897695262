//  https://gist.github.com/diachedelic/0d60233dab3dcae3215da8a4dfdcd434
export class DeepLinker {
  private authorizeResponse: Server.authorizeresponse = null;

  private hasFocus = true;
  private didHide = false;

  constructor(private options: { onReturn?: (authorizeResponse: Server.authorizeresponse) => void, onFallback?: (authorizeResponse: Server.authorizeresponse) => void, onIgnored?: (authorizeResponse: Server.authorizeresponse) => void }) {
    if (!options) {
      throw new Error('no options')
    }
    // add event listeners
    this.bindEvents('add');
  }

  // add/remove event listeners
  // `mode` can be "add" or "remove"
  bindEvents(mode) {
    [
      [window, 'blur', this.onBlur],
      [document, 'visibilitychange', this.onVisibilityChange],
      [window, 'focus', this.onFocus],
    ].forEach(function (conf) {
      conf[0][mode + 'EventListener'](conf[1], conf[2]);
    });
  }

  destroy = () => {
    this.bindEvents.bind(null, 'remove');
  }

  setAuthorizeResponse = (response: Server.authorizeresponse) => {
    this.authorizeResponse = response;
  }

  openURL = (url) => {
    // it can take a while for the dialog to appear
    var dialogTimeout = 500;
    var ignored = false;

    setTimeout(() => {
      if (this.hasFocus && this.options.onIgnored) {
        ignored = true;
        this.options.onIgnored(this.authorizeResponse);
      }
    }, dialogTimeout);

    try {
      window.top.location.href = url;
    } catch (error) {
      if (this.hasFocus && !ignored && this.options.onFallback) {
        this.options.onFallback(this.authorizeResponse)
      }
    }
  };

  // window is blurred when dialogs are shown
  onBlur = () => {
    this.hasFocus = false;
  };

  // document is hidden when native app is shown or browser is backgrounded
  onVisibilityChange = (e) => {
    if (e.target.visibilityState === 'hidden') {
      this.didHide = true;
    }
  };

  // window is focused when dialogs are hidden, or browser comes into view
  onFocus = () => {
    if (this.didHide) {
      if (this.options.onReturn) {
        this.options.onReturn(this.authorizeResponse);
      }

      this.didHide = false; // reset
    } else {
      // ignore duplicate focus event when returning from native app on
      // iOS Safari 13.3+
      if (!this.hasFocus && this.options.onFallback) {
        // wait for app switch transition to fully complete - only then is
        // 'visibilitychange' fired
        setTimeout(() => {
          // if browser was not hidden, the deep link failed
          if (!this.didHide) {
            this.options.onFallback(this.authorizeResponse);
          }
        }, 1000);
      }
    }

    this.hasFocus = true;
  };
}
