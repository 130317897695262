import * as angular from "angular";
import * as _ from "underscore";
import {
    WindowStateService,
    UrlService,
    ExpressCheckoutService,
    LanguageService,
    ExternalAuthService,
    AppStateService
} from "../index";
import { CheckoutEvent, Close } from "@bambora/checkout-core-events";

export class SessionService {
    static $inject: string[] = [
        "ExpressCheckoutService",
        "$q",
        "$stateParams",
        "$state",
        "ExternalAuthService",
        "LanguageService",
        "UrlService",
        "WindowStateService",
        "$window",
        "$sessionStorage",
        "$timeout",
        "AppStateService",
        "gettextCatalog",

    ];

    // Members
    private _session: Server.checkoutresponse = {} as Server.checkoutresponse;
    private _sessionToken: string;

    constructor(
        private expressCheckoutService: ExpressCheckoutService,
        private $q,
        private $stateParams,
        private $state,
        private externalAuthService: ExternalAuthService,
        private languageService: LanguageService,
        private urlService: UrlService,
        private windowService: WindowStateService,
        private $window: ng.IWindowService,
        private $sessionStorage: any,
        private $timeout: ng.ITimeoutService,
        private appStateService: AppStateService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) {

    }

    get sessionToken() {
        return this._sessionToken;
    }

    get sessionType() {
        return this._session.type;
    }

    get paymentCollections(): Server.paymentcollection[] {
        return this._session.paymentcollection;
    }

    get paymentCardTypes(): Server.paymentgroup[] {
        const groups = _.filter<any>(
            this._session.paymentcollection,
            item => item.name === "paymentcard"
        );

        if (!groups && groups.length < 1) {
            return [];
        }

        return _.first<any>(groups).paymentgroups;
    }

    get googlePayPaymentType(): Server.paymenttype | void {
        const collections = _.filter<Server.paymentcollection>(
            this._session.paymentcollection,
            item => item.name === "wallet"
        );

        if (!collections || collections.length < 1) {
            return null;
        }

        const groups = _.filter<Server.paymentgroup>(
            _.first<Server.paymentcollection>(collections).paymentgroups,
            item => item.name == "googlepay"
        );

        if (!groups || groups.length < 1) {
            return null;
        }

        return _.first<Server.paymenttype>(_.first(groups).paymenttypes);
    }

    get invoiceOptions(): Server.invoiceoptions {
        return this._session.invoiceoptions;
    }


    getSession(token: string): angular.IPromise<Server.checkoutresponse> {
        this._sessionToken = token;

        return this.expressCheckoutService.getExpressCheckout(this._sessionToken)
            .then((data: Server.checkoutresponse): Server.checkoutresponse => {

                this._session = data;
                this.setCleanCustomerIfEmpty();
                if (!this._session.truncatedpaymentcards) {
                    this._session.truncatedpaymentcards = [];
                }

                return this._session;
            });

    }

    getTransactionError(token: string, transactionId: string): angular.IPromise<Server.geterrorresponse> {
        return this.expressCheckoutService.getError(token, transactionId)
            .then((data: Server.geterrorresponse): Server.geterrorresponse => {
                return data
            });
    }

    clearTruncatedCard(): void {
        this._session.truncatedpaymentcards = [];
    }

    get isCobrandedPaymentTypeSelectorEnabled(): boolean {
        try {
            return this._session.merchant.enablepaymenttypeselector;
        } catch (error) {
            return false;
        }
    }

    get isSubscription(): boolean {
        return this._session
            && this._session.subscription
            && this._session.subscription.action
            && (this._session.subscription.action.toLowerCase() === "create" || this._session.subscription.action.toLowerCase() === "update");
    }

    get isCreateSubscription(): boolean {
        return this._session
            && this._session.subscription
            && this._session.subscription.action
            && this._session.subscription.action.toLowerCase() === "create";
    }

    get isUpdateSubscription(): boolean {
        return this._session
            && this._session.subscription
            && this._session.subscription.action
            && this._session.subscription.action.toLowerCase() === "update";
    }

    get isToken(): boolean {
        return this._session
            && this._session.token
            && this._session.token.action
            && (this._session.token.action.toLowerCase() === "verifyandcreate");
    }

    get isVerifyAndCreateToken(): boolean {
        return this._session
            && this._session.token
            && this._session.token.action
            && this._session.token.action.toLowerCase() === "verifyandcreate";
    }

    get truncatedCards(): Server.truncatedpaymentcard[] {
        return (this._session.truncatedpaymentcards);
    }

    get status(): string {
        return this._session.status;
    }

    set status(val) {
        this._session.status = val;
    }

    get authtype(): string {
        return this._session.authtype;
    }

    set authtype(val) {
        this._session.authtype = val;
    }

    get amount(): number {
        return this.order.amount;
    }

    get order(): Server.order {
        return (this._session.order);
    }

    get merchant(): Server.merchant {
        return (this._session.merchant);
    }

    get language(): string {
        return this._session.language;
    }

    get isExpired() {
        return this.status === "expired";
    }

    get isAuthorized() {
        return this.status === "authorized";
    }

    set isAuthorized(status) {
        if (status) {
            this.status = "authorized";
        } else {
            this.status = "open";
        }
    }

    get requiresSmsAuth() {
        return !this.isAuthorized && this.authtype === "sms";
    }

    isTest(): boolean {
        if (!this._session || !this._session.merchant) return false;
        if (this.$stateParams.demo === "1" || this.appStateService.options.demo) return false;

        return this._session.merchant.id.substring(0, 1) === "T";
    }

    isDemo(): boolean {
        if (!this._session || !this._session.merchant) return false;

        return (this.$stateParams.demo === "1" || this.appStateService.options.demo) && this._session.merchant.id.substring(0, 1) === "T";
    }

    hasTruncatedCards(): boolean {
        if (!this._session || !this._session.truncatedpaymentcards) {
            return false;
        }

        return this._session.truncatedpaymentcards.length > 0;
    }

    hasValidCustomer(): boolean {
        return !_.isEmpty(this._session.customer) &&
            !_.isEmpty(this._session.customer.email) &&
            !_.isEmpty(this._session.customer.phonenumber);
    }

    get isPaymentCardAvailable() {
        const paymentcollection = _.filter<any>(this.paymentCollections, (item) => item.name === "paymentcard");
        return (paymentcollection &&
            paymentcollection.length === 1 &&
            _.first<any>(paymentcollection).paymentgroups &&
            _.first<any>(paymentcollection).paymentgroups.length > 0);
    }

    get isInvoiceAvailable() {
        const invoiceCollection = _.first<any>(_.filter<any>(this.paymentCollections, (item) => item.name === "invoice"));
        return invoiceCollection &&
            invoiceCollection.paymentgroups &&
            invoiceCollection.paymentgroups.length > 0 &&
            this.invoiceOptions &&
            this.invoiceOptions.paymentoptions &&
            this.invoiceOptions.paymentoptions.length > 0 &&
            _.any<Server.paymentoption>(this.invoiceOptions.paymentoptions, paymentOption => {
                return paymentOption.type.toLowerCase() === "invoice";
            });
    }

    get isPartPaymentAvailable() {
        const invoiceCollection = _.first<any>(_.filter<any>(this.paymentCollections, (item) => item.name === "invoice"));
        return invoiceCollection &&
            invoiceCollection.paymentgroups &&
            invoiceCollection.paymentgroups.length > 0 &&
            this.invoiceOptions &&
            this.invoiceOptions.paymentoptions &&
            this.invoiceOptions.paymentoptions.length > 0 &&
            _.any<Server.paymentoption>(this.invoiceOptions.paymentoptions, paymentOption => {
                return paymentOption.type.toLowerCase() === "partpayment" ||
                    paymentOption.type.toLowerCase() === "annuityinvoice" ||
                    paymentOption.type.toLowerCase() === "interestfreeinvoice";
            });
    }
    get isEkspresBankAvailable() {
        const invoiceCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "invoice");
        return (invoiceCollection &&
            invoiceCollection.length > 0 &&
            _.first<any>(invoiceCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(invoiceCollection).paymentgroups, (invoice) => invoice.name === "ekspresbank").length > 0);
    }

    get isMobilePayAvailable() {
        const walletsCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "wallet");
        return (walletsCollection &&
            walletsCollection.length > 0 &&
            _.first<any>(walletsCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(walletsCollection).paymentgroups, (wallet) => wallet.name === "mobilepay").length > 0);
    }

    get isVippsAvailable() {
        const walletsCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "wallet");
        return (walletsCollection &&
            walletsCollection.length > 0 &&
            _.first<any>(walletsCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(walletsCollection).paymentgroups, (wallet) => wallet.name === "vipps").length > 0);
    }

    get isApplePayAvailable() {
        const walletsCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "wallet");
        return (walletsCollection &&
            walletsCollection.length > 0 &&
            _.first<any>(walletsCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(walletsCollection).paymentgroups, (wallet) => wallet.name === "applepay").length > 0);
    }

    get isGooglePayAvailable() {        
        const walletsCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "wallet");
        return (walletsCollection &&
            walletsCollection.length > 0 &&
            _.first<any>(walletsCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(walletsCollection).paymentgroups, (wallet) => wallet.name === "googlepay").length > 0);
    }

    get isSwishAvailable() {
        const directDebitCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "directdebit");
        return (directDebitCollection &&
            directDebitCollection.length > 0 &&
            _.first<any>(directDebitCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(directDebitCollection).paymentgroups, (directdebit) => directdebit.name === "swish").length > 0);
    }

    get isViaBillAvailable() {
        const invoiceCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "invoice");
        return (invoiceCollection &&
            invoiceCollection.length > 0 &&
            _.first<any>(invoiceCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(invoiceCollection).paymentgroups, (invoice) => invoice.name === "viabill").length > 0);
    }

    get isNordeaEPaymentFIAvailable() {
        const directDebitCollection = _.filter<Server.paymentcollection>(this.paymentCollections, (item) => item.name === "directdebit");
        return (directDebitCollection &&
            directDebitCollection.length > 0 &&
            _.first<any>(directDebitCollection).paymentgroups.length > 0 &&
            _.filter<any>(_.first<any>(directDebitCollection).paymentgroups, (directdebit) => directdebit.name === "nordeaepaymentfi").length > 0);
    }

    hasValidCustomerWithNoPaymentOptions(): boolean {
        return this.hasValidCustomer() && !this.hasTruncatedCards();
    }

    get immediateRedirectToAccept(): boolean {
        return this._session.accepturl && this._session.immediateredirecttoaccept > 0 && !this.windowService.isInline();
    }

    get immediateRedirectToAcceptValue(): number {
        return this._session.immediateredirecttoaccept;
    }

    private setCleanCustomerIfEmpty() {
        if (this._session.customer == null) {
            this._session.customer = {
                phonenumber: "",
                phonenumberverified: false,
                phonenumbercountrycode: "",
                alreadyremembered: false,
                email: "",
                usertoken: "",
                verificationsmssend: false,
                ssnbirthpart: null,
                lastusedpaymenttype: ""
            };
        }
    }

    authenticateConfirmationCode(code) {
        return this.expressCheckoutService.authenticateConfirmationCode(this._sessionToken, code);
    }

    createCustomer(request) {
        return this.expressCheckoutService.createCustomer(this._sessionToken, request);
    }

    deleteCustomer(deleteHard = false) {
        return this.expressCheckoutService.deleteCustomer(this._sessionToken, deleteHard);
    }

    setSsnAndGetOfficialAddress(ssn) {
        return this.expressCheckoutService.setSsnAndGetOfficialAddress(this._sessionToken, { ssn: ssn });
    }

    private _delayedRedirectToAcceptPromise: ng.IPromise<any> = null;
    private _timeOfDelayedRedirectToAccept: number = null;
    private _delayedRedirectToAcceptWasCancelled: boolean = false;

    initiateDelayedRedirectToAccept(delayInMs: number): ng.IPromise<any> {
        if (this._delayedRedirectToAcceptPromise) {
            this.cancelDelayedRedirectToAccept();
        }

        const promise = this.$timeout(
            () => { this.cancel(); },
            delayInMs
        );

        this._timeOfDelayedRedirectToAccept = new Date().getTime() + delayInMs;
        this._delayedRedirectToAcceptWasCancelled = false;

        this._delayedRedirectToAcceptPromise = promise;

        return promise;
    }

    cancelDelayedRedirectToAccept() {
        if (!this._delayedRedirectToAcceptPromise) return;

        this.$timeout.cancel(this._delayedRedirectToAcceptPromise);
        this._delayedRedirectToAcceptWasCancelled = true;

        this._delayedRedirectToAcceptPromise = null;
        this._timeOfDelayedRedirectToAccept = null;
    }

    delayedRedirectToAcceptInitiated(): boolean {
        return !!this._delayedRedirectToAcceptPromise;
    }

    get timeOfDelayedRedirectToAccept(): number {
        return this._timeOfDelayedRedirectToAccept;
    }

    get secondsTillTimeOfDelayedRedirectToAccept(): number {
        const time = this.timeOfDelayedRedirectToAccept - new Date().getTime();

        if (time < 0) return 0;

        return Math.round(time / 1000);
    }

    get delayedRedirectToAcceptWasCancelled(): boolean {
        return this._delayedRedirectToAcceptWasCancelled;
    }

    cancel(event?) {
        if (event) {
            event.preventDefault();
        }

        this.appStateService.windowState.dispatcher.updateOptions({ acceptUrl: this.urlService.acceptUrl, cancelUrl: this.urlService.declineUrl });

        if (this.isAuthorized) {
            if (!this.windowService.isInIFrame()) {
                this.$timeout(
                    () => { this.appStateService.startFullScreenLoadingWithMessage(this.gettextCatalog.getString("You are being redirected to {{merchantName}}, please wait", { merchantName: this._session.merchant.name })); },
                    1000
                );
            }

            let authorizeParams = this.urlService.getAuthorizeParams(this._sessionToken);

            if (authorizeParams !== null) {
                this.appStateService.windowState.dispatcher.dispatch(CheckoutEvent.Close, {
                    acceptUrl: decodeURIComponent(this.urlService.acceptUrl),
                    data: authorizeParams
                });
            }

            return;
        }

        this.appStateService.windowState.dispatcher.dispatch(CheckoutEvent.Cancel, {
            declineUrl: decodeURIComponent(this.urlService.declineUrl)
        });
    }
}
