import * as angular from "angular";
import { Accept } from "../../shared/components";
import {
    Component,
    CustomerService,
    SessionService,
    AppStateService,
    WindowStateService,
    FeeService,
    UrlService
} from "../../core/index";

import styles from "./payment-card-accept.scss";

@Component({
    selector: "becPaymentCardAccept",
    template: require("./payment-card-accept.html")
})
export class PaymentCardAccept extends Accept {

    static $inject = [
        "$timeout", "$window", "$scope", "CustomerService", "SessionService", "AppStateService", "$interval", "gettextCatalog", "WindowStateService", "FeeService", "UrlService"
    ];

    // Constructor
    constructor(
        $timeout: ng.ITimeoutService,
        $window: ng.IWindowService,
        $scope: ng.IScope,
        public customer: CustomerService,
        public session: SessionService,
        public appState: AppStateService,
        $interval: ng.IIntervalService,
        gettextCatalog: ng.gettext.gettextCatalog,
        windowState: WindowStateService,
        public fee: FeeService,
        urlService: UrlService
    ) {
        super(session, urlService, $interval, gettextCatalog, windowState);

        // todo: Check paymentcard controller, they should have same super type (quickfix for now)
        const keydownHandler = e => {
            const keyCode = e.charCode || e.keyCode || e.which;

            if (keyCode === 13) {

                if (this.onEnterPressed()) {
                    e.preventDefault();
                    $scope.$apply();
                }
            }
        };

        $timeout(() => angular.element($window).on("keydown", keydownHandler), 10);

        $scope.$on("$destroy", () => angular.element(window).off("keydown", keydownHandler));

        // We set authorized here to trigger the app into thinking everything is authorized
        // The Webserver doesn't know wheter the payment is authorized untill it gets a callback.
        session.isAuthorized = true;
    }

    showUserRegistration = false;

    styles = styles;

    showDetails = false;

    $onInit() {
        // Need some better way of handling this possibly.
        if (this.session.immediateRedirectToAccept && !this.customer.hasBeenRememberedForPaymentCard && this.customer.rememberMe && this.customer.hasCustomer) {
            this.customer.createCustomer();
        }

        if (!this.customer.hasBeenRememberedForPaymentCard) {
            /// If not saved yet, then lets skip the animation step
            this.showUserRegistration = true;
            return;
        }

        this.showUserRegistration = true;
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
        const showingDetails = this.showDetails;
        window.setTimeout(function ()
        {
            let buttonId = showingDetails ? "acceptHideDetailsButton" : "acceptShowDetailsButton";
            document.getElementById(buttonId).focus();
        }, 0);

    }

    protected onEnterPressed() {
        if (!this.customer
            .rememberMe ||
            (this.customer.rememberMe && this.customer.hasBeenRememberedForPaymentCard)) {
            this.session.cancel(null);
            return true;
        }
        this.customer.createCustomer();
        return true;
    }
}
