import { Component } from "../../core/index";
import { InvoiceService } from "../shared/index";

import buttons from "../../styles/buttons.scss";

const styles = {
    buttons: buttons
};

@Component({
    selector: "becInvoiceSsnInput",
    template: require("./ssn-input.html")
})
export class SsnInputController {
    static $inject = ["InvoiceService"];

    styles = styles;

    constructor(private invoice: InvoiceService) { }

    get ssn() {
        return this.invoice.ssn;
    }

    continue() {
        //this.invoice.continueFromSsnInput();
        this.invoice.confirmPayment();
    }

    onSsnChange(data: any) {
        this.invoice.ssn = data;
    }
}
