import * as _ from "underscore";

import { Component, CustomerService, ErrorService, SessionService, WindowStateService } from "../../../core/index";

import { AppStateService } from "../../../core";
import { ICustomerDetails } from "../../../core";
import { PhoneNumberService } from "../../../payment-card/shared/index";
import { States } from "../../../app.states";
import { SwishService } from "../index";
import { UAParser } from "ua-parser-js";

import base from "../../../styles/base.scss";
import buttons from "../../../styles/buttons.scss";
import input from "../../../shared/components/input/input.scss";
import swish from "./swish-start.scss";

const styles = {
  base: base,
  buttons: buttons,
  input: input,
  swish: swish
};

@Component({
  selector: "becSwishStart",
  template: require("./swish-start.html")
})
export class SwishStart {
  styles = styles;
  customer: ICustomerDetails;
  testPhoneNumberCountryCode = "SE";
  testPhoneNumber = "71234768";

  static $inject = [
    "SessionService", "SwishService", "ErrorService", "CustomerService", "WindowStateService", "PhoneNumberService", "AppStateService"
  ];

  constructor(
    private sessionService: SessionService,
    private swishService: SwishService,
    private errorService: ErrorService,
    private customerService: CustomerService,
    private windowStateService: WindowStateService,
    private phoneUtil: PhoneNumberService,
    private appStateService: AppStateService
  ) {
    this.customer = customerService.details;
    this.customer.phonenumbercountrycode = this.customer.phonenumbercountrycode || "SE";
  }

  otherDevice: boolean = false;

  $onInit() {
    if (this.sessionService.isDemo()) {
      this.customer.phonenumbercountrycode = this.testPhoneNumberCountryCode;
      this.customer.phonenumber = this.testPhoneNumber;
    }
  }

  continue() {
    this.errorService.hideError();

    let phonenumber = null;

    if (this.requirePhoneNumber || this.otherDevice) {
      phonenumber = this.customer.phonenumber;

      // Handle browser auto-fill - could also be handled by input validation, but this is more convenient to end user
      if (phonenumber.length > 10 && phonenumber.startsWith("46")) {
        phonenumber = phonenumber.substring(2);
      }
      if (phonenumber.startsWith("0046")) {
        phonenumber = phonenumber.substring(4);
      }
      if (phonenumber.startsWith("+46")) {
        phonenumber = phonenumber.substring(3);
      }
      if (phonenumber.startsWith("0")) {
        phonenumber = phonenumber.substring(1);
      }
      if (phonenumber.startsWith("(0)")) {
        phonenumber = phonenumber.substring(3);
      }

      phonenumber = this.phoneUtil.getCallingCodeFromCountryCode(this.customer.phonenumbercountrycode) + phonenumber;
    }

    let fallbackHandled: boolean = false;
    const handleFallback = (authorizeResponse: Server.authorizeresponse) => {
      if (!fallbackHandled) {
        fallbackHandled = true
        this.appStateService.switchState(States.Session.Swish.Fallback.name, { url: authorizeResponse.authorizeresponseurl });
      }
    }

    this.swishService.authorize(phonenumber, {
      onFallback: handleFallback,
      onIgnored: handleFallback,
      onReturn: () => this.appStateService.reload()
    });
  }

  useTestPhoneNumber() {
    this.customer.phonenumbercountrycode = this.testPhoneNumberCountryCode;
    this.customer.phonenumber = this.testPhoneNumber;
  }

  hasError() {
    return this.errorService.errorContext === States.Session.Swish.name;
  }

  getPhoneNumberInputContainerClasses(phoneNumberInvalid, focusPhone) {
    return `${styles.swish.countryCodeInputContainer} ${phoneNumberInvalid ? styles.input.invalid : ""
      } ${focusPhone ? styles.input.inputField : ""
      }`;
  }

  usePhoneNumber() {
    this.otherDevice = true;
  }

  useApp() {
    this.otherDevice = false;
  }

  isAllowedBrowser() {
    const ua = navigator.userAgent;

    const parser = new UAParser(ua);

    const browser = parser.getBrowser();
    const device = parser.getDevice();

    if ((browser.name === "Chrome" || browser.name === "Mobile Safari") && (device.type === "mobile" || device.type === "tablet")) {
      return true;
    }

    return false;
  }

  get requirePhoneNumber() {
    return (!this.appStateService.isAppSwitchSupported("swish") && !this.isAllowedBrowser())
  }
}
