// Directive for selecting payment method with buttons
export class clickElsewhere {

    restrict = "A";
    controller = ClickElsewhereController;
    controllerAs = "controller";
    template = ``;
    scope = {
        
    };
    bindToController = {
        clickElsewhere: "&"
    };
    link = (scope, element: ng.IAugmentedJQuery, attrs) => {
    };
}


// Controller for the two directives above
export class ClickElsewhereController {

    // Dependencies
    static $inject = ["$document", "$scope", "$rootScope", "$attrs", "$parse", "$element"];


    // Constructor
    constructor(private $document,
        private $scope,
        private $rootScope,
        private $attrs,
        private $parse,
        private $element) {

        // Listen for all click anywhere in the document.
        $document.on("click", this.eventHandler);

        if (this.hasTouch()) {
            $document.on("touchstart", this.eventHandler);
        }


        // The method, specified in the "clickElsewhere" attribute, to be invoked when a click has happened outside this directive.
        // Ex: <div click-elsewhere="click()"></div>
        var expressionHandler = this["clickElsewhere"];

        $rootScope.$on("click",
        (event, target) => {

            // Is the click made outside this directive
            var clickIsElseWhere = !($element.find($(target)).length || $element.is($(target)));

            // If the click is made outside invoke the method in the "click-elsewhere" attribute
            if (expressionHandler && clickIsElseWhere) {
                $scope.$apply(() => { expressionHandler(); });
            }

        });
    }


    // Methods
    eventHandler = (e) => {
        this.$rootScope.$emit("click", e.target);
    };
    hasTouch = () => {
        return "ontouchstart" in window || navigator.maxTouchPoints;
    };
}