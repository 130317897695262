export class StateHelperService {
    static $inject = ["$state", "$rootScope"];

    constructor(public $state: ng.ui.IStateService, private $rootScope: ng.IRootScopeService) {
        $rootScope.$on("$stateChangeSuccess", (event, toState, toParams, fromState, fromParams) => {
            this.previousState = {
                state: fromState,
                params: fromParams
            };
        });
    }

    previousState: IStateWithParams;
}


export interface IStateWithParams {
    state: ng.ui.IState;
    params?: {};
}