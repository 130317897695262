export function animateSlideList(): any {

    var animateIn = (element: JQuery, doneFn) => {
        var height = element.outerHeight();
        element.outerHeight(height);
        element.velocity("slideDown",
            height / Math.log(height) + 200,
            () => {
                element.find(".animate-slide-list-item")
                    .velocity("transition.slideRightIn",
                    {
                        stagger: 300,
                        complete: doneFn,
                        duration: height / Math.log(height) + 300
                    });
            });
    };

    var animateOut = (element: JQuery, doneFn) => {
        var height = element.outerHeight();
        element.outerHeight(height);

        element.find(".animate-slide-list-item")
            .velocity("transition.slideRightOut",
            {
                stagger: 300,
                complete: () => {
                    element.velocity("slideUp", height / Math.log(height) + 200, doneFn);
                },
                backwards: true,
                duration: height / Math.log(height) + 300
            });
    };

    return {
        enter: animateIn,
        leave: animateOut,
        addClass: (element: JQuery, className: string, doneFn) => {
            animateOut(element, doneFn);
        },
        removeClass: (element: JQuery, className: string, doneFn) => {
            animateIn(element, doneFn);
        }
    };
}