import * as angular from "angular";

import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as demo from "./index";

export const moduleName = "demo";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

// Components
addComponentToModule(module, demo.InitializationController);
addComponentToModule(module, demo.ShakeView);
addComponentToModule(module, demo.DemoCards);

module
    .directive("becDemoAddress", getFactoryFor(demo.DemoAddress))
    .directive("becDemoVisibility", getFactoryFor(demo.DemoVisibility))
    .directive("becLanguageSelector", demo.LanguageSelector.Factory())

    .directive("becTestMode", getFactoryFor(demo.TestMode))

    // Services
    .service("InitRequestService", demo.InitRequestService)
    .service("MerchantDemoDataService", demo.MerchantDemoDataService)

    // Directives
    .directive("becConvertToNumber", getFactoryFor(demo.ConvertToNumber))

    // Animations
    .animation(".shake-view", ["$animateCss", "$timeout", demo.AnimateShakeView]);
