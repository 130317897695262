import * as angular from "angular";

import dropdown from "./dropdown.scss";
import icons from "../../../styles/icons.scss";

import { default as IconArrow } from "icons/icon-arrow.svg";

const styles = {
    dropdown: dropdown,
    icons: icons
};

export class BecDropdown {
    static $inject = ["DropdownIdGeneratorService"];
    constructor(private dropdownIdGeneratorService: DropdownIdGeneratorService) { }

    restrict = "E";
    controller = BecDropdownController;
    require = ["becDropdown", "ngModel"];
    scope = false;

    compile = (templateElement, templateAttributes) => {
        const template = angular.element(`
            <bec-dropdown-selected>
                <bec-dropdown-selected-value></bec-dropdown-selected-value>
                <bec-dropdown-arrow ng-class="{'${styles.dropdown.open}':showBecDropdownOptions()}">
                    <bec-svg class="${styles.icons.icon}">${IconArrow}</bec-svg>
                </bec-dropdown-arrow>
            </bec-dropdown-selected>
        `);

        const optionTemplate = templateElement.find("bec-dropdown-option").clone();
        const optionListTemplate = angular.element(`
            <bec-dropdown-options role="listbox" class="${styles.dropdown.displayNone}"></bec-dropdown-options>
        `);

        optionListTemplate.append(optionTemplate);
        template.append(optionListTemplate);
        templateElement.empty().append(template);

        return {
            post: (
                scope: ng.IScope,
                element: ng.IAugmentedJQuery,
                attrs: ng.IAttributes,
                ngModel: Array<ng.INgModelController>,
                transclude: ng.ITranscludeFunction
            ) => {
                const id = `bec-dropdown_${this.dropdownIdGeneratorService.getNext()}`;

                element.attr({
                    "tabindex": "0",
                    "role": "listbox",
                    "aria-owns": id
                });

                element.find("bec-dropdown-options").attr("id", id);

                const becDropdownController: BecDropdownController = ngModel[0] as any;
                const ngModelController = ngModel[1];

                becDropdownController.id = id;
                becDropdownController.ngModelController = ngModelController;
                becDropdownController.selectMenuElement = element;
                becDropdownController.becDropDownSelectedValue = element.find("bec-dropdown-selected-value");

                scope.$watch(() => ngModelController.$viewValue, () => becDropdownController.render());

                scope["showBecDropdownOptions"] = () => becDropdownController.showBecDropdownOptions;
                scope["hideBecDropdown"] = () => becDropdownController.showBecDropdownOptions = false;

                element.find("bec-dropdown-selected").click(showOptions);
                element.focus(showOptions);

                angular.element(becDropdownController.dropdownMask).on("click focusin", clickOutsideToClose);
                angular.element(window).resize(onResize);
                angular.element(document).keydown(onKeyDown);

                attrs.$observe<string>("placeholder", value => becDropdownController.placeholder = value);

                scope.$on("$destroy",
                () => {
                    angular.element(becDropdownController.dropdownMask).off("click focusin", clickOutsideToClose);
                    angular.element(document).off("resize", onResize);
                    angular.element(document).off("keydown", onKeyDown);
                    angular.element(`bec-dropdown-mask`).remove();
                    angular.element(`#${id}`).remove();
                });

                function clickOutsideToClose(event: JQueryEventObject) {
                    const target = angular.element(event.target);

                    if (!($.contains(element[0], target[0]) ||
                        element.is(angular.element(target)) ||
                        $.contains(becDropdownController.optionsList[0], target[0]) ||
                        becDropdownController.optionsList.is(target))) {

                        hideOptions();
                    }
                }

                function onResize(event: JQueryEventObject) {
                    if (becDropdownController.showBecDropdownOptions) {
                        becDropdownController.positionDropdownOptions();
                    }
                };

                function showOptions(event?: JQueryEventObject) {
                    scope.$apply(() => {
                        becDropdownController.showBecDropdownOptions = true;
                        element.attr("aria-expanded", "true");
                    });
                }

                function hideOptions(event?: JQueryEventObject) {
                    scope.$apply(() => {
                        becDropdownController.showBecDropdownOptions = false;
                        element.attr("aria-expanded", "false");
                    });
                }

                function onKeyDown(event: JQueryEventObject) {
                    if (event.target !== element[0]) return;

                    if (becDropdownController.showBecDropdownOptions) {
                        switch (event.which) {
                            // Up arrow
                            case 38: {
                                event.preventDefault();
                                becDropdownController.focusPreviousOption();
                                break;
                            }

                            // Down arrow
                            case 40: {
                                event.preventDefault();
                                becDropdownController.focusNextOption();
                                break;
                            }

                            // Escape
                            case 27: {
                                event.preventDefault();
                                scope.$apply(() => {
                                    if (becDropdownController.showBecDropdownOptions) {
                                        becDropdownController.showBecDropdownOptions = false;
                                    }
                                });
                                break;
                            }

                            // Enter
                            case 13: {
                                event.preventDefault();
                                becDropdownController.selectFocused();
                                break;
                            }
                        }
                    } else {
                        switch (event.which) {
                            // Up arrow
                            case 38: {
                                event.preventDefault();
                                scope.$apply(() => {
                                    if (!becDropdownController.showBecDropdownOptions) {
                                        becDropdownController.showBecDropdownOptions = true;
                                    }
                                });
                                becDropdownController.focusPreviousOption();
                                break;
                            }

                            // Down arrow
                            case 40: {
                                event.preventDefault();
                                scope.$apply(() => {
                                    if (!becDropdownController.showBecDropdownOptions) {
                                        becDropdownController.showBecDropdownOptions = true;
                                    }
                                });
                                becDropdownController.focusNextOption();
                                break;
                            }

                            // Enter, spacebar
                            case 13: case 32: {
                                event.preventDefault();
                                scope.$apply(() => {
                                    if (!becDropdownController.showBecDropdownOptions) {
                                        becDropdownController.showBecDropdownOptions = true;
                                    }
                                });
                                break;
                            }
                        }
                    }
                }
            }
        };
    };
}

export interface IDropdownOption {
    select: Function;
    render: Function;
}

export class BecDropdownController {
    id: string;
    ngModelController;
    selectMenuElement: JQuery;
    becDropDownSelectedValue: JQuery;

    private _selectedElementHtml: string;
    private _selectedOptionElement: JQuery;
    private _showBecDropdownOptions: boolean;
    private _showBecDropdownOptionsLastChange: number;
    private _placeholder: string;
    private _focusedOptionElement: JQuery;

    static $inject = ["$animate"];
    constructor(private $animate: ng.animate.IAnimateService) {}

    get placeholder() {
        return this._placeholder || "";
    }

    set placeholder(value) {
        this._placeholder = value;
        this.render();
    }

    dropdownMask: JQuery = angular.element(`
        <bec-dropdown-mask></bec-dropdown-mask>
    `);

    set selectedElementHtml(element: JQuery) {
        this._selectedOptionElement = element;
        const clone = element.clone();
        clone.find(".ripple-container").remove();
        this._selectedElementHtml = clone.html();
        this.becDropDownSelectedValue.html(this._selectedElementHtml);
    }

    get showBecDropdownOptions() {
        return this._showBecDropdownOptions;
    }

    set showBecDropdownOptions(value: boolean) {
        // Fix for sometimes wrong event.target in Chrome
        if (this._showBecDropdownOptionsLastChange &&
            Date.now() - this._showBecDropdownOptionsLastChange < 150) {
            return;
        }

        this._showBecDropdownOptionsLastChange = Date.now();

        if (value) {
            this.positionDropdownOptions();

            if (this._selectedOptionElement)
                this.focusedOptionElement = this._selectedOptionElement;
            else
                this.focusedOptionElement = null;

            angular.element("body").append(this.dropdownMask);
            angular.element("body").append(this.optionsList);
            this.$animate.addClass(this.optionsList, "animate-height");
        } else {
            this.$animate.removeClass(this.optionsList, "animate-height");
            this.dropdownMask.detach();
        }

        this._showBecDropdownOptions = value;
    }

    get optionsList() {
        return angular.element(`#${this.id}`);
    }

    get focusedOptionElement() {
        return this._focusedOptionElement;
    }

    set focusedOptionElement(element: JQuery) {
        if (this._focusedOptionElement)
            this._focusedOptionElement.removeClass(styles.dropdown.focus);

        if (!element) {
            this._focusedOptionElement = null;
        } else {
            this._focusedOptionElement = element;
            this._focusedOptionElement.addClass(styles.dropdown.focus);
            this.selectMenuElement.attr("aria-activedescendant", this._focusedOptionElement.attr("id"));
        }
    }

    focusPreviousOption() {
        const focusedOption = this.focusedOptionElement;

        let previousOption = focusedOption ? focusedOption.prev() : this.optionsList.find("bec-dropdown-option").first();

        if (!previousOption.length)
            previousOption = focusedOption.siblings().addBack().last();

        this.focusedOptionElement = previousOption;
    }

    focusNextOption() {
        const focusedOption = this.focusedOptionElement;

        let nextOption = focusedOption ? focusedOption.next() : this.optionsList.find("bec-dropdown-option").first();

        if (!nextOption.length)
            nextOption = focusedOption.siblings().addBack().first();

        this.focusedOptionElement = nextOption;
    }

    selectFocused() {
        if (this.focusedOptionElement) this.focusedOptionElement.click();
    }

    positionDropdownOptions() {
        const selectMenuBoundingClientRect = this.selectMenuElement[0].getBoundingClientRect();
        const scrollPos = {
            top: angular.element(window).scrollTop(),
            left: angular.element(window).scrollLeft()
        };
        const windowDim = {
            height: angular.element(window).height(),
            width: angular.element(window).width(),
        }
        const elementHeight = this.optionsList.outerHeight();

        let top = selectMenuBoundingClientRect.bottom + scrollPos.top + 8;
        if(top + elementHeight > windowDim.height) {
            top = Math.max(8, (windowDim.height - (elementHeight + 8)));
        }

        this.optionsList.css({
            position: "absolute",
            top: top,
            left: selectMenuBoundingClientRect.left + scrollPos.left,
            width: selectMenuBoundingClientRect.width
        });
    }

    clear() {
        this._selectedElementHtml = this.placeholder;
        this.becDropDownSelectedValue.html(this._selectedElementHtml);
    }

    private _dropdownOptions: Array<IDropdownOption> = [];

    addDropdownOption(dropdownOption: IDropdownOption) {
        this._dropdownOptions.push(dropdownOption);
    }

    removeDropdownOption(dropdownOption: IDropdownOption) {
        const index = this._dropdownOptions.indexOf(dropdownOption);
        this._dropdownOptions.splice(index, 1);
    }

    render() {
        let result = false;

        this._dropdownOptions.forEach(dropdownOption => {
            result = dropdownOption.render() || result;
        });

        if (!result) {
            this.clear();
            this.becDropDownSelectedValue.addClass(styles.dropdown.placeholder);
        } else {
            this.becDropDownSelectedValue.removeClass(styles.dropdown.placeholder);
        }
    }
}


export class BecDropdownOption {
    restrict = "E";
    require = ["^^becDropdown"];

    link(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes,
        ngModel: Array<ng.INgModelController>,
        transclude: ng.ITranscludeFunction
    ) {
        const becDropdownController: BecDropdownController = ngModel[0] as any;

        element.click(select);

        element.attr({
            "role": "option",
            id: Math.random().toString(36).substring(2)
        });

        function select() {
            becDropdownController.selectedElementHtml = element;
            becDropdownController.becDropDownSelectedValue.removeClass(styles.dropdown.placeholder);
            becDropdownController.showBecDropdownOptions = false;
            becDropdownController.ngModelController.$setViewValue(attrs["value"]);
            becDropdownController.ngModelController.$commitViewValue();
        }

        function render() {
            element.removeAttr("aria-checked");

            if (becDropdownController.ngModelController &&
                becDropdownController.ngModelController.$viewValue !== undefined &&
                becDropdownController.ngModelController.$viewValue == attrs["value"]) { // needs to be ==, not ===
                select();
                return true;
            }
            return false;
        }

        attrs.$observe("value", render);
        becDropdownController.addDropdownOption({ select, render });
    };
}


export class DropdownIdGeneratorService {
    private currentId = 0;

    getNext() {
        return this.currentId++;
    }
}
