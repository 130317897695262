export class ConvertToNumber {
    require = "ngModel";
    link = (scope, element, attrs, ngModel: any) => {
        ngModel.$parsers.push((val) => {

            return parseInt(val, 10);
        });
        ngModel.$formatters.push((val) => {
            return `${val}`;
        });
    };
}