import { DeltaFoxService } from "../core/index";

import test from "./test.scss";
import checkmark from "../shared/components/checkmark/checkmark.scss";

const styles = {
    test: test,
    checkmark: checkmark
};

export const TestController = ["$scope", "InvoiceService", "DeltaFoxService",
    ($scope, invoice, deltaFox: DeltaFoxService) => {
        let id = "66666666666666666666666666666666666666666666666666666666";
        $scope.newID = () => {
            console.log("test" + id);
            id += "s";
            deltaFox.setDeltaFoxId(id);
        };

        $scope.onEmailUpdated = (email) => {
            console.log(email);
        };
        $scope.email = "email";
        $scope.address = {
            firstname: "dsdd", lastname: "lastname"
        };

        $scope.onAnswer = (id, answer) => {
            invoice.partpaymentAnswers[id] = answer;
        };

        $scope.evaluation = {
            "criteria": [
                {
                    "comparisonoperator": "GreaterThanOrEqual",
                    "firstoperand": "SwedishQuestion1",
                    "secondoperand": "SwedishQuestion5"
                }
            ],
            "partpaymentquestions": [
                {
                    "id": "SwedishQuestion1",
                    "options": [],
                    "placeholdertext": "Amount in EUR",
                    "preferredwidth": 12,
                    "range": {
                        "increment": 1000,
                        "max": 100000,
                        "min": 9000
                    },
                    "text": "Månadsinkomst innan skatt",
                    "tooltip": null,
                    "unit": "SEK"
                },
                {
                    "id": "SwedishQuestion2",
                    "options": [
                        {
                            "id": 1,
                            "text": "Bostadsrätt"
                        },
                        {
                            "id": 2,
                            "text": "Villa eller radhus"
                        },
                        {
                            "id": 3,
                            "text": "Hyresrätt eller annat"
                        }
                    ],
                    "placeholdertext": null,
                    "preferredwidth": 12,
                    "range": null,
                    "text": "Boendeform",
                    "tooltip": null,
                    "unit": null
                },
                {
                    "id": "SwedishQuestion3",
                    "options": [
                        {
                            "id": 0,
                            "text": "0"
                        },
                        {
                            "id": 1,
                            "text": "1"
                        },
                        {
                            "id": 2,
                            "text": "2"
                        },
                        {
                            "id": 3,
                            "text": "3"
                        },
                        {
                            "id": 4,
                            "text": "4"
                        },
                        {
                            "id": 5,
                            "text": "5"
                        },
                        {
                            "id": 6,
                            "text": "6+"
                        }
                    ],
                    "placeholdertext": null,
                    "preferredwidth": 6,
                    "range": null,
                    "text": "Antal barn under 18 år",
                    "tooltip": null,
                    "unit": null
                },
                {
                    "id": "SwedishQuestion4",
                    "options": [
                        {
                            "id": 1,
                            "text": "1"
                        },
                        {
                            "id": 2,
                            "text": "2"
                        },
                        {
                            "id": 3,
                            "text": "3"
                        },
                        {
                            "id": 4,
                            "text": "4"
                        },
                        {
                            "id": 5,
                            "text": "5"
                        },
                        {
                            "id": 6,
                            "text": "6+"
                        }
                    ],
                    "placeholdertext": null,
                    "preferredwidth": 6,
                    "range": null,
                    "text": "Antal vuxna",
                    "tooltip": null,
                    "unit": null
                },
                {
                    "id": "SwedishQuestion5",
                    "options": [],
                    "placeholdertext": "Amount in EUR",
                    "preferredwidth": 12,
                    "range": {
                        "increment": 1000,
                        "max": 100000,
                        "min": 0
                    },
                    "text": "Sammanlagda månadskostnader",
                    "tooltip":
                        "Saman masajadkajsdlkaj jald jadjalækdj alædkjasædlkjaældk jaældkjaældkjaælkds jaælskdj aælksjd aælkdj a",
                    "unit": "SEK"
                },
                {
                    "id": "SwedishQuestion6",
                    "options": [],
                    "placeholdertext": "Amount in EUR",
                    "preferredwidth": 12,
                    "range": {
                        "increment": 10000,
                        "max": 10000000,
                        "min": 0
                    },
                    "text": "Summan av andra lån och krediter",
                    "tooltip": null,
                    "unit": "SEK"
                }
            ],
            "version": "SE1.0"
        };
    }
];
