import {
    getFactoryFor,
    Country
} from "../../core/index";


interface SsnParseConfig {
    formatter: (value: string) => string;
    parser: (value: string) => string;
    formatValidator: (modelValue: string, viewValue: string) => boolean;
    ageValidator: (modelValue: string, viewValue: string) => boolean;
}

interface SsnParseConfigMap {
    [country: string]: SsnParseConfig
}

export class SsnParser {
    restrict = "A";
    require = "ngModel";
    static EmptyIsValid = true;

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel: ng.INgModelController) => {
        var watch = scope.$watch(() => attrs["becSsnParser"],
        () => {
            var config = this.getParserConfig(attrs["becSsnParser"]);
            if (!config) {
                // TODO: No valid country code specified, no SSN validation enabled
            } else {
                ngModel.$formatters.push(config.formatter);
                ngModel.$parsers.push(config.parser);
                ngModel.$validators["format"] = config.formatValidator;
                ngModel.$validators["age"] = config.ageValidator;
            }
            watch();
        });

    };

    private getParserConfig(countryCode: string): SsnParseConfig {
        return this.parsers[countryCode];
    }

    private parsers: SsnParseConfigMap = {};

    public constructor() {
        this.parsers[Country.Sweden] = new SwedishSsnParserConfig();
        this.parsers[Country.Norway] = new NorwegianSsnParserConfig();
        this.parsers[Country.Finland] = new FinnishSSNParserConfig();
        // Add new parsers here
    }


}

class NorwegianSsnParserConfig implements SsnParseConfig {
    formatter = (value) => {
        return value;
    };
    parser = (value) => {
        if (!this.formatValidator(value, value)) return "";
        return value;

    };
    formatValidator = (modelValue, viewValue) => {
        var value = modelValue || viewValue;
        if(!value) return SsnParser.EmptyIsValid;

        if(!(/^\d{11}$/.test(value))) return false;

        if(!this.isValidChecksums(value)) return false;

        if(!this.isDateValid(value)) return false;

        return true;
    };
    ageValidator = (modelValue: string, viewValue: string) => {
        if (!modelValue) return true;

        if(!this.isDateValid(modelValue)) return false;

        let day = parseInt(modelValue.substr(0,2));
        
        if(day > 40 && day < 72) {
            day = day - 40;
        }
        
        let year = parseInt(modelValue.substr(4,2)),
        individualNumber = parseInt(modelValue.substr(6,3));
        let birthYear = this.toLongYear(year, individualNumber);

        const birthDate = new Date(birthYear, parseInt(modelValue.substr(2, 2)) - 1, day);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();
        if (currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= 18;
    }; 
    
    private toLongYear(shortYear: number, individualNumber: number): number {
        if(individualNumber >= 0 && individualNumber <= 499) {
            return shortYear += 1900;
        } else if (individualNumber >= 500 && individualNumber <= 749 && shortYear >= 55) {
            return shortYear += 1800;
        } else if(individualNumber >= 500 && individualNumber <= 999 && shortYear <= 39) {
            return shortYear += 2000;
        } else if (individualNumber >= 900 && individualNumber <= 999 && shortYear >= 40) {
            return shortYear += 1900;
        }
    }

    private isValidChecksums(ssn: string) : boolean {
        if(!(/^\d{11}$/.test(ssn))) return false;

        let number = ssn.split("").map(function (ssn) {
            return parseInt(ssn);
        })

        let firstChecksum = 11 - ((3 * number[0] + 7 * number[1] + 6 * number[2] + 1 * number[3] + 8 * number[4] + 9 * number[5] + 4 * number[6] + 5 * number[7] + 2 * number[8]) % 11);

        if(firstChecksum === 10) return false;

        if(firstChecksum === 11) {
            firstChecksum = 0;
        }

        if(firstChecksum !== number[9]) return false;

        let secondChecksum = 11 - ((5 * number[0] + 4 * number[1] + 3 * number[2] + 2 * number[3] + 7 * number[4] + 6 * number[5] + 5 * number[6] + 4 * number[7] + 3 * number[8] + 2 * number[9]) % 11);

        if(secondChecksum === 10) return false;
        
        if(secondChecksum === 11) {
            secondChecksum = 0;
        }

        if(secondChecksum !== number[10]) return false;

        return true;
    }

    private isDateValid(ssn: string): boolean {
        let day = parseInt(ssn.substr(0, 2)),
        month = parseInt(ssn.substr(2, 2)),
        monthLengths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if(day > 40 && day < 72) {
            day = day - 40;
        }

        if (day < 1 || month < 1 || month > 12) return false;

        if (day > monthLengths[month - 1]) return false;

        return true;
    }
}

class SwedishSsnParserConfig implements SsnParseConfig {
    formatter = (value) => {
        if (!value) return value;
        if (this.isShortFormat(value)) {
            return this.toLongYear(value);
        }

        return value;
    };
    parser = (value) => {
        if (!this.formatValidator(value, value)) return "";
        if (this.isShortFormat(value)) {
            return this.toLongYear(value);
        }
        return this.removeOptionalChars(value);
    };
    formatValidator = (modelValue, viewValue) => {
        var value = modelValue || viewValue;
        return this.isShortFormat(value) || this.isLongFormat(value);
    };
    ageValidator = (modelValue: string, viewValue: string) => {
        if (!modelValue) return true;

        const birthDate = new Date(parseInt(modelValue.substr(0, 4)),
            parseInt(modelValue.substr(4, 2)) - 1,
            parseInt(modelValue.substr(6, 2)));
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();
        if (currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= 18;
    };

    private isShortFormat(value) {
        if (!value) return SsnParser.EmptyIsValid;
        return /^\d{6}[\-+]\d{4}$/.test(value);
    }

    private isLongFormat(value) {
        if (!value) return SsnParser.EmptyIsValid;
        return (/^\d{8}-?\d{4}$/.test(value));
    }

    private removeOptionalChars(value: string) {
        return value.replace("-", "").replace("+", "");
    }

    private toLongYear(value): string {
        const shortYear = value.substr(0, 2);
        const currentYearThreshold = 0; // if = 10 and currentYear = 2016 then it works for people aged 10 to 110; 
        const currentShortYear = (new Date().getFullYear() - currentYearThreshold).toString(10).substr(2, 2);
        let currentCentury = (new Date().getFullYear() - currentYearThreshold)
            .toString(10)
            .substr(0, 2); // As in astronomical century
        let lastCentury = (new Date().getFullYear() - (100 + currentYearThreshold))
            .toString(10)
            .substr(0, 2); // As in astronomical century

        const newVal = this.removeOptionalChars(value);
        if (/\+/.test(value)) {
            currentCentury = (parseInt(currentCentury) - 1).toString();
            lastCentury = (parseInt(lastCentury) - 1).toString();
        }

        if (shortYear <= currentShortYear) {
            return currentCentury + newVal;
        } else {
            return lastCentury + newVal;
        }
    }
}

class FinnishSSNParserConfig implements SsnParseConfig {
    formatter = (value) => {
        return value;
    };
    parser = (value) => {
        if (!this.formatValidator(value, value)) return "";
        return value;

    };
    formatValidator = (modelValue, viewValue) => {
        var value = modelValue || viewValue;
        if(!value) {
            return SsnParser.EmptyIsValid;
        }
        const SSN_REGEX = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[012]\dA)\d{3}[\dA-Z]$/;
        
        if(!SSN_REGEX.test(value)) {
            return false;
        }
         
        if(!this.isValidChecksums(value)) {
            return false;
        }

        if(!this.isDateValid(value)) {
            return false;
        } 

        return true;
    };
    ageValidator = (modelValue: string, viewValue: string) => {
        const ssn = modelValue;
        if (!ssn) return true;

        if(!this.isDateValid(modelValue)) return false;

        const centuryId = ssn.charAt(6);
        const year = parseInt(ssn.substring(4, 6), 10) + FinnishSSNParserConfig.centuryMap.get(centuryId)!;
        const month = parseInt(ssn.substring(2, 4), 10);
        const dayOfMonth = parseInt(ssn.substring(0, 2), 10);
        const dateOfBirth = new Date(year, month, dayOfMonth);
        const today = new Date();
        const birthDayPassed = dateOfBirth.getMonth() < today.getMonth() || (dateOfBirth.getMonth() === today.getMonth() && dateOfBirth.getDate() <= today.getDate())
        const ageInYears = today.getFullYear() - dateOfBirth.getFullYear() - (birthDayPassed ? 0 : 1)

        return ageInYears >= 18;

    }; 
    
    private static daysInMonthMap: Map<string, number> = new Map<string, number>([
        ['01', 31],
        ['02', 28],
        ['03', 31],
        ['04', 30],
        ['05', 31],
        ['06', 30],
        ['07', 31],
        ['08', 31],
        ['09', 30],
        ['10', 31],
        ['11', 30],
        ['12', 31]
    ]);

    private static centuryMap: Map<string, number> = new Map<string,number>([
        ['A', 2000],
        ['-', 1900],
        ['+', 1800]
    ]);

    private isValidChecksums(ssn: string) : boolean {
        const checksumTable: string[] = '0123456789ABCDEFHJKLMNPRSTUVWXY'.split('');
        
        const rollingId = ssn.substring(7, 10);
        const checksumBase = parseInt(ssn.substring(0, 6) + rollingId, 10);
        const checksum = ssn.substring(10, 11);

        return checksum === checksumTable[checksumBase % 31];
    }

    private isDateValid(ssn: string): boolean {
        const dayOfMonth = parseInt(ssn.substring(0, 2), 10);
        const month = ssn.substring(2, 4);
        const centuryId = ssn.charAt(6);

        // tslint:disable-next-line:no-non-null-assertion
        const year = parseInt(ssn.substring(4, 6), 10) + FinnishSSNParserConfig.centuryMap.get(centuryId)!;
        const daysInMonth = FinnishSSNParserConfig.daysInGivenMonth(year, month);
        
        if (!FinnishSSNParserConfig.daysInMonthMap.get(month) || dayOfMonth > daysInMonth) {
            return false;
        }

        return true;
    }

    private static daysInGivenMonth(year: number, month: string) {
        const february = '02';
        // tslint:disable-next-line:no-non-null-assertion
        const daysInMonth = FinnishSSNParserConfig.daysInMonthMap.get(month)!;
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        return month === february && isLeapYear ? daysInMonth + 1 : daysInMonth;
    } 
}

export class SsnValidatorController {


}
