import * as _ from "underscore";

const styles = {
    input: require("../components/input/input.scss")
};


export class SingleCharInput {
    restrict = "A";
    require = "ngModel";
    private static keyCode_backspace = 8;
    private static keyCode_left = 37;
    private static keyCode_right = 39;
    private static keyCode_enter = 13;

    static $inject = ["$timeout"];

    public constructor(private $timeout) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel: ng.INgModelController) => {
        this.initializeInputField(element);

        element.keydown((event: JQueryEventObject) => {
            var keyCode = event.charCode || event.keyCode || event.which;

            // Hacks to support phones that return 0 or 229 (looking at you android chrome)
            if (keyCode === 0 || keyCode === 229) {
                this.$timeout(() => {
                        this.moveFocusToNextInput(element);
                    },
                    0);
                return;
            }

            var digit = String.fromCharCode(keyCode);
            if (/^\d+$/.test(digit)) {
                event.preventDefault(); // A digit
                this.$timeout(() => {

                    this.setViewValue(ngModel, digit);
                    this.moveFocusToNextInput(element);

                });
                return;
            }

            if (keyCode === SingleCharInput.keyCode_backspace ||
                keyCode === SingleCharInput.keyCode_left ||
                keyCode === SingleCharInput.keyCode_right) {
                event.preventDefault();
                this.$timeout(() => {
                    if (keyCode === SingleCharInput.keyCode_backspace) {
                        this.moveFocusToPreviousInput($(event.target));
                        this.setViewValue(ngModel, "");
                    }
                    if (keyCode === SingleCharInput.keyCode_left) {
                        this.moveFocusToPreviousInput($(event.target));
                    }
                    if (keyCode === SingleCharInput.keyCode_right) {
                        this.moveFocusToNextInput($(event.target));
                    }
                });
            }
        });


        element.keypress((e: JQueryKeyEventObject) => {
            var keyCode = e.charCode || e.keyCode || e.which;
            if (keyCode === SingleCharInput.keyCode_enter) {
                return;
            }

            var digit = String.fromCharCode(keyCode);
            if (!/^\d+$/.test(digit)) {
                e.preventDefault(); // Not a digit
                return;
            }

            this.$timeout(() => {

                this.setViewValue(ngModel, digit);
                this.moveFocusToNextInput(element);

            });
        });

    };

    private setViewValue(ngModel, value) {
        ngModel.$setViewValue(value);
        ngModel.$render();
    }


    private initializeInputField(element) {
        element.focusin(() => { element.get(0).setSelectionRange(0, 1); });
        element.mouseup(() => { element.get(0).setSelectionRange(0, 1); });
    }


    private getAllInputFields(element) {
        return element.closest(`.${styles.input.digits}`).find("input");
    }

    private getIndexOfElement(element: any): any {
        const allInputFields = this.getAllInputFields(element);
        const index = _.findIndex(allInputFields, item => (item === element.get(0)));
        return index;
    }

    private moveFocusToNextInput(currentElement) {
        const nextInput = this.getNextSingleCharInput(currentElement);
        if (nextInput) {
            nextInput.focus();
        }
    }

    private moveFocusToPreviousInput(currentElement): JQuery {
        const previousInput = this.getPreviousSingleCharInput(currentElement);
        if (previousInput) {
            previousInput.focus();
        }
        return previousInput;
    }

    private getNextSingleCharInput(currentElement: any): any {
        const index = this.getIndexOfElement(currentElement);
        const allInputFields = this.getAllInputFields(currentElement);

        if (index < allInputFields.length - 1) {
            return $(allInputFields[index + 1]);
        } else {
            return null;
        }
    }

    private getPreviousSingleCharInput(currentElement: any): JQuery {
        const index = this.getIndexOfElement(currentElement);
        const allInputFields = this.getAllInputFields(currentElement);

        if (index > 0) {
            return $(allInputFields[index - 1]);
        } else {
            return null;
        }
    }
}