import Shake from "shake.js";

export class ShowOnShake {
    static $inject = [];

    public constructor() {
        const myShakeEvent = new Shake({
            threshold: 15 // optional shake strength threshold
            //  timeout: 1000 // optional, determines the frequency of event generation
        });

        myShakeEvent.start();
    }

    link = (scope, element, attrs) => {

        var show = false;
        scope.showOnShake = false;

        window.addEventListener("shake",
            () => {
                scope.showOnShake = !scope.showOnShake;
                scope.$apply();
            },
            false);
    };
}
