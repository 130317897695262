export class CreditCardType {
    private static postFixOldIcons = "";
    private static postFixNewIcons = "";
    private static currentIconsPostFix = CreditCardType.postFixNewIcons;

    static $inject = [
        "$animate",
        "$timeout"
    ];

    public constructor(
        private $animate: ng.animate.IAnimateService,
        private $timeout: ng.ITimeoutService
    ) {
    }

    scope = {
        cardnumber: "=cardnumber",
        iconBorder: "=?iconBorder"
    };

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {

        scope.$watch("cardnumber",
        (newValue, oldValue) => {
            var oldClass = this.getClass(oldValue, attrs.defaultIcon, attrs.iconBorder);
            var newClass = this.getClass(newValue, attrs.defaultIcon, attrs.iconBorder);

            if (newClass === oldClass) {
                if (!scope.animating) {
                    this.$animate.removeClass(element, oldClass);
                    this.$animate.addClass(element, newClass);

                    if (newClass)
                        attrs.$set("becSvgSrc",
                            `${this
                            .getIconName(newValue, attrs.defaultIcon, attrs.iconBorder)}${
                            CreditCardType.currentIconsPostFix}`);
                    else
                        attrs.$set("becSvgSrc", null);
                }
            } else {
                scope.animating = true;
                this.$animate.addClass(element, "bounceOut")
                    .then(() => {
                        this.$animate.removeClass(element, oldClass);
                        this.$animate.addClass(element, newClass);

                        if (newClass)
                            attrs.$set("becSvgSrc",
                                `${this
                                .getIconName(newValue, attrs.defaultIcon, attrs.iconBorder)}${
                                CreditCardType.currentIconsPostFix}`);
                        else
                            attrs.$set("becSvgSrc", null);

                        this.$timeout(() => {
                            this.$animate.removeClass(element, "bounceOut");
                            this.$animate.addClass(element, "bounceIn")
                                .then(() => {
                                    this.$animate.removeClass(element, "bounceIn");
                                    scope.animating = false;
                                });
                        });
                    });
            }
        });

    };

    private getPaymentCardType(ccnum: string): string {
        if (!ccnum) return "unknown";
        return jQuery.payment.cardType(ccnum) || "unknown";
    }

    private getClass(value: string, defaultClass: string = null, iconBorder: boolean = false): string {
        const cardType = this.getPaymentCardType(value);
        if (cardType === "unknown") {
            return defaultClass;
        } else {
            return `icon-card-${cardType}${iconBorder ? "-border" : ""}`;
        }
    }

    private getIconName(value: string, defaultClass: string = null, iconBorder: boolean = false): string {
        const cardType = this.getPaymentCardType(value);
        if (cardType === "unknown") {
            return defaultClass;
        } else {
            return `paymentcard-${cardType}${iconBorder ? "-border" : ""}`;
        }
    }
}