import { AppStateService } from "../../../core/index";
import * as $ from 'jquery'

export class GirafAnimation {
    restrict = "AE";
    template = require("./giraf-animation.html");
    replace = true;

    static $inject = ["AppStateService"];

    constructor(private appStateService: AppStateService) {

    }

    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {

        scope.appState = this.appStateService;

        scope.delay = 90;

        run();

        function run() {

            const giraffy = document.getElementById("giraffy");
            const paths = giraffy
                .getElementsByTagName("path");
                // Todo This collection should be available from the $element variable
            animate(paths);
        }

        function animate(paths) {

            if (paths === undefined) {
                return;
            }

            (function loop(e, i) {

                setTimeout(() => {

                        color(e[i], "purple");

                        if (--i >= 0) {
                            loop(paths, i);
                        } else {
                            loop(paths, paths.length);
                        }

                    },
                    scope.delay);
            })(paths, paths.length - 1);
        }

        function color(element, className: string ) {
            if (element === undefined) {
                return;
            } 

            //element.toggleClass(className);
        }
    };
}