import { default as IconEsc } from 'icons/icon-esc.svg';

export class EscapeButton {
    static $inject = ["WindowStateService", "AppStateService"];
    constructor(private windowState, private session) { }

    restrict = "E";

    template = `
        <div class="escape-button"
             ng-show="windowState.isFullScreen() || windowState.isOverlay()"
             ng-mouseup="session.cancel($event)"
             ng-class="{'layout-overlay': windowState.isOverlay()}"
             bec-ripple>
            <bec-svg class= "icon icon-icon-esc">${IconEsc}</bec-svg>
        </div>
    `;

    link = (scope, element, attrs) => {
        scope.windowState = this.windowState;
        scope.session = this.session;
    };
}
