import * as angular from "angular";
import * as _ from "underscore";


export interface IExtendedComponentOptions extends ng.IComponentOptions {
    selector: string;
}

export interface IReflector {
    addComponentOptions(controller: Function, options: ng.IComponentOptions);
    getComponentOptions(controller: Function): IExtendedComponentOptions;
}

export interface IReflectorMap {
    component: Function;
    options: IExtendedComponentOptions;
}

class Reflector implements IReflector {
    private _options: Array<IExtendedComponentOptions> = [];

    addComponentOptions(component: Function, options: IExtendedComponentOptions) {
        const opts = angular.extend(
            {},
            options,
            { controller: component }
        ) as IExtendedComponentOptions;

        this._options.push(opts);
    }

    getComponentOptions(component: Function): IExtendedComponentOptions {
        const options = _.find<IExtendedComponentOptions>(
            this._options as _.List<IExtendedComponentOptions>,
            o => o.controller === component
        );

        return options;
    }

}

export const reflector = new Reflector();

//export function provide(component: Function): Array<string | IExtendedComponentOptions> {
//    reflector.getComponentOptions(component);
//    return reflector.getComponentOptions(component);
//}

export function addComponentToModule(module: ng.IModule, component: Function) {
    const options = angular.copy(reflector.getComponentOptions(component));
    const selector = options.selector;

    delete options.selector;

    module.component(selector, options);
}
