import * as angular from "angular";

import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as acquirers from "./index";

export const moduleName = "acquirers";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

// Components
addComponentToModule(module, acquirers.EkspresBankStart);
addComponentToModule(module, acquirers.EkspresBankAccept);
addComponentToModule(module, acquirers.SwishStart);
addComponentToModule(module, acquirers.SwishAccept);
addComponentToModule(module, acquirers.SwishFallback);
addComponentToModule(module, acquirers.ViaBillStart);
addComponentToModule(module, acquirers.ViaBillAccept);
addComponentToModule(module, acquirers.NordeaEPaymentFIStart);
addComponentToModule(module, acquirers.NordeaEPaymentFIAccept);

//Services
module.service("EkspresBankService", acquirers.EkspresBankService)
module.service("SwishService", acquirers.SwishService)
module.service("ViaBillService", acquirers.ViaBillService)
module.service("NordeaEPaymentFIService", acquirers.NordeaEPaymentFIService)
