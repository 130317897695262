export class MinValue {
    restrict = "A";
    require = "ngModel";
    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {

        ngModel.$validators.minvalue = (val) => {
            return parseInt(val) >= parseInt(attrs.becMinValue);
        };
    };
}

export class MaxValue {
    restrict = "A";
    require = "ngModel";
    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {

        ngModel.$validators.maxvalue = (val) => {
            return parseInt(val) <= parseInt(attrs.becMaxValue);
        };
    };
}