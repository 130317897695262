import * as angular from "angular";

import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as invoice from "./index";

export const moduleName = "invoice";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

//// Components
addComponentToModule(module, invoice.InvoiceFormController);
addComponentToModule(module, invoice.InvoiceAcceptController);
addComponentToModule(module, invoice.InvoiceDecline);
addComponentToModule(module, invoice.InvoiceStart);
addComponentToModule(module, invoice.SsnFormController);
addComponentToModule(module, invoice.SsnInputController);
addComponentToModule(module, invoice.SsnVerifyController);
addComponentToModule(module, invoice.InvoiceOptionSelector);
addComponentToModule(module, invoice.InvoicingNameComponent);
addComponentToModule(module, invoice.NameEmailEditController);
addComponentToModule(module, invoice.PartPaymentDetails);
addComponentToModule(module, invoice.SecciLinkComponent);

module
    .directive("becVerifySsnInput", getFactoryFor(invoice.VerifySsnInput))

    // Services
    .service("InvoiceService", invoice.InvoiceService)

    // Directives
    .directive("becSsnParser", getFactoryFor(invoice.SsnParser));