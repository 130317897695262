export class BecSvgCountrycode {
    restrict = "AE";

    scope = {
        becSvgCountrycode: "=becSvgCountrycode"
    };

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {

        scope.$watch("becSvgCountrycode", render);

        function render(value: string) {
            if (value) {
                attrs.$set("becSvgSrc", `flag-${value}`);
            } else {
                attrs.$set("becSvgSrc", "");
            }
        }
    };
}