import * as config from "../../config";

export interface IConfigReaderService {
    isProduction(): boolean;
    readonly environment: string;
    readonly webServiceEndpoint: string;
    readonly version: string;
    readonly authorizePollEndpoint: string;
    readonly allowedWaitRedirectHosts: string[];
}

export class ConfigReaderService implements ng.IServiceProvider {
    isProduction(): boolean {
        return (config.environment as any) === "Production";
    }

    get environment(): string {
        return this.isBuilt() ?
            config.environment :
            "Development"
            // "Test"
            // "Production"
    }

    get webServiceEndpoint(): string {
        return this.isBuilt() ?
            `https://${config.webServiceEndpoint}` :
            //    "https://localhost:44366"                       // Localhost
            "https://api-dev.v1.checkout.bambora.com"       // Development
            // "https://api-test.v1.checkout.bambora.com"      // Test
           //  "https://api.v1.checkout.bambora.com"           // Production
    }

    get authorizePollEndpoint(): string {
      if (this.isBuilt() && config.environment.toLowerCase() === "production") return "https://authorize-v1.api-eu.bambora.com/poll/";
      if (this.isBuilt() && config.environment.toLowerCase() === "test") return "https://authorize-v1-test.api-eu.bambora.com/poll/";
      return "https://authorize-v1-dev.api-eu.bambora.com/poll/";
    }

    get allowedWaitRedirectHosts(): string[] {
      if (this.isBuilt() && config.environment.toLowerCase() === "production") {
        return ["authorize-v1.api-eu.bambora.com", "wallet-v1.api-eu.bambora.com"];
      }

      if (this.isBuilt() && config.environment.toLowerCase() === "test") {
        return ["authorize-v1-test.api-eu.bambora.com", "wallet-v1-test.api-eu.bambora.com"];
      }

      return ["authorize-v1-dev.api-eu.bambora.com", "wallet-v1-dev.api-eu.bambora.com"];
    }

    get version(): string {
        return this.isBuilt() ? config.version : "0.0.0";
    }

    private isBuilt(): boolean {
        switch(config.environment as any) {
            case "Development":
            case "Test":
            case "Production":
                return true;
        }

        return false;
    }

    $get() : IConfigReaderService {
        return {
            isProduction: this.isProduction,
            environment: this.environment,
            webServiceEndpoint: this.webServiceEndpoint,
            version: this.version,
            authorizePollEndpoint: this.authorizePollEndpoint,
            allowedWaitRedirectHosts: this.allowedWaitRedirectHosts
        };
    }
}
