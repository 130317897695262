export class OrderDetails {
    restrict = "E";
    template = require("./order-details.html");
    replace = true;

    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
        scope.showOrderLine = false;
        scope.$watch("main.session.order",
        () => {

            if (!scope.main.session.order) {
                return;
            }
            this.calculateOrderlineTotal(scope.main.session.order.lines);
        });
    };


    private calculateOrderlineTotal(orderlines) {
        for (let key in orderlines) {
            if (orderlines.hasOwnProperty(key)) {
                orderlines[key].total = orderlines[key].price * orderlines[key].quantity;
            }
        }
    }
}