require("./circular-loader.scss");

export class BecCircularLoader {
    static $inject = [];
    restrict = "E";

    template = `
        <bec-circular-loader-left-container>
            <bec-circular-loader-left-border></bec-circular-loader-left-border>
        </bec-circular-loader-left-container>

        <bec-circular-loader-right-container>
            <bec-circular-loader-right-border></bec-circular-loader-right-border>
        </bec-circular-loader-right-container>
    `;

    link = (scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes,
        ngModel: Array<ng.INgModelController>,
        transclude: ng.ITranscludeFunction) => {

    };
}
