import { Component, SessionService } from "../../../core/index";

import orderStatus from "./order-status.scss";
import checkmark from "../checkmark/checkmark.scss";
import failmark from "../failmark/failmark.scss";

const styles = {
    orderStatus: orderStatus,
    checkmark: checkmark,
    failmark: failmark
};

@Component({
    selector: "becOrderStatus",
    template: require("./order-status.html"),
    bindings: {
        isSuccess: "<"
    },
    transclude: true
})
export class OrderStatus {
    static $inject = ["SessionService"];

    constructor(public session: SessionService) {}

    styles = styles;
    isSuccess: boolean;
}
