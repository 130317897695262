import demo from './../demo.scss'
import demoAddress from './demo-address.scss'

const styles = {
    demo: demo,
    demoAddress: demoAddress
};

export class DemoAddress {
    static $inject = [];

    styles = styles;
    restrict = "EA";
    template = require("./demo-address.html");
    replace = false;
    controller = DemoAddressController;
    controllerAs = "controller";
    scope = {};
    bindToController = {
        header: "@",
        addressObject: "="
    };

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {};
}

export class DemoAddressController {

}
