import * as _ from "underscore";

export interface DemoMerchant
{
    env: string;
    name: string;
    merchantNumber: string;
    currency: string;
    apikey: string;
    invoiceCountry:  "DNK" | "SWE" | "FIN" | "NOR" ;
}
export class MerchantDemoDataService {
    getDemoMerchants(env?: string): DemoMerchant[] {
        const merchants: DemoMerchant[] = [
            {
                env: "Development",
                name: "T000000000",
                merchantNumber: "T000000000",
                currency: "DKK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAwMDpQYXNzd29yZC8x",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T000000015 - Walley (old) - Norway",
                merchantNumber: "T000000015",
                currency: "NOK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAxNTpQYXNzd29yZC8x",
                invoiceCountry: "NOR"
            },
            {
                env: "Development",
                name: "T000000016 - Walley (old) - Sweden",
                merchantNumber: "T000000016",
                currency: "SEK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAxNjpQYXNzd29yZC8x",
                invoiceCountry: "SWE"
            },
            {
                env: "Development",
                name: "T000000017 - Walley - Sweden",
                merchantNumber: "T000000017",
                currency: "SEK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAxNzpQYXNzd29yZC8x",
                invoiceCountry: "SWE"
            },
            {
                env: "Development",
                name: "T000000017 - Walley - Norway",
                merchantNumber: "T000000017",
                currency: "NOK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAxNzpQYXNzd29yZC8x",
                invoiceCountry: "NOR"
            },
            {
                env: "Development",
                name: "T000000017 - Walley - Finland",
                merchantNumber: "T000000017",
                currency: "EUR",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAxNzpQYXNzd29yZC8x",
                invoiceCountry: "FIN"
            },
            {
                env: "Development",
                name: "T017177801 - Demo",
                merchantNumber: "T017177801",
                currency: "DKK",
                apikey: "WDNkMkxZSHJtUlFJT3B1ankzMmxzQFQwMTcxNzc4MDE6Mk1iQ3RKSkRvbXF0MERrWVhqVmd3VEFOcTdrWGdEUjgwNzRHY08yb1g=",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T018048101 - Wallet - DKK - Demo",
                merchantNumber: "T018048101",
                currency: "DKK",
                apikey: "Zm1JSWdmZVJ5WURYc2tnR0pGSVVAVDAxODA0ODEwMTo4RHhGTUxQRkRDQjdiajdoV01GZXdjdzZrV3pZZFZ3RjJGbDBPZFZ0",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T018048101 - Wallet - NOK - Demo",
                merchantNumber: "T018048101",
                currency: "NOK",
                apikey: "Zm1JSWdmZVJ5WURYc2tnR0pGSVVAVDAxODA0ODEwMTo4RHhGTUxQRkRDQjdiajdoV01GZXdjdzZrV3pZZFZ3RjJGbDBPZFZ0",
                invoiceCountry: "NOR"
            },
            {
                env: "Development",
                name: "T018048101 - Nordea - EUR - Demo",
                merchantNumber: "T018048101",
                currency: "EUR",
                apikey: "Zm1JSWdmZVJ5WURYc2tnR0pGSVVAVDAxODA0ODEwMTo4RHhGTUxQRkRDQjdiajdoV01GZXdjdzZrV3pZZFZ3RjJGbDBPZFZ0",
                invoiceCountry: "FIN"
            },
            {
                env: "Development",
                name: "T116864101 - Ekspres Bank - DKK - Demo",
                merchantNumber: "T116864101",
                currency: "DKK",
                apikey: "blVLS056OWFHSmZDaDVsNkcxb0pAVDExNjg2NDEwMTo2WVk5VmNMYXJ5ZEo3OFJUdmxVYzNGemFiaFU3MHlUWGZVa25VbE9M",
                invoiceCountry: "SWE"
            },
            {
                env: "Development",
                name: "T018048103 - Single payment type",
                merchantNumber: "T018048103",
                currency: "DKK",
                apikey: "aWdVaUNGc2oxZXRzNUlxOURmakRAVDAxODA0ODEwMzp5c2EzUjkxVEd0SDVtS1BzWE5QOFkwVzE2ZDZVM1lmRmU1TU9nMHFk",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T655206501 - Swish - SEK - Demo",
                merchantNumber: "T655206501",
                currency: "SEK",
                apikey: "bUVMcFNZcHZVd3h4dExSNlEwMGtAVDY1NTIwNjUwMTo2MXc0MHhTSHFkSFExcGJrdlc1ckRIQ0p4UjJZV2dYRW43TktycU40",
                invoiceCountry: "SWE"
            },
            {
                env: "Development",
                name: "T950579301 - ViaBill - DKK - Demo",
                merchantNumber: "T950579301",
                currency: "DKK",
                apikey: "RXlMTzVTbjQ4TTQyTmZpd0RIUDBAVDk1MDU3OTMwMTpqM09kcnVtQ0I2cU9lVEJPakFOVGJYT3BvU0NLTUk3SnVlWWs5SDdr",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T017049804 - Bambora GA - DKK - Demo",
                merchantNumber: "T017049804",
                currency: "DKK",
                apikey: "ZmJoUGc2d0JDdDRoUkQ4aGJWM1NAVDAxNzA0OTgwNDppbWtRc1c0YjRrUjVTcXhycTN4S3BNUGhDSEpUeHkwOW5JOVNRcDZq",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T000000007 - Nets Test",
                merchantNumber: "T000000007",
                currency: "DKK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAwNzpQYXNzd29yZC8x",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T346137701 - Zero Automated Test - DKK",
                merchantNumber: "T346137701",
                currency: "DKK",
                apikey: "SWRnNUt5ak1PeXhzUER0SGNYUFJAVDM0NjEzNzcwMToyMW1VVjlIcDYwSWF3Z2dLUG9rRzg0cHNFN0Fwc1dUa3dWSHFQbDls",
                invoiceCountry: "DNK"
            },
            {
                env: "Development",
                name: "T346137701 - Checkout UI Test - SEK",
                merchantNumber: "T880819301",
                currency: "SEK",
                apikey: "NFBVdE1KZ3RqNHFZOEFWcE9QSGZAVDg4MDgxOTMwMTpXQmlnandEM3NjU2VyVW5sbTZxd3NOUlMyVUNtdzU1Y0d1SlNHT2t6",
                invoiceCountry: "SWE"
            },
            {
                env: "Test",
                name: "T000000003 - Walley - Sweden",
                merchantNumber: "T000000003",
                currency: "SEK",
                apikey: "VFNMRGN2Z2xyM3RDMFlpaFM0ekFAVDAwMDAwMDAwMzpJZ3VhcllvRTY3RnZKQ08xT1RHbmN1eWJFdmNWcnpmZGJuSU04S3Zj",
                invoiceCountry: "SWE"
            },
            {
                env: "Test",
                name: "T000000003 - Nordea - Finland",
                merchantNumber: "T000000003",
                currency: "EUR",
                apikey: "VFNMRGN2Z2xyM3RDMFlpaFM0ekFAVDAwMDAwMDAwMzpJZ3VhcllvRTY3RnZKQ08xT1RHbmN1eWJFdmNWcnpmZGJuSU04S3Zj",
                invoiceCountry: "FIN"
            },
            {
                env: "Test",
                name: "T000000000",
                merchantNumber: "T000000000",
                currency: "DKK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAwMDpQYXNzd29yZC8x",
                invoiceCountry: "DNK"
            },
            {
                env: "Test",
                name: "T127564901",
                merchantNumber: "T127564901",
                currency: "SEK",
                apikey: "SnJ6cXJETFFoZjNlY0xiUEZNQlFAVDEyNzU2NDkwMTpVOXNFRmRPdnlQSzBPcmQ1OVFPYXphakVGd01UMHRZU3ZnR2pYSkFu",
                invoiceCountry: "SWE"
            },
            {
                env: "Test",
                name: "T000000001",
                merchantNumber: "T000000001",
                currency: "DKK",
                apikey: "RGV2ZWxvcGVyTWVyY2hhbnRAVDAwMDAwMDAwMTpQYXNzd29yZC8x",
                invoiceCountry: "DNK"
            },
            {
                env: "Test",
                name: "T588390501 - Walley - Sweden TEMP",
                merchantNumber: "T588390501",
                currency: "SEK",
                apikey: "eVJra0dnaE4xT0lXTXdxZUtrMEFAVDU4ODM5MDUwMTpoamJFN29kMG9IOVNZUG1oNE5XVzBTZWY3NTFrdkRyMnVNYXBRNUIy",
                invoiceCountry: "SWE"
            },
            {
                env: "Test",
                name: "T797542501 - DKK - Wallets",
                merchantNumber: "T797542501",
                currency: "DKK",
                apikey: "SW5EakY3Z2pRN2I0eG1CcjVZTmVAVDc5NzU0MjUwMTpDNzh1QlR0SjRCdEZmNVFZejNyck1sS3NnaWoxemt4N0NrMWxEdDZL",
                invoiceCountry: "DNK"
            },
            {
                env: "Test",
                name: "T797542501 - SEK - Wallets - Walley",
                merchantNumber: "T797542501",
                currency: "SEK",
                apikey: "SW5EakY3Z2pRN2I0eG1CcjVZTmVAVDc5NzU0MjUwMTpDNzh1QlR0SjRCdEZmNVFZejNyck1sS3NnaWoxemt4N0NrMWxEdDZL",
                invoiceCountry: "SWE"
            },
            {
                env: "Test",
                name: "T797542502 - NOK - Wallets - Walley",
                merchantNumber: "T797542502",
                currency: "NOK",
                apikey: "b3dEb291elJ1Q1JjSDZxMUxxbjNAVDc5NzU0MjUwMjpKUXR1b0tkVGRGdWZWNWMwNjZlS0lyZ01zdVBmU2F6eGdkYndjdGEy",
                invoiceCountry: "NOR"
            },
            {
                env: "Test",
                name: "T447078505 - Bambora GA - DKK - Demo",
                merchantNumber: "T447078505",
                currency: "DKK",
                apikey: "Z2JDbVlZaXNoeFZBQzJvNnVTSEdAVDQ0NzA3ODUwNTpKQ0pNUUZ3a3VWZUY4azg0TDUzcEJWOGFWcXBkRlVobXJmejNxT3Zz",
                invoiceCountry: "DNK"
            },
            {
                env: "Test",
                name: "T000000005 - Nets - DKK - Test",
                merchantNumber: "T000000005",
                currency: "DKK",
                apikey: "SVNJVU03SHFrNkVpTVF0SHI1QjVAVDAwMDAwMDAwNTplb01zQlVtajI4U3FGSHlTcGQ4eG50VWZMelBYaEdXdGZlYTNmenRS",
                invoiceCountry: "DNK"
            },
            {
                env: "Production",
                name: "P782694502 - Production - 3D",
                merchantNumber: "P782694502",
                currency: "DKK",
                apikey:"VlR6MmVUSWlyQWI2bnQ4TnJZU3BAUDc4MjY5NDUwMjoyVUtSY3I3bWNLSnhMSVBuNDFrSzBVUzF1NThuUTd3ek5wV2FpNnpN",
                invoiceCountry: "DNK"
            },
            {
                env: "Production",
                name: "P011622514 - Production - Walley",
                merchantNumber: "P011622514",
                currency: "SEK",
                apikey: "QVByT0xVNm5pa1Ywdno5ekt0c3VAUDAxMTYyMjUxNDpseWtKbDZUNEhxM0RPRGcxeHZsNTF1NDJxM0p3Ulk4RDJXbWxGN0Rw",
                invoiceCountry: "SWE"
            },
            {
                env: "Production",
                name: "T017177701 - Demo",
                merchantNumber: "T017177701",
                currency: "DKK",
                apikey: "SWZtbTB4TXNCbml0WTBUU3lrNDlwQFQwMTcxNzc3MDE6Nm5kbVVTdEFtclNXQUNra3lLQkkyc2Ixb3FWSTFiVTRmeW1SamE0NDI=",
                invoiceCountry: "DNK"
            },
            {
                env: "Production",
                name: "T017177801 - Demo",
                merchantNumber: "T017177801",
                currency: "DKK",
                apikey: "WDNkMkxZSHJtUlFJT3B1ankzMmxzQFQwMTcxNzc4MDE6Mk1iQ3RKSkRvbXF0MERrWVhqVmd3VEFOcTdrWGdEUjgwNzRHY08yb1g=",
                invoiceCountry: "DNK"
            },
            {
                env: "Production",
                name: "P011622503 - Production",
                merchantNumber: "P011622503",
                currency: "DKK",
                apikey: "czFTTTBDTjhKS2YyakxtMzFqRWdRQFAwMTE2MjI1MDM6WW1QMHhhVzlxR0NGamRUcjhzVXJCRGQwVlVPRWcyRVl3enNwY3djQlA=",
                invoiceCountry: "DNK"
            }
        ];

        if (!env) {
            return merchants;
        }

        return _.filter<any>(
            merchants,
            (e) => {
                return e.env === env;
            }
        );
    }

    getMerchant( merchant: string): DemoMerchant {
        return _.find<DemoMerchant>(
            this.getDemoMerchants(),
            (m) => {
                return m.merchantNumber === merchant;
            }
        );
    }
}
