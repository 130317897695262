import * as angular from "angular";

export class AccessibilityService {
    init() {
        if (!angular.element("#screenReaderNotifier")[0]) {
            angular.element(document.body).append(
                angular.element(`
                    <div id="screenReaderNotifier" class="offscreen" aria-live="polite"></div>
                `)
            );
        }
    }

    notify(message: string) {
        angular.element("#screenReaderNotifier").text(message);

        setTimeout(
            () => {
                if (angular.element("#screenReaderNotifier").text() !== message) return;

                angular.element("#screenReaderNotifier").text("");
            },
            5000
        );
    }
}