import { AbstractDispatcher } from "@bambora/checkout-core-events";

export class WindowStateService {

    dispatcher: AbstractDispatcher;

    // Properties
    get currentState() {
        return this.getWindowState();
    }


    // Constructor
    static $inject = ["$stateParams", "$window"];

    public constructor(private _$stateParams, private _$window: ng.IWindowService) {}


    // Methods
    isFullScreen = () =>  this._$window.self === this._$window.top;

    isOverlay = () => this.isInIFrame()  && !this.isInline() && !this.isInlineNoCss() && this.getWindowState() !== WindowStateEnum.FullScreen;

    isInline = () => this.isInIFrame() && this.getWindowState() === WindowStateEnum.Inline;

    isInlineNoCss = () => this.isInIFrame()  && this.getWindowState() === WindowStateEnum.InliceNoCss;

    isInWebView = () => this.dispatcher.eventMode === "android" || this.dispatcher.eventMode === "ios";

    isInIFrame = () => (this._$window.self !== this._$window.top) || this.isInWebView();


    // Private methods
    private getWindowState() {
        if (this._$stateParams.ui) {
            switch (this._$stateParams.ui) {
                case "fullscreen": return 1;
                case "modal": return 2;
                case "inline": return 3;
                default: return 1;
            }
        }

        return this._$stateParams.windowstate === undefined
            ? WindowStateEnum.FullScreen
            : parseInt(this._$stateParams.windowstate);
    }
}

export enum WindowStateEnum {

    FullScreen = 1,
    Overlay = 2,
    Inline = 3,
    InliceNoCss = 4
}
