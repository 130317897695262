import * as angular from "angular";

import {
  AppStateService,
  SessionService,
  LanguageService,
  ErrorService,
  AccessibilityService,
  UrlService,
  ExpressCheckoutService
} from "./core/index";

import { CheckoutEvent, createCheckoutDispatcher } from "@bambora/checkout-core-events";
import { WalletService } from "./wallets";

declare global {
  interface Window {
    sdkVersion: string;
    handshakeIdData: any;
  }
}

export class States {

  static Demo = {
    name: "demo",
    url: "/demo",
    views: { "@": { template: "<bec-demo-initialization />" } }
  };

  static Test = {
    name: "test",
    url: "/testtest",
    views: { "@": { template: require("./test/test.html") } },
    resolve: {
      visibility: [
        "AppStateService", (appState: AppStateService) => {
          appState.cancelLoading();
        }
      ]
    }
  };

  static UnitTest = {
    name: "unittest",
    url: "/unittest",
    views: { "@": { template: "" } }
  };

  static Error = {
    name: "error",
    url: "/error",
    views: { "@": { template: "<bec-session-expired></bec-session-expired>" } },
    resolve: {
      stoploading: [
        "AppStateService", "AccessibilityService", (appState, accessibility: AccessibilityService) => {
          accessibility.init();
          appState.cancelLoading();
        }
      ]
    }
  };

  static Root = {
    name: "root",
    views: { "@": { template: "" } },
    url: "/?ui&language",
    resolve: {
      loadSession: ["AppStateService", listenForLoadSession]
    }
  };

  static Wait = {
    name: "wait",
    url: "/wait?epayreturn",
    views: { "@": { template: "<bec-wait />" } },
  }

  static Session = {
    abstract: true,
    name: "session",
    views: { "@": { template: "<ui-view/>" } },
    url: "/{token:[0-9a-z]{32}}?ui&language&windowstate&demo&prefilled-demo-card&show-demo-cards&txnid",
    resolve: {
      session: [
        "SessionService", "$stateParams", "AccessibilityService", (session: SessionService, $stateParams, accessibility: AccessibilityService) => {
          accessibility.init();
          return session.getSession($stateParams.token);
        }
      ],
      urls: [
        "session", "UrlService", "$stateParams", (session, urls, $stateParams) => {
          return urls.setInitialUrls(session, $stateParams.token);
        }
      ],
      customer: [
        "session", "CustomerService", (session, customer) => {
          return customer.initialize(session);
        }
      ],
      language: [
        "session", "LanguageService",
        (session: Server.checkoutresponse, languageService: LanguageService) => {
          return languageService.setCurrentLanguage(session.language);
        }
      ],
      wallets: [
        "session", "WalletService", (session: Server.checkoutresponse,
          walletService: WalletService) => {
            return walletService.initializeWallets();
        }
      ],
      criticalerror: [
        "session", "AppStateService", "ErrorService", (session: Server.checkoutresponse,
          appStateService: AppStateService,
          errorService: ErrorService) => {
          if (!session.meta.result) {
            errorService.displayErrorMessage(session.meta.message.enduser, States.Error.name);
            appStateService.switchState(States.Error.name);
          }
        }
      ]
    },

    Start: {
      name: "start",
      url: "",
      views: { "@": { template: "<bec-payment-methods></bec-payment-methods>" } }
    },

    Accept: {
      url: "/accept",
      name: "accept",
      views: { "@": { template: "<bec-wallet-accept></bec-wallet-accept>" } }, //Ok to use wallet accept as general, as it is a plain accept page
      title: "Payment Completed"
    },

    Expired: {
      name: "expired",
      url: "/expired",
      views: { "@": { template: "<bec-session-expired></bec-session-expired>" } },
      resolve: {
        stoploading: [
          "AppStateService", (appState) => {
            appState.cancelLoading();
          }
        ]
      }
    },

    PaymentCard: {
      name: "paymentCard",
      url: "/creditcard",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='paymentcard'></bec-payment-methods>" } }
      },

      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='paymentcard'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-payment-card-accept></bec-payment-card-accept>" } },
        title: "Payment Completed"
      },
      ReturnFrom3DAccept: {
        url: "/returnfrom3daccept",
        name: "returnfrom3daccept",
        views: { "@": { template: "" } }
      },
      ReturnFrom3DDecline: {
        url: "/returnfrom3ddecline",
        name: "returnfrom3ddecline",
        views: { "@": { template: "" } }
      }
    },

    Invoice: {
      name: "invoice",
      abstract: true,
      url: "/invoice",
      views: {
        "@": {
          template: "<ui-view/>"
        }
      },

      Start: {
        url: "",
        name: "start",
        views: { "@": { template: "<bec-payment-methods selected-method='invoice'></bec-payment-methods>" } }
      },
      SSNInput: {
        url: "/ssninput",
        name: "ssninput",
        views: { "@": { template: "<bec-invoice-ssn-input />" } }
      },
      SSNVerify: {
        url: "/ssnverify",
        name: "ssnverify",
        views: { "@": { template: "<bec-invoice-ssn-verify />" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-invoice-accept />" } },
        title: "Payment Completed"
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='invoice'></bec-payment-methods>" } }
      }
    },

    PartPayment: {
      name: "partpayment",
      abstract: true,
      url: "/partpayment",
      views: {
        "@": {
          template: "<ui-view/>"
        }
      },

      Start: {
        url: "",
        name: "start",
        views: { "@": { template: "<bec-payment-methods selected-method='partpayment'></bec-payment-methods>" } }
      },
      SSNInput: {
        url: "/ssninput",
        name: "ssninput",
        views: { "@": { template: "<bec-invoice-ssn-input />" } }
      },
      SSNVerify: {
        url: "/ssnverify",
        name: "ssnverify",
        views: { "@": { template: "<bec-invoice-ssn-verify />" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-invoice-accept />" } },
        title: "Payment Completed"
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='partpayment'></bec-payment-methods>" } }
      }
    },

    EkspresBank: {
      name: "ekspresbank",
      url: "/ekspresbank",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='ekspresbank'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='ekspresbank'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-ekspres-bank-accept></bec-ekspres-bank-accept>" } },
        title: "Payment Completed"
      }
    },
    MobilePay: {
      name: "mobilepay",
      url: "/mobilepay",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='mobilepay'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='mobilepay'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-wallet-accept></bec-wallet-accept>" } },
        title: "Payment Completed"
      }
    },
    Vipps: {
      name: "vipps",
      url: "/vipps",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='vipps'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='vipps'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-wallet-accept></bec-wallet-accept>" } },
        title: "Payment Completed"
      }
    },
    ApplePay: {
      name: "applepay",
      url: "/applepay",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='applepay'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='applepay'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-wallet-accept></bec-wallet-accept>" } },
        title: "Payment Completed"
      }
    },
    GooglePay: {
      name: "googlepay",
      url: "/googlepay",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='googlepay'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='googlepay'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-wallet-accept></bec-wallet-accept>" } },
        title: "Payment Completed"
      }
    },
    Swish: {
      name: "swish",
      url: "/swish",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='swish'></bec-payment-methods>" } }
      },
      Fallback: {
        url: "/fallback",
        params: {
          url: null
        },
        name: "fallback",
        views: { "@": { template: "<bec-swish-fallback></bec-swish-fallback>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='swish'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-swish-accept></bec-swish-accept>" } },
        title: "Payment Completed"
      }
    },
    ViaBill: {
      name: "viabill",
      url: "/viabill",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='viabill'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='viabill'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-viabill-accept></bec-viabill-accept>" } },
        title: "Payment Completed"
      }
    },
    NordeaEPaymentFI: {
      name: "nordeaepaymentfi",
      url: "/direct-banking",
      abstract: true,
      views: { "@": { template: "<ui-view/>" } },

      Start: {
        name: "start",
        url: "",
        views: { "@": { template: "<bec-payment-methods selected-method='nordeaepaymentfi'></bec-payment-methods>" } }
      },
      Decline: {
        url: "/decline",
        name: "decline",
        views: { "@": { template: "<bec-payment-methods selected-method='nordeaepaymentfi'></bec-payment-methods>" } }
      },
      Accept: {
        url: "/accept",
        name: "accept",
        views: { "@": { template: "<bec-nordeaepaymentfi-accept></bec-nordeaepaymentfi-accept>" } },
        title: "Payment Completed"
      }
    },
    PaymentRequest: {
      name: "paymentrequest",
      url: "/paymentrequest",
      views: { "@": { template: "<bec-payment-request-landing />" } },
      params: {
        paymentRequestSession: null
      }
    }
  };

  static PaymentRequest = {
    name: "paymentrequest",
    views: { "@": { template: "<bec-payment-request-landing />" } },
    url: "/paymentrequests/{paymentrequestid:[0-9a-z]{32}}?language",
    resolve: {
      paymentRequestSession: [
        "AppStateService", "$stateParams", "ExpressCheckoutService", "ErrorService", "$timeout", "LanguageService",
        (appStateService: AppStateService, $stateParams, expressCheckoutService: ExpressCheckoutService, errorService: ErrorService, $timeout: ng.ITimeoutService, languageService: LanguageService) => {

          languageService.setCurrentLanguage($stateParams.language || navigator.language);

          return expressCheckoutService.setPaymentRequestSession($stateParams.paymentrequestid)
            .then(response => {
              if (response.meta.result) {
                if (response.termsurl != null) {
                  $timeout(() => {
                    appStateService.switchState(States.Session.PaymentRequest.name, { token: response.token, paymentRequestSession: response });
                  });
                }
                else {
                  $timeout(() => {
                    appStateService.switchState(States.Session.Start.name, { token: response.token });
                  });
                }
              }
              else {
                errorService.displayErrorMessage(response.meta.action.type || response.meta.message.enduser, States.PaymentRequest.name);
              }
            });
        }
      ]
    }
  };
}

function listenForLoadSession(appStateService: AppStateService) {
  if (parent === window) return;

  window.addEventListener("message", (event: MessageEvent) => {
    if (!event.data || event.data.action !== "loadSession") return;

    const { messageId, payload } = event.data;

    appStateService.switchState(States.Session.Start.name, { token: payload }).then(
      () => window.parent.postMessage({ result: true, messageId, payload: null }, "*"),
      () => window.parent.postMessage({ result: false, messageId, payload: null }, "*")
    );
  });
}

export const onConfig = [
  "$stateProvider",
  "$urlRouterProvider",
  (
    $stateProvider,
    $urlRouterProvider
  ) => {
    // $urlRouterProvider.when("", "/");
    // $urlRouterProvider.when("/", "/");
    $urlRouterProvider.when("/init", "/demo");
    $urlRouterProvider.otherwise("");

    let config = {
      UseNameBasedInheritance: true
    };

    StateParser($stateProvider, config, States);
  }
];

export const onRun = [
  "SessionService",
  "$rootScope",
  "AppStateService",
  "$timeout",
  "ErrorService",
  "UrlService",
  "$q",
  "$stateParams",
  (
    session: SessionService,
    $rootScope,
    appState: AppStateService,
    $timeout: ng.ITimeoutService,
    errorService: ErrorService,
    urlService: UrlService,
    $q: ng.IQService,
    $stateParams
  ) => {
    if (window["locationHash"]) {
      try {
        const options = JSON.parse(atob(window["locationHash"].replace("#", "")));

        Object.keys(options).forEach(x => {
          appState.options[x] = options[x]
        });

      } catch (e) { }
    }

    appState.windowState.dispatcher = createCheckoutDispatcher({
      acceptUrl: urlService.acceptUrl,
      cancelUrl: urlService.declineUrl,
      handshakeId: $q((resolve, reject) => {
        if (parent === window) {
          resolve("");
          return;
        }

        if (!appState.options.version) {
          resolve(sessionStorage.getItem("handshakeId"));
          return;
        };

        if (window.handshakeIdData) {
          respond(window.handshakeIdData);
          return;
        }

        window.addEventListener("message", onMessage);
        function onMessage(event) {
          if (!event.data || event.data.action !== "initiateHandshake") return;
          window.removeEventListener("message", onMessage);
          respond(event.data);
        }

        function respond(eventData) {
          resolve(eventData.payload);

          window.parent.postMessage({
            result: true,
            messageId: eventData.messageId,
            payload: eventData.payload
          }, "*");
        }
      })
    });

    $rootScope.$on("$stateChangeStart",
      (event, toState, toParams, fromState, fromParams, options) => {
        if (toState.name === States.Root.name) return;
        if (toState.name === States.Session.Expired.name) return;

        if (session.isExpired) {
          event.preventDefault();
          appState.switchState(States.Session.Expired.name, { token: session.sessionToken });
          return;
        }

        if (
          toState.name === States.Session.Accept.name ||
          toState.name === States.Session.PaymentCard.Accept.name ||
          toState.name === States.Session.Invoice.Accept.name ||
          toState.name === States.Session.PartPayment.Accept.name ||
          toState.name === States.Session.EkspresBank.Accept.name ||
          toState.name === States.Session.MobilePay.Accept.name ||
          toState.name === States.Session.Vipps.Accept.name ||
          toState.name === States.Session.ApplePay.Accept.name ||
          toState.name === States.Session.GooglePay.Accept.name ||
          toState.name === States.Session.Swish.Accept.name ||
          toState.name === States.Session.ViaBill.Accept.name ||
          toState.name === States.Session.NordeaEPaymentFI.Accept.name
        ) {
          return;
        }
        // Redirect to start of flow on page refresh, unless on accept/decline page
        if (fromState.name === "") {
          if (toState.name.indexOf(States.Session.PaymentCard.name) === 0 &&
            toState.name !== States.Session.PaymentCard.Start.name &&
            toState.name !== States.Session.PaymentCard.Accept.name &&
            toState.name !== States.Session.PaymentCard.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.PaymentCard.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.EkspresBank.name) === 0 &&
            toState.name !== States.Session.EkspresBank.Start.name &&
            toState.name !== States.Session.EkspresBank.Accept.name &&
            toState.name !== States.Session.EkspresBank.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.EkspresBank.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.MobilePay.name) === 0 &&
            toState.name !== States.Session.MobilePay.Start.name &&
            toState.name !== States.Session.MobilePay.Accept.name &&
            toState.name !== States.Session.MobilePay.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.MobilePay.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.Vipps.name) === 0 &&
            toState.name !== States.Session.Vipps.Start.name &&
            toState.name !== States.Session.Vipps.Accept.name &&
            toState.name !== States.Session.Vipps.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.Vipps.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.ApplePay.name) === 0 &&
            toState.name !== States.Session.ApplePay.Start.name &&
            toState.name !== States.Session.ApplePay.Accept.name &&
            toState.name !== States.Session.ApplePay.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.ApplePay.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.GooglePay.name) === 0 &&
            toState.name !== States.Session.GooglePay.Start.name &&
            toState.name !== States.Session.GooglePay.Accept.name &&
            toState.name !== States.Session.GooglePay.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.GooglePay.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.Swish.name) === 0 &&
            toState.name !== States.Session.Swish.Start.name &&
            toState.name !== States.Session.Swish.Accept.name &&
            toState.name !== States.Session.Swish.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.Swish.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.ViaBill.name) === 0 &&
            toState.name !== States.Session.ViaBill.Start.name &&
            toState.name !== States.Session.ViaBill.Accept.name &&
            toState.name !== States.Session.ViaBill.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.ViaBill.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.NordeaEPaymentFI.name) === 0 &&
            toState.name !== States.Session.NordeaEPaymentFI.Start.name &&
            toState.name !== States.Session.NordeaEPaymentFI.Accept.name &&
            toState.name !== States.Session.NordeaEPaymentFI.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.NordeaEPaymentFI.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.Invoice.name) === 0 &&
            toState.name !== States.Session.Invoice.Start.name &&
            toState.name !== States.Session.Invoice.Accept.name &&
            toState.name !== States.Session.Invoice.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.Invoice.Start.name, toParams, { location: "replace" });
          } else if (toState.name.indexOf(States.Session.PartPayment.name) === 0 &&
            toState.name !== States.Session.PartPayment.Start.name &&
            toState.name !== States.Session.PartPayment.Accept.name &&
            toState.name !== States.Session.PartPayment.Decline.name) {
            event.preventDefault();
            appState.switchState(States.Session.PartPayment.Start.name, toParams, { location: "replace" });
          }
        }
      });

    $rootScope.$on("$stateChangeSuccess",
      (event, toState) => {
        toState.title
          ? appState.setTitle(toState.title)
          : appState.resetTitle();

        if (toState.name === States.Root.name) return;

        if (
          toState.name === States.Session.Accept.name ||
          toState.name === States.Session.PaymentCard.Accept.name ||
          toState.name === States.Session.Invoice.Accept.name ||
          toState.name === States.Session.PartPayment.Accept.name ||
          toState.name === States.Session.EkspresBank.Accept.name ||
          toState.name === States.Session.MobilePay.Accept.name ||
          toState.name === States.Session.Vipps.Accept.name ||
          toState.name === States.Session.ApplePay.Accept.name ||
          toState.name === States.Session.GooglePay.Accept.name ||
          toState.name === States.Session.Swish.Accept.name ||
          toState.name === States.Session.ViaBill.Accept.name ||
          toState.name === States.Session.Expired.name ||
          toState.name === States.Session.NordeaEPaymentFI.Accept.name
        ) {
          $("body").addClass("isAcceptState");

          setTimeout(() => angular.element("#checkout").focus(), 1000);

          let authorizeParams = urlService.getAuthorizeParams(session.sessionToken);

          if (authorizeParams !== null) {
            appState.windowState.dispatcher.updateOptions({ acceptUrl: urlService.acceptUrl });

            appState.windowState.dispatcher.dispatch(CheckoutEvent.Authorize, {
              acceptUrl: urlService.acceptUrl ? decodeURIComponent(urlService.acceptUrl) : null,
              data: authorizeParams
            });
          }
        } else {
          $("body").removeClass("isAcceptState");
        }

        if (toState.name !== States.Error.name && toState.name !== States.Wait.name) {
          appState.resolveVisibilityFields();

          //declined invoice or partpayment
          if (toState.name === States.Session.Invoice.Decline.name || toState.name === States.Session.PartPayment.Decline.name) {
            const context = (toState.name === States.Session.Invoice.Decline.name) ?
              States.Session.Invoice.name : States.Session.PartPayment.name;

            session.getTransactionError($stateParams.token, $stateParams.txnid)
              .then((data: Server.geterrorresponse) => {
                errorService.displayExternalErrorMessage(data.errormessage, context);
              });
          }
          else if (toState.name !== States.PaymentRequest.name) {
            errorService.reset();
          }
        }

        if (toState.name.indexOf(States.Session.name) === -1) { // If not a session state
          appState.stateChangeFinished();
          return;
        }

        // Handling if user refreshes the page
        if (session.isAuthorized &&
          !(toState.name === States.Session.Accept.name ||
            toState.name === States.Session.PaymentCard.Accept.name ||
            toState.name === States.Session.Invoice.Accept.name ||
            toState.name === States.Session.PartPayment.Accept.name ||
            toState.name === States.Session.EkspresBank.Accept.name ||
            toState.name === States.Session.MobilePay.Accept.name ||
            toState.name === States.Session.Vipps.Accept.name ||
            toState.name === States.Session.ApplePay.Accept.name ||
            toState.name === States.Session.GooglePay.Accept.name ||
            toState.name === States.Session.Swish.Accept.name ||
            toState.name === States.Session.ViaBill.Accept.name ||
            toState.name === States.Session.NordeaEPaymentFI.Accept.name)) {

          $timeout(() => {
            appState.switchState(States.Session.PaymentCard.Accept.name, { token: session.sessionToken });
          });

          return;
        }

        if (
          session.immediateRedirectToAccept &&
          (
            toState.name === States.Session.Accept.name ||
            toState.name === States.Session.PaymentCard.Accept.name ||
            toState.name === States.Session.Invoice.Accept.name ||
            toState.name === States.Session.PartPayment.Accept.name ||
            toState.name === States.Session.EkspresBank.Accept.name ||
            toState.name === States.Session.MobilePay.Accept.name ||
            toState.name === States.Session.Vipps.Accept.name ||
            toState.name === States.Session.ApplePay.Accept.name ||
            toState.name === States.Session.GooglePay.Accept.name ||
            toState.name === States.Session.Swish.Accept.name ||
            toState.name === States.Session.ViaBill.Accept.name ||
            toState.name === States.Session.NordeaEPaymentFI.Accept.name
          )
        ) {
          const cancelRedirect = (event: JQueryEventObject) => {
            if (event.keyCode !== 27) return;

            session.cancelDelayedRedirectToAccept();

            $("body").off("keydown", cancelRedirect);
          };

          $("body").on("keydown", cancelRedirect);

          let timeout = 0;

          if (session.immediateRedirectToAcceptValue > 1) {
            timeout = session.immediateRedirectToAcceptValue * 1000;
          }

          session.initiateDelayedRedirectToAccept(timeout).then(() => {
            $("body").off("keydown", cancelRedirect);
          });
        }

        if (session.isExpired && toState.name !== States.Session.Expired.name) {
          $timeout(() => {
            appState.switchState(States.Session.Expired.name, { token: session.sessionToken });
          });
          return;
        }

        appState.stateChangeFinished();
      });
  }
];


let StateParser = ($stateProvider, config, states) => {
  let addStates = (state, parentName?) => {
    let stateProperties: any = {};

    if (parentName) {
      if (config.UseNameBasedInheritance) {
        state.name = parentName + "." + state.name;
      } else {
        stateProperties.parent = parentName;
      }
    }

    for (let stateKey in state) {
      if (stateKey[0].toUpperCase() === stateKey[0]) {
        addStates(state[stateKey], state.name);
      } else {
        stateProperties[stateKey] = state[stateKey];
      }
    }

    $stateProvider.state(stateProperties);
  };

  for (let key in states) {
    addStates(States[key]);
  }
};
