import * as _ from "underscore";
import {
    AppStateService,
    SessionService
} from "../../../core/index";

import footer from "../../../shared/components/footer/footer.scss";

const styles = {
    footer: footer
};

export interface IFormatText {
    text: string;
    textClass?: string;
}

export class AvailablePaymentTypesList {
    formattedAvailablePaymentTypes: Array<IFormatText>;

    template = `
        <span ng-repeat="formatText in formattedAvailablePaymentTypes" class="{{formatText.textClass}}">
            {{formatText.text}}
        </span>
    `;

    static $inject: string[] = ["SessionService", "gettextCatalog", "AppStateService"];


    public constructor(
        private sessionService: SessionService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private appState: AppStateService) {
    }

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {

        scope.formattedAvailablePaymentTypes = this
            .getFormattedAvailablePaymentTypes(this.sessionService.paymentCardTypes);

    };

    private getFormattedAvailablePaymentTypes(paymentGroups: Server.paymentgroup[]): Array<IFormatText> {
        const formatText: Array<IFormatText> = [];

        if (!paymentGroups || paymentGroups.length === 0)
            return formatText;


        const paymentTypes = _.map(paymentGroups as _.List<any>,
            item => new PaymentTypeViewModel(item.id as number, item.name as string, item.displayname as string));
        const len = paymentTypes.length;
        if (len === 0) return [];


        if (len === 1) {
            formatText.push(
            { text: paymentTypes[0].displayName, textClass: styles.footer.strong },
            { text: ` ${this.gettextCatalog.getString("is accepted.")} `});
        } else {
            formatText.push(
            { text: paymentTypes[len - 2].displayName, textClass: styles.footer.strong },
            { text: ` ${this.gettextCatalog.getString("and")} ` },
            { text: paymentTypes[len - 1].displayName, textClass: styles.footer.strong },
            { text: ` ${this.gettextCatalog.getString("are accepted.")}` });

            for (let i = len - 3; i >= 0; i--) {
                formatText.unshift(
                { text: paymentTypes[i].displayName, textClass: styles.footer.strong },
                { text: ", " });
            }
        }

        return formatText;
    }
}

class PaymentTypeViewModel {
    private static imageBaseUrl = "https://s3-eu-west-1.amazonaws.com/epayzero/images/paymentlogos/{id}.png";
    id: number;
    name: string;
    displayName: string;

    public constructor(id: number, name: string, displayName: string) {
        this.displayName = displayName;
        this.name = name;
        this.id = id;
    }

    getImageUrl(): string {
        const url = PaymentTypeViewModel.imageBaseUrl.replace("{id}", this.id.toString());
        return url;
    }
}
