import * as _ from "underscore";
import {
    Component,
    MerchantService,
    Country,
    AppStateService,
    SessionService
} from "../../core/index";
import { InvoiceService } from "../shared/index";

import app from "../../app.scss";
import buttons from "../../styles/buttons.scss";
import creditCardForm from "../../payment-card/payment-card-start/credit-card-form/credit-card-form.scss";
import errorMessage from "../../shared/components/error-message/error-message.scss";
import ssnForm from "./ssn-form.scss";

const styles = {
    app: app,
    buttons: buttons,
    creditCardForm: creditCardForm,
    errorMessage: errorMessage,
    ssnForm: ssnForm
};

@Component({
    selector: "becSsnForm",
    bindings: {
        onSsnChange: "&",
        ssn: "<",
        context: "<"
    },
    template: require("./ssn-form.html")
})
export class SsnFormController {
    static $inject = ["AppStateService", "InvoiceService", "MerchantService", "SessionService", "gettextCatalog"];
    private static countrySpecifiers;

    styles = styles;
    ssnText: string;
    onSsnChange;
    ssn;
    spec;
    context: "invoice" | "partpayment" = "invoice";
    showTestIdentities = false;

    constructor(
        public appState: AppStateService,
        public invoice: InvoiceService,
        public merchantService: MerchantService,
        public session: SessionService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) {
        if (!SsnFormController.countrySpecifiers) {
            SsnFormController.countrySpecifiers = [
                { index: Country.Sweden, placeholder: this.gettextCatalog.getString("YYYYMMDDXXXX") },
                { index: Country.Norway, placeholder: this.gettextCatalog.getString("DDMMYYXXXXX") },
                { index: Country.Finland, placeholder: this.gettextCatalog.getString("DDMMYYCXXXY") }
            ];
        }

        this.ssnText = this.gettextCatalog.getString("Social Security Number");
        this.spec = _.find(SsnFormController.countrySpecifiers,
        (item: any) => item.index === this.merchantService.Country);
    }

    $onInit() {
        if (this.session.isDemo()) {
            if (this.isCountrySweden) this.ssn = "191603019876";
            if (this.isCountryNorway) this.ssn = "01118100014";
            if (this.isCountryFinland) this.ssn = "010200A9618";
            this.onChange();
        }
    }

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }

    onChange = () => {
        this.onSsnChange({ ssn: this.ssn });
    };

    get ssnCountryMaxLength() {

        if(this.isCountrySweden) {
            return 13;
        }
        return 11;
    }

    get isCountrySweden() {
        return this.merchantService.IsCountry(Country.Sweden);
    }

    get isCountryNorway() {
        return this.merchantService.IsCountry(Country.Norway);
    }

    get isCountryFinland() {
        return this.merchantService.IsCountry(Country.Finland);
    }

    get inputType() {
        if(this.isCountryFinland) {
            return "text";
        }
        return "tel";
    }
}
