export class InitRequestService {
    static $inject: string[] = ["localStorageService"];
    constructor(private localStorageService) {}
    
    getDefaultRequest(): Server.checkoutrequest {
        return {
            language: this.localStorageService.get("bec-language") || "da-DK",
            instantcaptureamount: 0,
            capturemulti: false,

            customer: {
                email: "test2@test.dk",
                phonenumber: "",
                phonenumbercountrycode: "45"
            },

            order: {
                amount: 100000,
                vatamount: 20000,
                currency: "DKK",
                id: (Math.ceil(Math.random() * 1000000000)).toString(),

                lines: [
                    {
                        id: "article1",
                        linenumber: "1",
                        description: "Organic Vegetarian Pizza",
                        quantity: 2,
                        text: "A great Pizza",
                        unit: "pcs",
                        totalprice: 10000,
                        totalpricevatamount: 2500,
                        totalpriceinclvat: 12500,
                        unitprice: 5000,
                        unitpriceinclvat: 6250,
                        unitpricevatamount: 1250,
                        vat: 25
                    },
                    {
                        id: "article2",
                        linenumber: "2",
                        description: "Crispy Salt Chips",
                        quantity: 1,
                        text: "Too salty",
                        unit: "pcs",
                        totalprice: 46000,
                        totalpricevatamount: 11500,
                        totalpriceinclvat: 57500,
                        unitprice: 46000,
                        unitpriceinclvat: 57500,
                        unitpricevatamount: 11500,
                        vat: 25
                    },
                    {
                        id: "article3",
                        linenumber: "3",
                        description: "San Miguel Beer",
                        quantity: 6,
                        text: "Let's chill",
                        unit: "pcs",
                        totalprice: 24000,
                        totalpricevatamount: 6000,
                        totalpriceinclvat: 30000,
                        unitprice: 4000,
                        unitpricevatamount: 1000,
                        unitpriceinclvat: 5000,
                        vat: 25
                    }
                ],
                billingaddress: {
                    att: "",
                    firstname: "Approved08",
                    lastname: "Sweden08",
                    street: "MELLANGATAN 2",
                    zip: "53431",
                    city: "VARA",
                    country: "SWE"
                },
                shippingaddress: {
                    att: "",
                    firstname: "Approved08",
                    lastname: "Sweden08",
                    street: "MELLANGATAN 2",
                    zip: "53431",
                    city: "VARA",
                    country: "SWE"
                }
            },
            url: {
                accept: `${location.origin}/demo?status=accept`,
                immediateredirecttoaccept: 0,
                decline: `${location.origin}/demo?status=decline`,
                callbacks: [
                    {
                        url: "http://www.example.org"
                    }
                ]
            },
            subscription: {
                action: "none",
                description: null, 
                reference: null
            },
            securitylevel: null,
            securityexemption: null
        };
    }

    getInvoiceAddressFinland():Server.address {
        return {
            att: "",
            firstname: "Approved0P",
            lastname: "Finland0P",
            street: "Kluuvikatu 16",
            zip: "1510",
            city: "VANTAA",
            country: "FIN" 
        }
    }

    getInvoiceAddressNorway():Server.address {
        return {
            att: "",
            firstname: "Approved08",
            lastname: "Norway49",
            street: "RADARVEIEN 22",
            zip: "1152",
            city: "OSLO",
            country: "NOR" 
        }
    }

    getInvoiceAddressDenmark():Server.address {
        return {
            att: "",
            firstname: "Approved08",
            lastname: "Denmark01",
            street: "Havnegade 87",
            zip: "7600",
            city: "Struer",
            country: "DNK" 
        }
    }


    get3DRequest() {
            
    }


}