import * as _ from "underscore";

import styles from "./radio-button.scss";

export class BecRadioGroup {
    static $inject = [];
    restrict = "E";
    require = ["becRadioGroup", "ngModel"];
    controller = BecRadioGroupController;

    link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs, ngModel: Array<ng.INgModelController>) => {
        element.attr({
            "role": "radiogroup",
            "tabindex": element.attr("tabindex") || "0"
        });

        const becRadioGroupController: BecRadioGroupController = ngModel[0] as any;
        const ngModelController = ngModel[1];
        const formElement = element.closest("form");

        becRadioGroupController.ngModelController = ngModelController;

        scope.$watch(() => ngModelController.$viewValue,
        (newValue, oldValue) => {
            becRadioGroupController.render();
        });

        element.attr("id", element.find("bec-radio-group-container").first().attr("id"));

        element.mousedown(event => {
            // if (event.target !== event.currentTarget) return;
            element.addClass("focus-by-mouse");
        });

        element.focus(event => {
            becRadioGroupController.focus();
        });

        element.blur(event => {
            element.removeClass("focus-by-mouse");
            becRadioGroupController.blur();
        });

        element.keydown(onKeyDown);

        function onKeyDown(event: JQueryEventObject) {
            if (event.target !== event.currentTarget) return;

            switch (event.which) {
                // Up, left arrows
                case 37: case 38: {
                    event.preventDefault();
                    becRadioGroupController.selectPreviousOption();
                    break;
                }

                // Down, right arrows
                case 39: case 40: {
                    event.preventDefault();
                    becRadioGroupController.selectNextOption();
                    break;
                }

                // Enter
                case 13: {
                    if (element.is(":focus")) event.preventDefault();
                    if (formElement) {
                        formElement.triggerHandler("submit");
                    }
                    break;
                }
            }
        }
    };
}

export interface IRadioButtonRenderable {
    element: ng.IAugmentedJQuery;
    render: Function;
}

export interface ILinked<T> {
    item: T;
    previous: ILinked<T>;
    next: ILinked<T>;
}

export class BecRadioGroupController {
    ngModelController: ng.INgModelController;
    radioButtonRenderables: Array<ILinked<IRadioButtonRenderable>> = [];
    hasFocus = false;

    addRadioButton(radioButtonRenderable: IRadioButtonRenderable) {
        const firstItem = this.radioButtonRenderables[0] || null;
        const lastItem = this.radioButtonRenderables[this.radioButtonRenderables.length - 1] || null;

        const newItem = {
            item: radioButtonRenderable,
            previous: lastItem,
            next: firstItem
        };

        if (!newItem.previous) newItem.previous = newItem;
        if (!newItem.next) newItem.next = newItem;

        newItem.next.previous = newItem;
        newItem.previous.next = newItem;

        this.radioButtonRenderables.push(newItem);
    }

    removeRadioButton(radioButtonRenderable: IRadioButtonRenderable) {
        const item = _.find(this.radioButtonRenderables, rBR => rBR.item === radioButtonRenderable);

        if (!item) return;

        item.previous.next = item.next;
        item.next.previous = item.previous;

        return _.reject(this.radioButtonRenderables, rBR => rBR === item);
    }

    render() {
        this.radioButtonRenderables.map(
            radioButtonRenderable => radioButtonRenderable.item.render()
        );
    }

    getCurrentlySelectedOrDefault(): ILinked<IRadioButtonRenderable> {
        const selected = _.find(this.radioButtonRenderables, radioButtonRenderable =>
            this.ngModelController.$viewValue == radioButtonRenderable.item.element.attr("value")
        );

        if (selected) return selected;

        return this.radioButtonRenderables[0];
    }

    selectNextOption() {
        const currentItem = this.getCurrentlySelectedOrDefault();

        this.ngModelController.$setViewValue(currentItem.next.item.element.attr("value"));
        this.ngModelController.$render();
    }

    selectPreviousOption() {
        const currentItem = this.getCurrentlySelectedOrDefault();

        this.ngModelController.$setViewValue(currentItem.previous.item.element.attr("value"));
        this.ngModelController.$render();
    }

    focus() {
        this.hasFocus = true;
        this.getCurrentlySelectedOrDefault().item.element.addClass(styles.focus);
    }

    blur() {
        this.hasFocus = false;
        this.getCurrentlySelectedOrDefault().item.element.removeClass(styles.focus);
    }
}

export class BecRadioButton {
    static $inject = [];
    restrict = "E";
    require = ["^becRadioGroup"];
    transclude = true;

    template = `
        <bec-radio-button-container role="radio">
            <bec-radio-button-outer></bec-radio-button-outer>
            <bec-radio-button-inner></bec-radio-button-inner>
        </bec-radio-button-container>
        <bec-radio-button-label ng-transclude></bec-radio-button-label>
    `;

    link(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes,
        ngModel: Array<ng.INgModelController>,
        transclude: ng.ITranscludeFunction
    ) {
        // Remove the aria-checked from the root directive element as it's unsupported
        // We add it later to the containerElement
        scope.$watch(
            () => element.attr("aria-checked"),
            () => element.removeAttr("aria-checked")
        );

        const containerElement = element.find("bec-radio-button-container");
        const labelElement = element.find("bec-radio-button-label");
        const radioGroupElement = element.closest("bec-radio-group");

        const id = Math.random().toString(36).substring(2);
        const radioButtonRenderable = { element, render };
        const labelId = `label_${id}`;
        const containerId = `radio_${id}`;

        containerElement.attr("id", containerId);
        containerElement.attr("aria-labelledby", labelId);
        labelElement.attr("id", labelId);

        attrs.$observe("ariaLabelledby", (value: string) => {
            element.removeAttr("aria-labelledby");
            containerElement.attr("aria-labelledby", value);
        });

        const becRadioGroupController: BecRadioGroupController = ngModel[0] as any;

        function render() {
            if (becRadioGroupController.ngModelController &&
                becRadioGroupController.ngModelController.$viewValue == attrs["value"]) {
                element.addClass(styles.checked);
                containerElement.attr("aria-checked", "true");
                radioGroupElement.attr("aria-activedescendant", containerId);

                if (becRadioGroupController.hasFocus) {
                    element.addClass(styles.focus);
                }
            } else {
                element.removeClass(styles.checked);
                containerElement.attr("aria-checked", "false");
                element.removeClass(styles.focus);
            }
        }

        attrs.$observe("value", render);
        becRadioGroupController.addRadioButton(radioButtonRenderable);

        element.click(
            (event: JQueryEventObject) => {
                becRadioGroupController.ngModelController.$setViewValue(attrs["value"]);
                becRadioGroupController.ngModelController.$commitViewValue();
            }
        );

        element.on("$destroy", () => becRadioGroupController.removeRadioButton(radioButtonRenderable));
    };
}

export class BecNoRadioButton extends BecRadioButton {
  template = `
      <bec-radio-button-label ng-transclude></bec-radio-button-label>
  `;
}
