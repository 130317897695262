import * as _ from "underscore";
import { Component, FeeService, PaymentCardService, SessionService, WindowStateService } from "../../../core/index";

import creditCardForm from "./credit-card-form.scss";
import input from "../../../shared/components/input/input.scss";
import base from "../../../styles/base.scss";
import app from "../../../app.scss";

const styles = {
    creditCardForm: creditCardForm,
    input: input,
    base: base,
    app: app
};

@Component({
    selector: "becCreditCardForm",
    bindings: {
        "onUpdate": "&",
        "onToggleDisplayTestCards": "&",
        "paymentcard": "<"
    },
    transclude: true,
    require:
    {
        form: "^form"
    },
    template: require("./credit-card-form.html")
})
export class CreditCardFormCtrl {
    static $inject = [
        "PaymentCardService",
        "SessionService",
        "$timeout",
        "FeeService",
        "WindowStateService",
        "$window"
    ];

    styles = styles;
    onUpdate: (PaymentCard) => void;
    onToggleDisplayTestCards: () => void;
    paymentcard;
    inputPaymentcard;
    cardtype: string = "maestro";

    constructor(
        private paymentCardService: PaymentCardService,
        public session: SessionService,
        private $timeout: ng.ITimeoutService,
        public feeService: FeeService,
        private windowService: WindowStateService,
        private $window: ng.IWindowService
    ) {}

    $onInit() {
        if (this.session.isDemo()) {
            const thisYear = (new Date().getFullYear() + 1).toString();
            const nextYearShort = parseInt(thisYear.substring(thisYear.length - 2, thisYear.length));

            this.paymentcard = {
                cardnumber: "4154210000000001",
                cvc: "000",
                expiry: { month: 5, year: nextYearShort },
                cardholder: { name: "John Doe" }
            };

            this.onChange();
        }
    }

    onChange() {
        this.onUpdate({ value: this.paymentcard });
    }

    userMustSelectCard() {
        return this.feeService.paymentTypes
            && this.feeService.paymentTypes.length > 1;
    }

    getFriendlierPaymentTypeDisplayName(displayName: string): string {
        return displayName.replace(/^(.*) \(.*$/, "$1");
    }

    set selectedPaymentType(value: number | string) {
        if (typeof value === "string") value = parseInt(value, 10);

        this.feeService.selectedPaymentType = _.find(
            this.feeService.paymentTypes,
            paymentType => paymentType.id === value
        );
    }

    get selectedPaymentType(): number | string {
        try {
            return this.feeService.selectedPaymentType.id;
        }
        catch (error) {
            return null;
        }
    }
}
