import { Component } from "../../../core/index";

import app from "../../../app.scss";
import invoicingName from "./invoicing-name.scss";

const styles = {
    app: app,
    invoicingName: invoicingName
};

@Component({
    selector: "becInvoicingName",
    template: require("./invoicing-name.html"),
    bindings: { name: "<", ssn: "<", onDeleteCustomer: "&", isReturning: "<", context: "<" }
})
export class InvoicingNameComponent {
    onDeleteCustomer;
    styles = styles;
    context: "invoice" | "partpayment" = "invoice";

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }
}
