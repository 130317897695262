import { DeltaFoxService } from "../../core/index";


export class DeltaFox {
    restrict = "A";
    controller = DeltaFoxController;
    controllerAs = "$ctrl";
    template = ``;
}


export class DeltaFoxController {
    //[BOC-3364] Removes ThreatMetrix

    //static $inject = ["$rootScope"];

    //static TagsJsBaseUrl = "https://deltafox0682.bambora.com/fp/tags.js";
    //static orgId = "1fu9nc10";

    //constructor($rootScope: ng.IRootScopeService) {
    //    $rootScope.$on(DeltaFoxService.NewDFIdBroadcaseIdentifier, (event, args: string) => {
    //        if (args && args.length >= 32) {
    //            this.addTagsJs(args);
    //        }
    //    });
    //}

    //addTagsJs(id: string) {
    //    const dfc = DeltaFoxController;
    //    const script = document.createElement("script");
    //    script.type = "text/javascript";
    //    script.src = `${dfc.TagsJsBaseUrl}?org_id=${dfc.orgId}&session_id=${id}`;
    //    document.getElementsByTagName("head")[0].appendChild(script);
    //}
}