import { AppStateService } from "../../core/index";


export class BecOverflowWhenLoading {
    restrict = "A";

    static $inject = ["AppStateService"];

    constructor(private appState: AppStateService) { }
        
    link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs) => {
        var timeout: number;
        scope.$watch(() => this.appState.isFullScreenLoading && !this.appState.showDemoLoader, newValue => {
            if (newValue) {
                element.css("overflow-y", "hidden");
                element.scrollTop(0);
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => element.css("overflow-y", ""), 300) as any;
            }
        });
    };
}