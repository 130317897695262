import {
    Component,
    SessionService,
    WindowStateService,
    FeeService,
    CustomerService
} from "../../core/index";

import styles from "./invoice-accept.scss";

@Component({
    selector: "becInvoiceAccept",
    template: require("./invoice-accept.html")
})
export class InvoiceAcceptController {
    static $inject = [
        "SessionService",
        "gettextCatalog",
        "$interval",
        "WindowStateService",
        "FeeService",
        "CustomerService"
    ];

    styles = styles;

    constructor(
        public session: SessionService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private $interval: ng.IIntervalService,
        public windowState: WindowStateService,
        public fee: FeeService,
        public customer: CustomerService
    ) {
        session.isAuthorized = true;

        if (session.secondsTillTimeOfDelayedRedirectToAccept) {
            const intervalPromise = $interval(
                () => { if (!session.secondsTillTimeOfDelayedRedirectToAccept) $interval.cancel(intervalPromise); },
                1000
            );
        }
    }

    showDetails = false;

    toggleDetails() {
        this.showDetails = !this.showDetails;
        const showingDetails = this.showDetails;
        window.setTimeout(function ()
        {
            let buttonId = showingDetails ? "acceptHideDetailsButton" : "acceptShowDetailsButton";
            document.getElementById(buttonId).focus();
        }, 0);

    }

    onBackPressed($event) {
        this.session.cancel($event);
    }

    get merchantName() {
        return this.session.merchant.name;
    }

    get invoiceProvider() {
        return "Walley";
    }

    get customerEmail() {
        return this.customer.details.email;
    }

    get immediateRedirectToAccept() {
        return this.session.immediateRedirectToAccept;
    }

    hasOrderLines(): boolean {
        return this.session.order.lines.length > 0 && !this.windowState.isInline();
    }

    get countDownLabel(): string {
        return this.gettextCatalog.getPlural(
            this.session.secondsTillTimeOfDelayedRedirectToAccept,
            "Redirecting in {{secondsTillTimeOfDelayedRedirectToAccept}} second...",
            "Redirecting in {{secondsTillTimeOfDelayedRedirectToAccept}} seconds...",
            { secondsTillTimeOfDelayedRedirectToAccept: this.session.secondsTillTimeOfDelayedRedirectToAccept }
        )
    }
}
