import * as _ from "underscore";
import { States } from "../../app.states";
import {
    Component,
    SessionService,
	AppStateService,
	ErrorService
} from "../../core/index";

import landing from "./landing.scss";

const styles = {
	landing: landing
};

@Component({
    selector: "becPaymentRequestLanding",
	template: require("./landing.html")
})
export class LandingComponent  {
	termsAccepted: boolean = false;
	styles = styles;
	paymentRequestSession: Server.setpaymentrequestsessionresponse;
	test: string;

	static $inject: string[] = [
        "ErrorService",
        "SessionService",
        "AppStateService",
		"$stateParams",
		"gettextCatalog"
    ];
	constructor(
		private errorService: ErrorService,
		public session: SessionService,
        private appStateService: AppStateService,
		private $stateParams,
		private gettextCatalog: ng.gettext.gettextCatalog,
	) {
		if (this.hasError) {
			switch (errorService.errorMessage) {
				case "notfound":
					errorService.errorMessage = this.gettextCatalog.getString("The payment request could not be found");
					break;
				case "paymentrequestexpired":
					errorService.errorMessage = this.gettextCatalog.getString("The payment request has expired");
					break;
				case "paymentrequestclosed":
					errorService.errorMessage = this.gettextCatalog.getString("The payment request has been closed");
					break;
			}
		}
	}

	$onInit() {
		this.paymentRequestSession = this.$stateParams["paymentRequestSession"];
	}

	submit() {
		if (this.termsAccepted)
			this.appStateService.switchState(States.Session.Start.name);
	}

	hasError() {
		return this.errorService.errorContext === States.PaymentRequest.name;
	}
}
