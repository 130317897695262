import * as angular from "angular";

import { getFactoryFor } from "./index";

import * as animations from "./animations/index";
import * as payment from "./payment/index";
import * as session from "./session/index";
import * as state from "./state/index";
import * as utilities from "./utilities/index";

import "angular-ui-router";
import "angular-gettext";

export const moduleName = "core";
export const module = angular.module(moduleName, [
    "ui.router",
    "gettext"
]);

module
    // Animations
    .animation(".animate-container-height", animations.animateContainerHeight)
    .animation(".animate-height", animations.animateHeight)
    .animation(".animate-heightAndFade", animations.animateHeightAndFade)
    .animation(".animate-height-bounce", animations.animateHeightBounce)
    .animation(".animate-slide-list", animations.animateSlideList)

    // Payment services
    .service("AuthorizeService", payment.AuthorizeService)
    .service("DeltaFoxService", payment.DeltaFoxService)
    .service("ExternalAuthService", payment.ExternalAuthService)
    .service("FeeService", payment.FeeService)
    .service("PaymentCardService", payment.PaymentCardService)

    // Session services
    .service("CustomerService", session.CustomerService)
    .service("ErrorService", session.ErrorService)
    .service("ExpressCheckoutService", session.ExpressCheckoutService)
    .service("LanguageService", session.LanguageService)
    .service("MerchantService", session.MerchantService)
    .service("SessionService", session.SessionService)

    // State services
    .service("AccessibilityService", state.AccessibilityService)
    .service("AppStateService", state.AppStateService)
    .provider("ConfigReaderService", state.ConfigReaderService)
    .service("StateHelperService", state.StateHelperService)
    .service("UrlService", state.UrlService)
    .service("WindowStateService", state.WindowStateService);