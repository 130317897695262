import { AppStateService, Component, ConfigReaderService, ExternalAuthService } from "../../core/index";

import app from "../../app.scss";

import { default as IconWarningMessage } from 'icons/icon-warning-message.svg';

const styles = {
  app: app
};

@Component({
  selector: "becWait",
  template: `
        <div class="${styles.app.errorMark}" ng-show="$ctrl.hasError">
            <bec-svg class="icon ${styles.app.errorIcon}">${IconWarningMessage}</bec-svg>
        </div>

        <h2 class="${styles.app.errorText}">{{$ctrl.errorText}}</h2>
    `
})
export class Wait {
  static $inject = [
    "AppStateService",
    "$stateParams",
    "$window",
    "ConfigReaderService",
    "ExternalAuthService",
    "gettextCatalog"
  ];

  constructor(
    private appStateService: AppStateService,
    private $stateParams,
    private _$window: ng.IWindowService,
    private configReaderService: ConfigReaderService,
    private externalAuthService: ExternalAuthService,
    private gettextCatalog: ng.gettext.gettextCatalog) { }

  hasError = false;
  errorText = null;

  $onInit() {
    this.appStateService.startFullScreenLoadingForVerification();

    const url = this.$stateParams.epayreturn;

    const parsedUrl = new URL(url);

    if (this.configReaderService.allowedWaitRedirectHosts.indexOf(parsedUrl.host) === -1) {
      this.hasError = true;
      this.errorText = this.gettextCatalog.getString("An unknown error occurred.");
      this.appStateService.cancelLoading();

    } else {
      const pathArray = parsedUrl.pathname.split("/");
      const pollToken = pathArray[pathArray.length - 1];

      const pollUrl = this.configReaderService.authorizePollEndpoint + pollToken;

      const authorizeResponse: Server.authorizeresponse = {
        authorizeresponseurl: pollUrl,
        accepturl: null,
        declineurl: null,
        meta: {
          result: true,
          action: {
            code: null,
            source: null,
            type: null
          },
          message: {
            enduser: null,
            merchant: null
          },
          paging: null
        },
        method: "poll",
        parameters: null
      };

      this.externalAuthService.pollAuthorize(authorizeResponse, true, true)
        .then(validationResponse => {
          if (validationResponse.meta.result) {
            if (validationResponse.authorizeresponseurl !== null) {
              this._$window.location.replace(validationResponse.authorizeresponseurl);
              return;
            }
          }

          setTimeout(() => this.appStateService.reload(), 1000);
        }).catch(validationResponse => {
          this.hasError = true;
          this.errorText = this.gettextCatalog.getString("An unknown error occurred.");
          this.appStateService.cancelLoading();
        });
    }
  }
}
