import * as angular from "angular";
import {
    Component,
    FeeService,
    PaymentCard
} from "../../core/index";

import demoCards from "./demo-cards.scss";
import paymentMethods from "../../shared/components/payment-methods/payment-methods.scss";
import buttons from "../../styles/buttons.scss";

const styles = {
    demoCards: demoCards,
    paymentMethods: paymentMethods,
    buttons: buttons
};

@Component({
    selector: "becDemoCards",
    template: `
        <button
            type="button"
            class="${styles.buttons.debugContainerButton}"
            ng-repeat="card in $ctrl.cards"
            ng-click="$ctrl.selectCard(card.card)"
            bec-focus-by-mouse>

            <bec-svg
                class="${styles.paymentMethods.paymentCard}"
                bec-svg-src="{{card.svg}}">
            </bec-svg>

            {{card.name}}
        </button>
    `,
    bindings: { onCardSelected: "&" },
    require: { ngModel: "ngModel" }
})
export class DemoCards {
    static $inject = [
        "$window",
        "$stateParams",
        "FeeService"
    ];

    public constructor(
        private $window,
        private $stateParams,
        private feeService: FeeService
    ) {
        const thisYear = (new Date().getFullYear() + 1).toString();
        const nextYearShort = parseInt(thisYear.substring(thisYear.length - 2, thisYear.length));

        this.cards = [
            {
                name: "Visa",
                svg: "paymentcard-visa",
                card: {
                    cardnumber: "4154210000000001",
                    cvc: "000",
                    expiry: { month: 5, year: nextYearShort },
                    cardholder: { name: "John Doe"}
                }
            },
            {
                name: "Mastercard",
                svg: "paymentcard-mastercard",
                card: {
                    cardnumber: "5156230000000004",
                    cvc: "000",
                    expiry: { month: 5, year: nextYearShort },
                    cardholder: { name: "John Doe"}
                }
            },
            {
                name: "Visa/Dankort",
                svg: "paymentcard-dankort",
                card: {
                    cardnumber: "4571740000000002",
                    cvc: "000",
                    expiry: { month: 5, year: nextYearShort },
                    cardholder: { name: "John Doe"}
                }
            }
        ];
    }

    styles = styles;
    cards: Array<any> = [];
    onCardSelected: () => void;
    ngModel: ng.INgModelController;

    $onInit() {
        if (this.$stateParams["prefilled-demo-card"] === "1") {
            this.selectCard(this.cards[0].card);
        }
    }

    selectCard(card: PaymentCard) {
        this.ngModel.$setViewValue(angular.copy(card));
        this.ngModel.$commitViewValue();
        this.feeService.setCardNumber(card.cardnumber);
        this.onCardSelected();
    }
}
