export class UrlService {

  static $inject = [
    "$location", "$sessionStorage"
  ];

  acceptUrl: string = null;
  declineUrl: string = null;
  authorizeParams: any = null;

  public constructor(private $location: ng.ILocationService,
    private $sessionStorage: any) {
  }

  setUrlFromWalletResponse(redirectUrl: string, sessionToken: string) {
    this.acceptUrl = this.convertUrl(this.acceptUrl, redirectUrl);
    this.$sessionStorage[sessionToken + "acceptUrl"] = this.acceptUrl;
  }

  setUrlFromAuthorize(res: Server.authorizeresponse, sessionToken: string) {
    if (res.meta && res.meta.action.type && res.meta.action.type.indexOf("success") == 0) { // From authorize
      this.acceptUrl = this.convertUrl(this.acceptUrl, res.authorizeresponseurl);
      this.$sessionStorage[sessionToken + "acceptUrl"] = this.acceptUrl;
    }
  }

  getAuthorizeParams(sessionToken: string) {
    let sessionStorageUrl = this.$sessionStorage[sessionToken + "authorizeParams"];
    if (sessionStorageUrl) {
      return sessionStorageUrl;
    }

    if (this.authorizeParams === null && this.acceptUrl !== null) {
      this.authorizeParams = this.setAuthorizeParams(this.acceptUrl, sessionToken);
      this.$sessionStorage[sessionToken + "authorizeParams"] = this.authorizeParams;
    }

    return this.authorizeParams;
  }

  setAuthorizeParams(url: string, sessionToken: string): void {
    this.authorizeParams = this.getUrlParams(url);
    this.$sessionStorage[sessionToken + "authorizeParams"] = this.authorizeParams;

    return this.authorizeParams;
  }

  setInitialUrls(session: Server.checkoutresponse, sessionToken: string) : void {
    this.acceptUrl = session.accepturl;
    this.declineUrl = session.declineurl;
    let sessionStorageUrl = this.$sessionStorage[sessionToken + "acceptUrl"];
    if (sessionStorageUrl) {
      this.acceptUrl = sessionStorageUrl; // from 3ds
    } else if (this.$location.absUrl().indexOf("txnid") > -1) {
      this.acceptUrl = this.convertUrl(this.acceptUrl, this.$location.absUrl()); // from 3ds

      if (this.acceptUrl === null)
      {
        this.setAuthorizeParams(this.convertUrl(this.$location.absUrl(), this.$location.absUrl()), sessionToken);
      }

      this.$sessionStorage[sessionToken + "acceptUrl"] = this.acceptUrl;
    } else if (session.authorizeresponseurl && session.status === "authorized") {
      this.acceptUrl = this.convertUrl(this.acceptUrl, session.authorizeresponseurl); // Refresh after purchase complete
      this.$sessionStorage[sessionToken + "acceptUrl"] = this.acceptUrl;
    }
  }

  convertUrl(acceptUrl: string, responseUrl: string) {
    if (acceptUrl == null) {
      return null;
    }

    const responseA: HTMLAnchorElement = document.createElement("a");
    const acceptA: HTMLAnchorElement = document.createElement("a");

    acceptA.href = acceptUrl;
    responseA.href = responseUrl;

    const responseSearch = this.getQueryObject(responseA);
    const resultSearch = {};

    for (let attrname in responseSearch) {
      resultSearch[attrname] = responseSearch[attrname];
    }

    const result = document.createElement("a");
    result.href = acceptUrl;
    result.search = this.queryParams(resultSearch);
    return result.href;
  }

  private getQueryObject(anchor) {
    var queryString = {};
    anchor.search.replace(
      new RegExp("([^?=&]+)(=([^&]*))?", "g"), ($0, $1, $2, $3) => { queryString[$1] = $3; }
    );

    return queryString;
  }

  queryParams(source) {
    const array = [];

    for (const key in source) {
      array.push(encodeURIComponent(key) + "=" + encodeURIComponent(source[key]));
    }

    return array.join("&");
  }

  public getUrlParams<T = any>(search: string): T {
    const startPos = search.indexOf("?") + 1;
    const hashIndex = search.indexOf("#");
    const endPos = hashIndex === -1 ? undefined : hashIndex;

    const hashes: Array<string> = search.slice(startPos, endPos).split("&");

    return hashes.reduce((params: any, hash) => {
      let [key, val] = hash.split("=");
      return { ...params, ...{ [decodeURIComponent(key)]: decodeURIComponent(val) } };
    }, {});
  }
}
