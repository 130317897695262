import { Component, SessionService } from "../../core/index";


@Component({
    selector: "becPartPaymentDetails",
    template: `
        <span ng-if="$ctrl.format == 'long' && !$ctrl.session.isDemo()" >{{$ctrl.getPaymentOptionDescription()}}<br /><br /></span>
        <span ng-if="$ctrl.format == 'long' && !$ctrl.session.isDemo()" >{{$ctrl.getHeadline()}}<br /></span>
        <span ng-if="$ctrl.format == 'long' && !$ctrl.session.isDemo()" >{{$ctrl.getPaymentOptionDetailsStaticString()}}<br /></span>
        <span ng-if="$ctrl.format != 'long'" aria-label="{{$ctrl.getPaymentOptionsShortStringTextual()}}" >{{$ctrl.getPaymentOptionShortString()}}</span>
    `,
    bindings: {
        invoiceOption: "<",
        format: "@"
    }
})
export class PartPaymentDetails {
    static $inject = [
        "gettextCatalog",
        "numberToLocaleFilter",
        "toMajorFilter",
        "becFormatCurrencyByCultureFilter",
        "SessionService"
    ];

    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private numberToLocaleFilter,
        private toMajorFilter,
        private becFormatCurrencyByCultureFilter,
        public session: SessionService
    ) {}

    invoiceOption: Server.paymentoption;

    getPaymentOptionDetailsString() {
        // const details = this.getPaymentOptionDetails(this.invoiceOption);
        // if(!details) return "";

        // let detailsString = this.gettextCatalog.getString("When you choose to pay off your purchase in {{creditTime}} months, {{interestPercentage}}% credit interest," +
        // " setup fee {{originationFee}} and notification fee {{initialNotificationFee}} SEK per month. The effective annual interest rate is {{annualInterestPercentage}}% for a debt of {{purchageAmount}}" +
        // " which is repaid in {{creditTime}} months. The total repayment amount will then be {{totalRepayAmount}} ({{initialMonthlyAmount}} the first month and then {{monthlyAmount}} per month).",
        // {
        //     creditTime: details.creditTime,
        //     interestPercentage: details.interestPercentage,
        //     originationFee: details.originationFee,
        //     initialNotificationFee: details.initialNotificationFee,
        //     annualInterestPercentage: details.annualInterestPercentage,
        //     purchageAmount: details.purchageAmount,
        //     totalRepayAmount: details.totalRepayAmount,
        //     initialMonthlyAmount: details.initialMonthlyAmount,
        //     monthlyAmount: details.monthlyAmount
        // });

        return this.getPaymentOptionDetailsStaticString();
    }

    getHeadline() {
        return this.gettextCatalog.getString("Example");
    }

    getPaymentOptionDescription() {
        return this.invoiceOption ? this.invoiceOption.description : "";
    }

    getPaymentOptionDetailsStaticString() {
        let detailsString = this.gettextCatalog.getString("Annual interest rate will be 15,09% on a debt at 10 000 SEK that is repaid over 12 months." +
        " Total amount to pay will be 10 763 SEK (1 163 SEK first month and thereafter 868 SEK per month).");

        return detailsString;
    }



    getPaymentOptionShortString() {
        const details = this.getPaymentOptionDetails(this.invoiceOption);
        return details
            ? this.gettextCatalog.getString("{{monthlyAmount}}/month - {{creditTime}} months",
            {
                creditTime: details.creditTime,
                monthlyAmount: details.monthlyAmount
            })
            : "";
    }

    getPaymentOptionsShortStringTextual() {
        const details = this.getPaymentOptionDetails(this.invoiceOption);
        return details
            ? this.gettextCatalog.getString("{{monthlyAmount}} per month for {{creditTime}} months",
            {
                creditTime: details.creditTime,
                monthlyAmount: details.monthlyAmount
            })
            : "";
    }


    getPaymentOptionDetails(paymentOption: Server.paymentoption) {
        if (paymentOption && paymentOption.paymentdetails) {
            return {
                creditTime: paymentOption.paymentdetails.credittime,
                interestPercentage: paymentOption.paymentdetails.interestpercentage ? paymentOption.paymentdetails.interestpercentage : 0,
                originationFee: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(paymentOption.paymentdetails.originationfee)),
                initialNotificationFee: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(paymentOption.paymentdetails.initialnotificationfee)),
                annualInterestPercentage: paymentOption.paymentdetails.annualinterestpercentage ? paymentOption.paymentdetails.annualinterestpercentage : 0,
                purchageAmount: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(this.session.amount)),
                totalRepayAmount: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(paymentOption.paymentdetails.totalamount + 100)),//missing this value
                initialMonthlyAmount: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(paymentOption.paymentdetails.monthlyamount + paymentOption.paymentdetails.originationfee)),
                monthlyAmount: this.becFormatCurrencyByCultureFilter(this.toMajorFilter(paymentOption.paymentdetails.monthlyamount)),
                description: paymentOption.description,
            };

        }
        return null;
    }
}
