import * as _ from "underscore";
import { Component, ErrorService } from "../../../core/index";
import { States } from "../../../app.states";
import { EkspresBankService } from "../index";

import buttons from "../../../styles/buttons.scss";
import ekspresbankstart from "./ekspres-bank-start.scss";

const styles = {
  buttons: buttons,
  ekspresbankstart: ekspresbankstart
};

@Component({
  selector: "becEkspresBankStart",
  template: require("./ekspres-bank-start.html")
})
export class EkspresBankStart {
  styles = styles;

  static $inject = [
    "EkspresBankService", "ErrorService"
  ];

  constructor(
    private ekspresBankService: EkspresBankService,
    private errorService: ErrorService
  ) {
  }

  continue() {
    this.ekspresBankService.authorize();
  }

  hasError() {
    return this.errorService.errorContext === States.Session.EkspresBank.name;
  }
}
