import { AppStateService, Component } from "../../../core/index";

import styles from "./giraf-loader.scss";

@Component({
    selector: "becGirafLoader",
    template: require("./giraf-loader.html"),
})
export class GirafLoader {
    static $inject = [
        "AppStateService",
        "$rootScope",
        "$timeout",
        "gettextCatalog"
    ];

    styles = styles;

    constructor(
        private appState: AppStateService,
        private $rootScope: ng.IRootScopeService,
        private $timeout: ng.ITimeoutService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) {
        $rootScope.$watch(() => this.isLoading, (newValue, oldValue) => {
            newValue ? $("body").addClass("isLoading") : $("body").removeClass("isLoading");

            if (newValue && !oldValue) {
                $timeout(() => $("#girafLoader").focus());
            }
        });
    }

    get isLoading() {
        return this.appState.isFullScreenLoading && !this.appState.showDemoLoader;
    }

    get girafMessage() {
        return this.appState.girafMessage;
    }

    get loadingMessage() {
        return this.gettextCatalog.getString("Loading...");
    }

    get showLogoLoader() {
        return this.appState.showLogoLoader;
    }

    get logoLocation() {
        return "/assets/images/loader/" + this.appState.loaderType + ".svg";
    }

    onLoading(value: boolean) {
        // $("#girafLoader").focus();
    }
}
