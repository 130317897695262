import { Component } from "../../../core/index";

import failmark from "./failmark.scss";
import icons from "../../../styles/icons.scss";

const styles = {
    failmark: failmark,
    icons: icons
};

@Component({
    selector: "becFailmark",
    template: `
        <div class="${styles.failmark.failmark} ${styles.failmark.animate}" bec-offset-broadcast="loader">
            <ng-transclude class="${styles.failmark.transclusionContainer}"></ng-transclude>

            <div class="${styles.failmark.leftContainer}">
                <div class="${styles.failmark.leftBorder}"></div>
            </div>

            <div class="${styles.failmark.rightContainer}">
                <div class="${styles.failmark.rightBorder}"></div>
            </div>
        </div>
    `,
    transclude: true
})
export class FailmarkComponent { }
