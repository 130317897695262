import * as _ from "underscore";
import { Component, ErrorService } from "../../../core/index";
import { States } from "../../../app.states";

import invoiceOptionSelector from "./invoice-option-selector.scss";
import panel from "../../../shared/components/panel/panel.scss";
import radioButton from "../../../shared/components/radio-button/radio-button.scss";

const styles = {
    invoiceOptionSelector: invoiceOptionSelector,
    panel: panel,
    radioButton: radioButton
};

@Component({
    selector: "becInvoiceOptionSelector",
    template: require("./invoice-option-selector.html"),
    bindings: {
        invoiceOptions              : "<",
        onOptionSelected            : "&",
        order                       : "<",
        creditTermsUrl              : "<",
        context                     : "<"
    }
})
export class InvoiceOptionSelector {
    invoiceOptions: Server.invoiceoptions;
    order: Server.order;
    onOptionSelected: (option: any) => void;
    selectedPartPaymentOption: Server.paymentoption;
    creditTermsUrl: String;
    styles = styles;
    context: "invoice" | "partpayment" = "invoice";

    static $inject = ["$filter", "gettextCatalog", "ErrorService"];

    constructor(private $filter: ng.IFilterService,
                private gettextCatalog: ng.gettext.gettextCatalog,
                private errorService: ErrorService) { }

    $onInit() {
        this.selectedPartPaymentOption = this.cheapestPartPaymentOption;
        this.selectPaymentModel(this.selectedPartPaymentOption);
    }

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }

    get invoicePaymentOption(): Server.paymentoption {
        const paymentOption = _.first<any>(
            _.select<any>(
                this.invoiceOptions.paymentoptions,
                item => item.type === "Invoice"
            )
        );
        return paymentOption;
    }


    get cheapestPartPaymentOption(): Server.paymentoption {
        return _.min<Server.paymentoption>(
            this.partPaymentOptions,
            paymentOption => paymentOption.paymentdetails.monthlyamount);

    }

    get partPaymentOptions(): Array<Server.paymentoption> {
        if (!this.invoiceOptions) return null;
        return this.sortPaymentOptions(_.select<any>(this.invoiceOptions.paymentoptions, item => item.type === "AnnuityInvoice" || item.type === "InterestFreeInvoice"));
    }

    private sortPaymentOptions(paymentOptions: Array<Server.paymentoption>) : Array<Server.paymentoption> {
        return _.sortBy(paymentOptions, option => option.paymentdetails.credittime);
    }


    selectPaymentModel(option: Server.paymentoption) {
        this.onOptionSelected({ option: option });
    }

    hasPartPaymentError(): boolean {
        return this.errorService.errorContext === States.Session.PartPayment.name;
    }

    get arrangementFeeLabel(): string {
        const arrangementFee = this.$filter<any>("becFormatCurrencyByCulture")(
            this.$filter<any>("toMajor")(
                this.selectedPartPaymentOption.paymentdetails.arrangementfee
            )
        );

        return this.gettextCatalog.getString(
            "An arrangement fee of {{arrangementFee}} is added to your first invoice.",
            { arrangementFee }
        );
    }
}
