import * as angular from "angular";


export function animateContainerHeight(): any {
    const bezier = [0.25, 0.8, 0.25, 1];
    const $containerElement = angular.element("#checkout");
    const animateIn = (element: JQuery, doneFn) => {
        doneFn();
    };

    const animateOut = (element: JQuery, doneFn) => {
        doneFn();

        $containerElement.css("overflow", "hidden");

        const totalContainerPadding = Math.abs($containerElement.height() - $containerElement.innerHeight());
        const height = element.height() + totalContainerPadding;
        const containerHeight = element.siblings("[ui-view]").height() + totalContainerPadding;
        const heightDifference = Math.abs(containerHeight - height);

        $containerElement
            .velocity("stop")
            .velocity(
                {
                    height: [containerHeight, height]
                },
                {
                    duration: heightDifference / Math.log(heightDifference) + 200,
                    complete: () => {
                        $containerElement.css("height", "");
                        $containerElement.css("overflow", "");
                        doneFn();
                    },
                    easing: bezier
                });
    };

    return {
        enter: animateIn,
        leave: animateOut,
        addClass: (element: JQuery, className: string, doneFn) => {
            className === "animate-container-height" ? animateIn(element, doneFn) : animateOut(element, doneFn);
        },
        removeClass: (element: JQuery, className: string, doneFn) => {
            className === "animate-container-height" ? animateOut(element, doneFn) : animateIn(element, doneFn);
        }
    };
}