import { AppStateService } from "../../core/index";


export class TestMode {
    restrict = "AE";
    template = require("./test-mode.html");
    replace = false;
    
    static $inject = ["AppStateService"];

    constructor(private appStateService: AppStateService) {}
    
    link = (scope) => {
        scope.$watch(() => {
            return this.appStateService.displayTestMode;
        },
        () => {
            scope.display = (this.appStateService.displayTestMode);
        });
    };
}