export class ShowOnParam {
    static $inject = ["$stateParams"];

    public constructor(private $stateParams) {
            
    }

         
    link = (scope, element, attrs) => {
             
        scope.showOnParam = this.$stateParams[attrs["becShowOnParam"]] === "1";
           
    };
}