import * as _ from "underscore";
import { cultures } from "@bambora/cultures";
import {
    ICustomerDetails,
    SessionService,    
} from "../core/index";
import { PhoneNumberService } from "../payment-card/index";
import { InvoiceService} from "../invoice/shared/index";


export const phoneNumberFilter = [
    "PhoneNumberService", (phoneService: PhoneNumberService) => {
        return (input: ICustomerDetails) => {
            // if (!input) return "";
            let phoneinfo = phoneService.parse(input.phonenumber, input.phonenumbercountrycode);
            return `+${phoneinfo.countryInfo.dialCode} ${phoneinfo.formatted}`;
        };
    }
];


export const cardNumberFilter = () => {
    return (input: string) => {
        return input ? (jQuery.payment as JQueryPayment.ExpressPayment).formatCardNumber(input).replace(/X/g, "*") : "";
    };
};

export const ssnFilter = [
    "InvoiceService", (invoiceService: InvoiceService) => {
        return (input: string) => {

            if (!input || input.length < 6) {
                return "";
            }
            let ssnSecurePartLength = invoiceService.ssnSecuredPartLength;            
            if (input.length <= 8) {             
                return input + "-" + Array(ssnSecurePartLength+1).join("X");
            }            
            return input.substr(0, input.length - ssnSecurePartLength) + "-" + Array(ssnSecurePartLength+1).join("X");
        };
    }
];



export const numberToLocaleFilter = [
    "SessionService", (session: SessionService) => {
        return (input: number | string, language?: string) => {
            if (!language) language = session.language || "en-GB";

            const localeData = _.find<any>(
                cultures,
                c => c.language.code.toLowerCase() === language.toLowerCase()
            );

            let num = input.toString();

            num = num.replace(".", localeData ? localeData.currency.format.decimalSeparator : ".");
            return num;
        };
    }
];


export const ccAmountPlaceholder = [
    "gettextCatalog", "SessionService", (gettextCatalog: ng.gettext.gettextCatalog, sessionService: SessionService) => {
        var translate = { gettextCatalog };
        return (input: string) => {

            let match = input.match(/([A-Z]{3})$/);
            let currency;
            if (match) {
                currency = match[0];
            }
            if (!currency || currency.length === 0 || !currency[0]) {
                return input;
            }

            let text = input.substr(0, input.length - 3);
            if (text !== "Amount in ") {
                return input;
            }
            try {
                let localCurrency = _.find<any>(
                    cultures,
                    c => c.language.code.toLowerCase() === sessionService.language.toLowerCase() &&
                        c.currency.isoSymbol === currency
                ).currency.isoSymbol;
                if (!localCurrency) {
                    localCurrency = currency;
                }
                let translatedString = translate.gettextCatalog.getString("Amount in {{currency}}", { currency: localCurrency }); 
                return translatedString;
            } catch (e) {
                return input;
            }
        };
    }
];