export function animateHeightBounce(): any {
    const bezier = [0.25, 0.8, 0.25, 1];

    const animateIn = (element: JQuery, doneFn) => {
        var height = element.outerHeight();
        element
            .velocity("slideDown",
            {
                duration: height / Math.log(height) + 300,
                complete: () => {
                    element.css("height", "");
                    element.children()
                            
                        .velocity("callout.pulse",
                        {
                            complete: () => {
                                doneFn();
                            }
                        });
                },
                easing: bezier,
                // display: "inline-block"
            });


    };

    const animateOut = (element: JQuery, doneFn) => {
        var height = element.outerHeight();
        element.velocity("slideUp",
        {
            duration: height / Math.log(height) + 300,
            complete: () => {
                element.css("height", "");
                doneFn();
            },
            easing: bezier
        });
    };

    return {
        enter: animateIn,
        leave: animateOut,
           
        addClass: (element: JQuery, className: string, doneFn) => {
            className === "animate-height-bounce" ? animateIn(element, doneFn) : animateOut(element, doneFn);
        },
        removeClass: (element: JQuery, className: string, doneFn) => {
            className === "animate-height-bounce" ? animateOut(element, doneFn) : animateIn(element, doneFn);
        }
    };
}