import { WalletService } from "../../../wallets/shared/wallet.service";
import { States } from "../../../app.states";
import {
    Component,
    AppStateService,
    SessionService
} from "../../../core/index";

import paymentMethodSelectors from "./payment-method-selectors.scss";
import buttons from "../../../styles/buttons.scss";

const styles = {
    paymentMethodSelectors: paymentMethodSelectors,
    buttons: buttons
};

@Component({
    selector: "becPaymentTypeSwitcher",
    template: `
        <div ng-if="$ctrl.paymentMethods && $ctrl.paymentMethods.length > 1">
            <button-group class="${styles.paymentMethodSelectors.buttonGroupWhite}" >
                <button type="button"
                    id="{{'paymentMethodButton_'+method.methodName}}"
                    ng-click="$ctrl.selectMethod(method)"
                    ng-repeat="method in $ctrl.paymentMethods"
                    ng-class="{ '${styles.paymentMethodSelectors.active}': ($ctrl.selectedMethod | lowercase) === (method.methodName | lowercase) }"
                    bec-focus-by-mouse>
                {{method.name}}
                </button>
            </button-group>
        </div>
    `
})
export class PaymentMethodSelectorController {
    static $inject = [
        "AppStateService",
        "SessionService",
        "WalletService",
        "gettextCatalog"
    ];

    constructor(
        private appState: AppStateService,
        private sessionService: SessionService,
        private walletService: WalletService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) {
        this.paymentMethods = [];

        if (this.sessionService.isPaymentCardAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Payment card"),
                state: States.Session.PaymentCard.Start.name,
                methodName: "paymentcard"
            });

        if (this.sessionService.isMobilePayAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("MobilePay"),
                state: States.Session.MobilePay.Start.name,
                methodName: "mobilepay"
            });

        if (this.sessionService.isVippsAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Vipps"),
                state: States.Session.Vipps.Start.name,
                methodName: "vipps"
            });

        if (this.sessionService.isApplePayAvailable &&
            this.walletService.isApplePayAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Apple Pay"),
                state: States.Session.ApplePay.Start.name,
                methodName: "applepay"
            });

        if (this.sessionService.isGooglePayAvailable &&
            this.walletService.isGooglePayAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Google Pay"),
                state: States.Session.GooglePay.Start.name,
                methodName: "googlepay"
            });

        if (this.sessionService.isInvoiceAvailable) {
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Invoice"),
                state: States.Session.Invoice.Start.name,
                methodName: "invoice"
            });
        }
        if (this.sessionService.isEkspresBankAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Ekspres Bank"),
                state: States.Session.EkspresBank.Start.name,
                methodName: "ekspresbank"
            });

        if (this.sessionService.isSwishAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Swish"),
                state: States.Session.Swish.Start.name,
                methodName: "swish"
            });
        if (this.sessionService.isViaBillAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("ViaBill"),
                state: States.Session.ViaBill.Start.name,
                methodName: "viabill"
            });
        if (this.sessionService.isNordeaEPaymentFIAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Direct Banking"),
                state: States.Session.NordeaEPaymentFI.Start.name,
                methodName: "nordeaepaymentfi"
            });
    }

    paymentMethods = [];
    visible = false;

    get selectedMethod() {
        return this.appState.currentPaymentMethod();
    }

    toggle = () => {
        this.visible = !this.visible;
    };

    hide = () => {
        this.visible = false;
    };

    selectMethod = (method) => {
        this.appState.switchState(method.state);
    };
}
