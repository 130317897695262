import * as angular from "angular";

import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as paymentRequest from "./index";

export const moduleName = "paymentrequest";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

// Components
addComponentToModule(module, paymentRequest.LandingComponent);