export function animateHeightAndFade(): any {

    var bezierCurve = [0.25, 0.8, 0.25, 1];

    var animateIn = (element: JQuery, doneFn) => {

        var height = element.outerHeight();
        var duration = height / Math.log(height) + 200;

        element.velocity("slideDown", { duration: duration, easing: bezierCurve })
            .velocity("fadeIn", { queue: false, duration: duration, easing: bezierCurve });
    };

    var animateOut = (element: JQuery, doneFn) => {

        var height = element.outerHeight();
        var duration = height / Math.log(height) + 200;

        element.velocity("slideUp", { duration: duration, easing: bezierCurve })
            .velocity("fadeOut", { queue: false, duration: duration, easing: bezierCurve });
    };

    return {
        enter: animateIn,
        leave: animateOut,
        addClass: (element: JQuery, className: string, doneFn) => {

            if (className !== "ng-hide") {

                return false;
            }

            animateOut(element, doneFn);
            return true;
        },
        removeClass: (element: JQuery, className: string, doneFn) => {

            if (className !== "ng-hide") {
                return false;
            }

            animateIn(element, doneFn);
            return true;
        }
    };
}