import { ExpiryInfo } from "../../../core/interfaces/clientPaymentCard";


export class PaymentCardExpiry {

    maxLength = 9;
    require = "ngModel";
    scope = {
        countrycode: "="
    };

    static $inject = ["$compile"];

    constructor(private $compile: ng.ICompileService) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {

        this.addHiddenFields(scope);

        ngModel.$formatters.push((value: ExpiryInfo): string => {
            if (!value) return "";
            return this.formatExpiryText(value.month + " / " + value.year);
        });

        ngModel.$parsers.push((value: string): ExpiryInfo => {
            if (!value) return null;
            var formatted = this.formatExpiryText(value);
            if (value != formatted) {
                ngModel.$setViewValue(formatted);
                ngModel.$render();
            }
            return jQuery.payment.cardExpiryVal(value);
        });

        ngModel.$validators.invalid = (modelValue, viewValue) => {
            if (! modelValue) return false;
            return jQuery.payment.validateCardExpiry(modelValue);
        };
    };

    private addHiddenFields(scope) {
        $("form").append(
            this.$compile(`
                <input
                    type="hidden"
                    name="expmonth"
                    autocomplete="cc-exp-month"
                    x-autocomplete="cc-exp-month"
                    max-length="2"
                    id="cardExpirationMonth"
                    class="offscreen"
                    tabindex="-1"
                />
            `)(scope)
        );

        $("form").append(
            this.$compile(`
                <input
                    type="hidden"
                    name="expyear"
                    autocomplete="cc-exp-year"
                    x-autocomplete="cc-exp-year"
                    max-length="4"
                    id="cardExpirationYear"
                    class="offscreen"
                    tabindex="-1"
                />
            `)(scope)
        );
    }

    formatExpiryText(value: string): string {
        if (value.length > this.maxLength) {
            value = value.substring(0, this.maxLength);
        }
        var valueFormatted = (jQuery.payment as JQueryPayment.ExpressPayment).formatExpiry(value);
        if(valueFormatted && valueFormatted.length === 9){
            var split = valueFormatted.split(' / ');
            valueFormatted = split[0] + ' / ' + split[1].substring(2,4);
        }

        return valueFormatted;
    }
}