import { ExpressCheckoutService } from "../../core/index";


export class SmsService {
    private _sessionToken: string;


    static $inject: string[] = ["ExpressCheckoutService", "$stateParams"];

    public constructor(private _expressCheckoutService: ExpressCheckoutService, private $stateParams) {
        this._sessionToken = $stateParams.token;
    }

    sendPaymentConfirmationSms(): ng.IPromise<Server.smsresponse> {
        const promise = this._expressCheckoutService.sendPaymentConfirmationSms(this._sessionToken);
        return promise;
    }

    sendMembershipConfirmationSms(): ng.IPromise<Server.smsresponse> {
        const promise = this._expressCheckoutService.sendMembershipConfirmationSms(this._sessionToken);
        return promise;
    }
}