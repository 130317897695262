export class MoveToNextOnValid {
    restrict = "A";
    require = "ngModel";
    static $inject = ["$timeout"];

    public constructor(private $timeout) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel: ng.INgModelController) => {
        scope.next = () => {
            return ngModel.$valid;
        };
        scope.$watch(scope.next,
        (newValue: boolean, oldValue: boolean) => {

            if (newValue && ngModel.$dirty) {

                if (attrs.becMoveToNextOnValid &&
                 (!attrs.becMoveToNextOnValidLength ||
                 (attrs.becMoveToNextOnValidLength &&
                 ngModel.$modelValue.length.toString() === attrs.becMoveToNextOnValidLength))) {
                    this.$timeout(() => {
                        jQuery(attrs.becMoveToNextOnValid).focus();
                    });
                } else {
                    const nextinput = element.nextAll("input");
                    if (nextinput && nextinput.length >= 1) {
                        nextinput[0].focus();
                    }
                }
            }
        });
    };
}