// CSS dependencies
import "normalize.css";

import base from "./styles/base.scss";
import 'angular/angular-csp.css';

const styles = {
    base: base
};

// External dependencies
import * as jQuery from "jquery";
import * as angular from "angular";
import * as WebFont from "webfontloader";
import "angular-animate";
import "angular-aria";
import "angular-messages";
import "angular-sanitize";
import "angular-gettext";
import "angular-local-storage";
import "angular-ui-router";
import "ngstorage";
import "autonumeric";


// Internal dependencies
import * as appStates from "./app.states";
import {
    ConfigReaderService,
    addComponentToModule
} from "./core/index";

import * as core from "./core/core.module";
import * as shared from "./shared/shared.module";
import * as paymentCard from "./payment-card/payment-card.module";
import * as invoice from "./invoice/invoice.module";
import * as demo from "./demo/demo.module";
import * as wallet from "./wallets/wallet.module";
import * as acquirer from "./acquirers/acquirer.module";
import * as paymentRequest from "./payment-request/payment-request.module";

import * as test from "./test/test.component";
import { AppComponent } from "./app.component";


// Declare module
export const moduleName = "becApp";

export const app = angular.module(moduleName, [
    "ngAnimate",
    "ngAria",
    "ngMessages",
    "ngSanitize",
    "gettext",
    "LocalStorageModule",
    "ui.router",
    "ngStorage",
    core.moduleName,
    shared.moduleName,
    paymentCard.moduleName,
    invoice.moduleName,
    demo.moduleName,
    wallet.moduleName,
	acquirer.moduleName,
	paymentRequest.moduleName
]);

app.controller("TestController", test.TestController);

addComponentToModule(app, AppComponent);


// Configure module
app.config([
        "$compileProvider",
        "ConfigReaderServiceProvider",
        (
            $compileProvider: ng.ICompileProvider,
            configReaderServiceProvider: ConfigReaderService
        ) => {
            if (configReaderServiceProvider.environment === "Production") {
                $compileProvider.debugInfoEnabled(false);
            }

			$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|tel|swish):/);
        }
    ])
    .config([
        "$locationProvider", $locationProvider => {
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });
        }
    ])
    .config([
        "localStorageServiceProvider",
        localStorageServiceProvider => { // TODO: Deprecate angular-local-storage from checkout
            // read about settings: https://www.npmjs.com/package/angular-local-storage
            localStorageServiceProvider
                .setPrefix("BamboraCheckout")
                .setStorageCookie(0) // in case of fallback 0=never expires (days)
                .setStorageCookieDomain("");
        }
    ])
    .config([
        "$localStorageProvider", "$sessionStorageProvider", ($localStorageProvider: any,
            $sessionStorageProvider: any) => {
            $localStorageProvider.setKeyPrefix("BamboraCheckout");
            $sessionStorageProvider.setKeyPrefix("BamboraCheckout");
        }
    ])
    .run([
        "gettextCatalog",
        (gettextCatalog: ng.gettext.gettextCatalog) => gettextCatalog.setCurrentLanguage("")
    ]);


// Initialize states
app.config(appStates.onConfig);
app.run(appStates.onRun);


// Bootstrap app
angular.element(document).ready(
    () =>
        angular.bootstrap(
            document,
            [moduleName],
            { strictDi: true,  }
        )
    );

// Load fonts
WebFont.load({
    custom: {
        families: [ "FaktPro:n4,n5,n6" ],
        urls: ["https://static.bambora.com/assets/fonts/fonts.css"]
    }
});
