import * as _ from "underscore";
import { Component, SessionService, ErrorService } from "../../../core/index";
import { States } from "../../../app.states";
import { NordeaEPaymentFIService } from "../index";

import buttons from "../../../styles/buttons.scss";
import nordeaepaymentfistart from "./nordeaepaymentfi-start.scss";

const styles = {
    buttons: buttons,
    nordeaepaymentfistart: nordeaepaymentfistart
};

@Component({
    selector: "becNordeaepaymentfiStart",
    template: require("./nordeaepaymentfi-start.html")
})
export class NordeaEPaymentFIStart {
    styles = styles;

    static $inject = [
        "SessionService", "NordeaEPaymentFIService", "ErrorService", "$state"
    ];

    constructor(
        private sessionService: SessionService,
        private nordeaEPaymentFIService: NordeaEPaymentFIService,
        private errorService: ErrorService,
        private $state
    ) {

    }

    selectedBankOption: {bank: string, name: string} = null;

    bankOptions: Array<{bank: string, name: string}> = [
        { bank: "nordea",  name: "Nordea"},
        { bank: "op" , name: "OP"},
        { bank: "spankki", name: "S-Pankki"},
        { bank: "danskebank", name: "Danske Bank" },
        { bank: "alandsbanken", name: "Ålandsbanken" },
        { bank: "handelsbanken", name: "Handelsbanken"},
        { bank: "saastopankki", name: "Säästöpankki"},
        { bank: "omasp",  name: "Oma Säästöpankki"},
        { bank: "poppankki", name: "POP Pankki"}
    ];

    setSelectedBankOption(bankOption: {bank: string, name: string}) {
        if(this.selectedBankOption && this.selectedBankOption == bankOption) {
            this.selectedBankOption = null
        } else {
            this.selectedBankOption = bankOption;
        }
    }

    get SelectedBankOption() {
        return this.selectedBankOption;
    }

    continue() {
        this.nordeaEPaymentFIService.authorize(this.selectedBankOption.bank);
    }

    hasError() {
        return this.errorService.errorContext === States.Session.NordeaEPaymentFI.name;
    }
}
