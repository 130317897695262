import {
  AppStateService,
  SessionService,
  ExpressCheckoutService,
  WindowStateService
} from "../index";
import { CheckoutEvent, CardTypeResolve } from "@bambora/checkout-core-events";

export class FeeService {
  feeIsLoading: boolean;
  feeCalculated: boolean;
  paymentTypes: Array<Server.paymenttype> = [];
  private _selectedPaymentType: Server.paymenttype;
  private _lastUsedCardNumber: string = null;

  static $inject = [
    "$rootScope",
    "SessionService",
    "AppStateService",
    "ExpressCheckoutService",
    "WindowStateService",
    "$window"
  ];

  public constructor(
    private $rootScope: ng.IRootScopeService,
    private sessionService: SessionService,
    private appState: AppStateService,
    private checkoutService: ExpressCheckoutService,
    private windowService: WindowStateService,
    private $window: ng.IWindowService
  ) {}

  get selectedPaymentType() {
    return this._selectedPaymentType;
  }

  set selectedPaymentType(value) {
    if (
      !(this._selectedPaymentType === null && value === null) &&
      !(
        this._selectedPaymentType &&
        value &&
        this._selectedPaymentType.id === value.id
      )
    ) {
      this.appState.windowState.dispatcher.dispatch(
        CheckoutEvent.CardTypeResolve,
        value as CardTypeResolve
      );
    }

    this._selectedPaymentType = value;
  }

  get displayFee(): boolean {
    return (
      this.feeAmount > 0 &&
      this.appState.currentPaymentMethod() === "Paymentcard"
    );
  }

  setCardNumber(cardNumber: string): void {
    if (cardNumber === this._lastUsedCardNumber || this.feeIsLoading) return;

    if (!cardNumber || cardNumber.length < 8) {
      this.selectedPaymentType = null;
      this._lastUsedCardNumber = undefined;
      return;
    }

    this.feeCalculated = false;
    this.feeIsLoading = true;
    this._lastUsedCardNumber = cardNumber;
    this.paymentTypes = [];

    this.checkoutService
      .getPaymentType(
        this.sessionService.sessionToken,
        cardNumber.substring(0, 8)
      )
      .then(
        pt => {
          this.feeCalculated = true;

          if (!pt.meta.result) {
            this.selectedPaymentType = null;
            return;
          }

          this.paymentTypes = pt.paymenttypes;
          this.selectedPaymentType = this.paymentTypes[0];
        },
        reject => {
          this.selectedPaymentType = null;
        }
      )
      .finally(() => {
        this.feeIsLoading = false;
        this.feeCalculated = true;
      });
  }

  get feeAmount(): number {
    if (this.appState.currentPaymentMethod() !== "Paymentcard") return 0;

    if (!this.selectedPaymentType || !this.selectedPaymentType.fee) return 0;

    if (
      !this.selectedPaymentType.fee.addfee ||
      this.selectedPaymentType.fee.addfee.toLowerCase() === "default"
    ) {
      if (!this.sessionService.merchant.addfee) {
        return 0;
      } else {
        return this.selectedPaymentType.fee.amount;
      }
    }
    if (this.selectedPaymentType.fee.addfee.toLowerCase() === "yes")
      return this.selectedPaymentType.fee.amount;

    return 0;
  }
}
