import { Component } from "../../core/index";
import { InvoiceService } from "../index";


@Component({
    selector: "becSecciLink",
    template: `
        <a ng-disabled="!$ctrl.hasSecaUrl" ng-href="{{$ctrl.secaUrl}}" target="_blank">
            {{$ctrl.secciText}}
        </a>
    `
})
export class SecciLinkComponent {
    static $inject = [
        "gettextCatalog",
        "InvoiceService"
    ];

    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private invoiceService: InvoiceService
    ) {
    }

    get secciText() {
        return this.gettextCatalog.getString("Show the Standard European Consumer Credit Information");
    }

    get hasSecaUrl(): boolean {
        return this.secaUrl !== "";
    }

    get secaUrl(): string {
        return this.invoiceService.selectedPaymentOption ?
            this.invoiceService.selectedPaymentOption.termsurl : "";
    }
}