export class CardHolderName {
    require = "ngModel";

    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel: ng.INgModelController) => {

        ngModel.$validators.invalid = (modelValue, viewValue) => {
            if (!modelValue) return true;
            return modelValue.match(/^[a-zA-ZÀ-ÖØ-öø-ÿ\.' -]{2,45}$/);
        }
    }

    static Factory() {
        const directive = () => {
            return new CardHolderName();
        }
         return directive;
    }
}