import { States } from "../../app.states";
import {
    AppStateService,
    UrlService,
    SessionService,
    ExpressCheckoutService,
    ErrorService,
    CustomerService,
    ExternalAuthService,
    AuthorizeService
} from "../index";
import { PaymentCard } from "../interfaces/clientPaymentCard";

export interface IAuthorizeInfo {
    confirmationCode?: number[];
    usertoken?: string;
}


// Service for Checkouts using PaymentCard
export class PaymentCardService {
    static $inject: string[] = [
        "ExpressCheckoutService", "ExternalAuthService", "SessionService","AuthorizeService", "UrlService", "CustomerService",
        "AppStateService", "ErrorService", "gettextCatalog"
    ];

    paymentcard: PaymentCard;

    constructor(
        private expressCheckoutService: ExpressCheckoutService,
        private externalAuthService: ExternalAuthService,
        private sessionService: SessionService,
        private authorizeService: AuthorizeService,
        private urlService: UrlService,
        private customerService: CustomerService,
        private appState: AppStateService,
        private errorService: ErrorService,
        private gettextCatalog: ng.gettext.gettextCatalog) {

    }


    get isSmsCodeRequired() {
        return this.sessionService.authtype === "sms";
    }

    authorize() {
        this.appState.startFullScreenLoadingForAuthorize();
        let authorizePromise: ng.IPromise<Server.authorizeresponse>;
        if (this.sessionService.hasTruncatedCards()) {
            authorizePromise = this.authorizeService
                .authorizeTruncatedCard(this.getActivePaymentOption(),
                    { confirmationCode: this.customerService.confirmationCode },
                    !this.customerService.keepClientDetails);

        } else {
            authorizePromise = this.authorizeService
                .authorizePaymentCard(this.paymentcard,
                    this.customerService.rememberMe,
                    this.customerService.details);

        }

        return authorizePromise.then((res: Server.authorizeresponse) => {
            if (res.meta.action.code === Server.ActionCodeTypeEnum.ConfirmationCodeInvalid.toString()) {
                const errorMsg = this.gettextCatalog.getString("The code was not correct. Please type it again.");
                this.errorService.displayErrorMessage(errorMsg, States.Session.PaymentCard.name);
                this.sessionService.authtype = "sms";
                this.customerService.resetConfirmationCode();
                this.appState.cancelLoading();
                return res;
            }

            if (res.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString() ||
                res.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                return res;
            }

            if (res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresRedirectValidation.toString()) {
                return res;
            }

            if (res.meta.action.code === Server.ActionCodeTypeEnum.AuthorizeRequiresSmsValidation.toString()) {
                this.sessionService.authtype = "sms";
                this.appState.cancelLoading();
                return res;
            }

            if (!res.meta.result) {
                this.errorService.displayErrorMessage(res.meta.message.enduser, States.Session.PaymentCard.name);
                this.appState.cancelLoading();
                return res;
            }


            // Here we assume success*
            // test if is success
            this.sessionService.status = "authorized";
            this.appState.switchState(States.Session.PaymentCard.Accept.name);

            this.appState.cancelLoading();
            return res;

        });
    }

    getActivePaymentOption() {
        return this.sessionService.truncatedCards[0];
    }

    deleteActiveTruncatedCard() {
        this.expressCheckoutService.deleteTruncatedCard(this.sessionService.sessionToken, { truncatedcardid: this.getActivePaymentOption().paymentid })
        .then((response) => {
            if (response.meta.result) {
                this.sessionService.clearTruncatedCard();
                this.customerService.hasBeenRemembered = false;
                this.reset();
            } else {
                this.errorService.displayErrorMessage(response.meta.message.enduser, States.Session.PaymentCard.name);
            }
        });
    }

    reset() {
        this.paymentcard = {
            cardnumber: "",
            cvc: "",
            expiry: {
                year: null,
                month: null
            },
            cardholder: {
                name: null
            }
        };
    }
}