export class LanguageService {

    static $inject = [
        "gettextCatalog"
    ];

    // Constructor
    public constructor(private gettextCatalog: ng.gettext.gettextCatalog) {
    }


    // Methods
    setCurrentLanguage(languageIsoName: string): void {
        const languageInGetTextIsoFormat = this.convertToGetTextIsoFormat(languageIsoName);
        this.gettextCatalog.setCurrentLanguage(languageInGetTextIsoFormat);
    }

    changeLanguage(language): void {
        this.setCurrentLanguage(language);
    }

    getCurrentLanguage() {
        return this.convertToPrettyIsoFormat(this.gettextCatalog.getCurrentLanguage());
    }


    // Helper methods
    private convertToGetTextIsoFormat(languageIsoName: string) {
        if (languageIsoName == null || languageIsoName === "")
            return null;

        let getTextIsoFormat = languageIsoName.replace("-", "_");
        const arr = getTextIsoFormat.split("_");

        if (arr.length < 2)
            return languageIsoName.replace("nb", "no");

        getTextIsoFormat = arr[0].toLocaleLowerCase() + "_" + arr[1].toUpperCase();
        return arr[0].toLocaleLowerCase().replace("nb", "no"); // getTextIsoFormat;
    }

    private convertToPrettyIsoFormat(languageIsoName: string) {
        if (languageIsoName == null || languageIsoName === "")
            return null;

        const prettyIsoFormat = languageIsoName.replace("_", "-");
        return prettyIsoFormat;
    }
}