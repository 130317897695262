import { InvoiceService } from "../shared/index";

import input from "../../shared/components/input/input.scss";

const styles = {
    input: input
};

export class VerifySsnInput {
    private inputPaymentCodePrefix = "inputPaymentCode_";
    private static keyCode_backspace = 8;

    controller = VerifySsnInputController;
    controllerAs = "$ctrl";
    restrict = "E";
    require = "ngModel";
    template = require("./verify-ssn-input.html");
    replace = false;
    scope = { modelValue: "=ngModel", becFocusIf: "=?", context: "@context" };

    static $inject = [
        "gettextCatalog",
        "InvoiceService"
    ];
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private invoice: InvoiceService
    ) { }


    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel: ng.INgModelController) => {
        scope.styles = styles;
        scope.value = [];

        ngModel.$parsers.push((val) => {
            return val;
        });

        scope.$watch(
            "value",
            (val) => {
                scope.modelValue = val.join("");

                if (scope.modelValue && scope.modelValue.length > 0) {
                    ngModel.$setDirty();
                }

                ngModel.$setValidity(attrs.name, this.isConfirmationCodeValid(val));
            },
            true
        );

        scope.getValidationClasses = (invalid: boolean) => invalid ? styles.input.invalid : "";
        scope.getSsnSecuredPartLength = () => { return this.invoice.ssnSecuredPartLength; };
        scope.getSsnSecuredPartLengthTextual = () => {
            if(this.invoice.ssnSecuredPartLength === 4) { return this.gettextCatalog.getString("four");}
            if(this.invoice.ssnSecuredPartLength === 5) { return this.gettextCatalog.getString("five");}
        }
        scope.getDigitsClass = () => {
            if(this.invoice.ssnSecuredPartLength === 4) { return styles.input.digits4; }
            if(this.invoice.ssnSecuredPartLength === 5) { return styles.input.digits5; }
        }
    };

    private isConfirmationCodeValid(confirmationCode): boolean {
        if (confirmationCode.length !== this.invoice.ssnSecuredPartLength)
            return false;

        var result: boolean = true;
        jQuery.each(confirmationCode,
        (index, element) => { result = result && ((element as any) !== "") && !isNaN(element) });
        return result;
    }

}

export class VerifySsnInputController {
    static $inject = ["InvoiceService"];
    constructor(public invoice: InvoiceService) {}
}
