import {
    Component,
    AppStateService
} from "../core/index";


@Component({
    selector: "becInvoiceForm",
    template :
    `<form class="animate-heightAndFade"
           name="form"
           autocomplete="on"
           ng-model-options="{'allowInvalid' : true}"
           ng-show="!appState.girafVisible"
           novalidate>
        <ng-transclude></ng-transclude>
    </form>`,
    transclude: true
})
export class InvoiceFormController {
    static $inject = ["AppStateService"];

    public constructor(private appState: AppStateService) { }
}