import { Component } from "../../../core/index";

const styles = {
    checkmark: require("./checkmark.scss"),
    icons: require("../../../styles/icons.scss")
};


@Component({
    selector: "becCheckmark",
    template: `
        <div class="${styles.checkmark.checkmark} ${styles.checkmark.animate}" bec-offset-broadcast="loader">
            <ng-transclude class="${styles.checkmark.transclusionContainer}"></ng-transclude>

            <div class="${styles.checkmark.leftContainer}">
                <div class="${styles.checkmark.leftBorder}"></div>
            </div>

            <div class="${styles.checkmark.rightContainer}">
                <div class="${styles.checkmark.rightBorder}"></div>
            </div>
        </div>
    `,
    transclude: true
})
export class CheckmarkComponent { }