import input from "../../../../shared/components/input/input.scss";
import userRegistrationForm from "../../../../payment-card/shared/components/user-registration-form/user-registration-form.scss";

const styles = {
    input: input,
    userRegistrationForm: userRegistrationForm
};

export class PaymentSmsCodePrompt {
    private inputPaymentCodePrefix = "inputPaymentCode_";
    private static inputPaymentFocusDelayMs = 500;
    // must be a bit longer than the animation used to show this directive
    private static inputPaymentNextSmsCodeFieldDelayMs = 100;
    // if to low, then cursor jumps to next input-field BEFORE the number is inserted to the field
    private static keyCode_backspace = 8;

    restrict = "E";
    require = "ngModel";
    template = require("./payment-sms-code-prompt.html");
    replace = false;
    scope = { value: "=ngModel", focusIf: "=?" };
    static $inject = ["$timeout", "$window"];

    link = (scope, element: ng.IAugmentedJQuery, attrs, ngModel) => {
        scope.styles = styles;

        scope.$watch(
            "value",
            (val) => {
                if (val && val.length > 0) {
                    ngModel.$setDirty();
                }
                ngModel.$setValidity(attrs.name, this.isConfirmationCodeValid(val));
            },
            true
        );

        scope.getValidationClasses = (invalid: boolean) => invalid ? styles.input.invalid : "";
    };

    private isConfirmationCodeValid(confirmationCode): boolean {
        if (!confirmationCode || confirmationCode.length !== 6)
            return false;

        var result: boolean = true;
        jQuery.each(confirmationCode,
        (index, element) => { result = result && ((element as any) !== "") && !isNaN(element) });
        return result;
    }
}
