import * as _ from "underscore";
import { States } from "../../app.states";
import {
    Component,
    AppStateService,
    SessionService,
    CustomerService,
    ErrorService,
    MerchantService,
    Country
} from "../../core/index";
import { InvoiceService } from "../index";

import invoiceStart from "./invoice-start.scss";
import buttons from "../../styles/buttons.scss";
import radioButton from "../../shared/components/radio-button/radio-button.scss";

const styles = {
    invoiceStart: invoiceStart,
    buttons: buttons,
    radioButton: radioButton
};

@Component({
    selector: "becInvoiceStart",
    template: require("./invoice-start.html"),
    bindings: {
        context: "<"
    }
})
export class InvoiceStart {
    static $inject = [
        "SessionService", "AppStateService", "InvoiceService", "CustomerService", "ErrorService", "gettextCatalog", "MerchantService"
    ];

    styles = styles;
    showPartPaymentOptions = false;
    selectedPartPaymentOption: Server.paymentoption
    context: "invoice" | "partpayment" = "invoice";
    emailEdit = false;
    useMobileBankId= false;
    norway = Country.Norway;

    constructor(
        private session: SessionService,
        private appState: AppStateService,
        private invoice: InvoiceService,
        private customer: CustomerService,
        private errorService: ErrorService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private merchantService: MerchantService
    ) { }

    $onInit() {
        if (this.session.isDemo()) {
            this.customer.details.email = "janedoe@example.com";
        }
    }

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }

    get invoiceOptions(): Server.invoiceoptions {
        return this.session.invoiceOptions;
    }

    get name() {
        if (this.invoice.invoicableAddress) {
            return this.invoice.invoicableAddress.firstname + " " + this.invoice.invoicableAddress.lastname;
        }
        return "";
    }

    get ssn() {
        return this.customer.ssn || this.customer.ssnBirthPart;
    }

    isCountry(country: Country): boolean {
        return (this.merchantService.IsCountry(country));
    }

    onPartPaymentOptionSelected(option: Server.paymentoption) {
        this.selectedPartPaymentOption = option;
    }

    onSsnChange(ssn: string) {
        this.invoice.ssn = ssn;
    }
    onEmailChange(email: string) {
        this.invoice.invoiceEmail = email ? email : "";
    }

    deleteCustomer() {
        this.appState.startStateChange();
        this.session.deleteCustomer(true)
            .then(() => {
                this.appState.reload();
            });
    }

    continue() {
        this.invoice.setInvoiceContext(this.context);
        if (this.context === "invoice") {
            this.invoice.selectedPaymentOption = this.invoicePaymentOption;
        } else if (this.context === "partpayment") {
            this.invoice.selectedPaymentOption = this.selectedPartPaymentOption;
        }

        this.invoice.useMobileBankId = this.useMobileBankId;

        this.invoice.continueWithSelectedPaymentPlan();
    }

    get invoicePaymentOption(): Server.paymentoption {
        const paymentOption = _.first<any>(
            _.select<any>(
                this.invoiceOptions.paymentoptions,
                item => item.type === "Invoice"
            )
        );
        return paymentOption;
    }

    get selectedPaymentOption() {
        return this.invoice.selectedPaymentOption;
    }

    get order() {
        return this.session.order;
    }

    setInvoiceEmail(email: string) {
        this.invoice.invoiceEmail = email;
    }

    get invoiceEmail() {
        return this.invoice.invoiceEmail;
    }

    get invoiceEmailText() {
        return this.invoice.invoiceEmail ? this.invoice.invoiceEmail : this.gettextCatalog.getString("Your e-mail");
    }

    get hasBeenRememberedForInvoice() {
        return this.customer.hasBeenRememberedForInvoice;
    }

    get creditTermsUrl() {
        return this.invoice.creditTermsUrl;
    }

    hasInvoiceError(): boolean {
        return this.errorService.errorContext === States.Session.Invoice.name;
    }
}
