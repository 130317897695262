import { Accept } from "../../../shared";
import {
    Component,
    SessionService,
    WindowStateService,
    UrlService
} from "../../../core/index";

import styles from "./ekspres-bank-accept.scss";

@Component({
    selector: "becEkspresBankAccept",
    template: require("./ekspres-bank-accept.html")
})
export class EkspresBankAccept extends Accept {
    static $inject = [
        "SessionService",
        "UrlService",
        "$interval",
        "gettextCatalog",
        "WindowStateService"
    ];

    constructor(
        public session: SessionService,
        urlService: UrlService,
        $interval: ng.IIntervalService,
        gettextCatalog: ng.gettext.gettextCatalog,
        windowState: WindowStateService
    ) {
        super(session, urlService, $interval, gettextCatalog, windowState);

        this.session.status = "authorized";
        this.session.isAuthorized = true;
    }

    styles = styles;
}
