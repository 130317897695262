import { SmsService } from "../../index";


export class SmsResender {

    restrict = "E";
    template = require("./sms-resender.html");
    scope = {
        'sendMethod': "&"
    };
    static $inject = ["$timeout", "SmsService"];

    constructor(private $timeout, private smsService: SmsService) {

    }

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {
        scope.sentCount = 0;
        scope.smsError = false;
        scope.click = () => {
            scope.isSending = true;

            scope.sendMethod()
                .then((res) => {
                    scope.isSending = false;

                    if (!res.meta || !res.meta.result) {
                        scope.smsError = true;
                    } else {
                        scope.sentCount++;
                    }
                });
        };
    };
}