import { SessionService } from "../../core/index";


export class BecSessionInject {
    static $inject = ["SessionService"];

    public constructor(private session: SessionService) {}

    link = (scope, element, attrs) => {
        scope["session"] = this.session;
    };
}