import * as _ from "underscore";
import { Component, SessionService, ErrorService } from "../../../core/index";
import { States } from "../../../app.states";
import { ViaBillService } from "../index";

import buttons from "../../../styles/buttons.scss";
import viabillstart from "./viabill-start.scss";

const styles = {
    buttons: buttons,
    viabillstart: viabillstart
};

@Component({
    selector: "becViabillStart",
    template: require("./viabill-start.html")
})
export class ViaBillStart {
    styles = styles;

    static $inject = [
        "SessionService", "ViaBillService", "ErrorService", "$state"
    ];

    constructor(
        private sessionService: SessionService,
        private viaBillService: ViaBillService,
        private errorService: ErrorService,
        private $state
    ) {
    }

    continue() {
        this.viaBillService.authorize();
    }

    hasError() {
        return this.errorService.errorContext === States.Session.ViaBill.name;
    }
}
