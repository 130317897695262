import * as angular from "angular";

import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as wallets from "./index";

export const moduleName = "wallets";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

// Components
addComponentToModule(module, wallets.MobilePayStart);
addComponentToModule(module, wallets.VippsStart);
addComponentToModule(module, wallets.ApplePayStart);
addComponentToModule(module, wallets.GooglePayStart);
addComponentToModule(module, wallets.WalletAccept);

//Services
module.service("WalletService", wallets.WalletService)

