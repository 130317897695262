import $ from "jquery";
import * as angular from "angular";
import "velocity-animate";

import ripple from "./ripple.directive.scss";

const styles = {
    ripple: ripple
};

export class BecRipple {
  restrict = "A";
  static $inject = ["$parse"];

  public constructor(private $parse) { }

  link = (scope, element: ng.IAugmentedJQuery, attrs) => {
    if (navigator && navigator.appVersion && (navigator.appVersion.indexOf("MSIE") !== -1))
      return angular.noop;

    var color = element.attr("bec-ripple") || "dark";

    if (color == "rgba(255,255,255,0.2)") {
      color = "light"
    }

    if (color == "rgba(255,255,255,0.2)") {
      color = "dark"
    }

    var rippleContainer = $(`
        <div class="${styles.ripple.rippleContainer}">
        </div>
    `);

    element.append(rippleContainer);

    element
      .mousedown((event: JQueryEventObject) => {
        if (element.css("position") === "static") element.addClass(styles.ripple.rippleStatic);
        var rippleSize = element.width() > element.height() ? element.width() : element.height();
        var ripple = $(`
                    <div class="${styles.ripple.ripple} ${color === "dark" ? styles.ripple.rippleDark : styles.ripple.rippleLight}"></div>
                `);

        rippleContainer.append(ripple);

        var offsetX = event.clientX - element.offset().left;
        var offsetY = event.clientY - element.offset().top;

        ripple.velocity(
          {
            width: [rippleSize * 3, 0],
            height: [rippleSize * 3, 0],
            top: [offsetY - (rippleSize * 1.5), offsetY],
            left: [offsetX - (rippleSize * 1.5), offsetX]
          },
          400,
          "ease-out",
          () => {
            ripple.velocity({ opacity: [0, 1] }, 200, "ease-in-out", () => { ripple.remove() });
          });
      });
  };
}
