export class PaymentCardCvc {

    restrict = "A";
    require = "ngModel";
    replace = false;
    defaultCvcLength = 4;

    scope = {
        cardnumber: "=cardnumber"
    };


    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel) => {

        ngModel.$parsers.push((value) => {
            var transformedInput = value.replace(/[^0-9]/g, "").trim();

            if (transformedInput !== value) {
                ngModel.$setViewValue(transformedInput);
                ngModel.$render();
            }
            return transformedInput;
        });

        scope.$watch("cardnumber",
        (newValue, oldValue) => {

            var cvcLength = this.getCardCvcLength(newValue);
            element.attr("maxlength", cvcLength);
        });
    };
    
    private getCardCvcLength(cardNumber: string): number {

        if (!cardNumber) return this.defaultCvcLength;

        const typeName = jQuery.payment.cardType(cardNumber) || "unknown";
        const card = this.cardFromType(typeName);

        if (!card) return this.defaultCvcLength;

        return (Math.max as any).apply(Math, card.cvcLength);
    }

    private cardFromType(typeName) {
        let card: JQueryPayment.CardInfo;
        let i: number;
        let len: number;

        for (i = 0, len = jQuery.payment.cards.length; i < len; i++) {
            card = jQuery.payment.cards[i];
            if (card.type === typeName) {
                return card;
            }
        }

        return null;
    }
    
    static Factory() {
        const directive = () => {
            return new PaymentCardCvc();
        };

        return directive;
    }
}