import * as angular from "angular";
import "@bambora/paymentcard-js";
import * as core from "../core/core.module";
import * as shared from "../shared/shared.module";

import { getFactoryFor, addComponentToModule } from "../core/index";

import * as pC from "./index";

export const moduleName = "payment-card";
export const module = angular.module(moduleName, [
    core.moduleName,
    shared.moduleName
]);

// Components
addComponentToModule(module, pC.PaymentCardAccept);
addComponentToModule(module, pC.PaymentCardController);
addComponentToModule(module, pC.CreditCardFormCtrl);
addComponentToModule(module, pC.UserRegistrationForm);
addComponentToModule(module, pC.EnterManuallyComponent);

module
    .directive("becSmscodePrompt", getFactoryFor(pC.PaymentSmsCodePrompt))
    .directive("becSmsResender", getFactoryFor(pC.SmsResender))
    .directive("becAvailablePaymentTypesList", getFactoryFor(pC.AvailablePaymentTypesList))
    .directive("becOrderDetails", getFactoryFor(pC.OrderDetails))

    // Services
    .service("PhoneNumberService", pC.PhoneNumberService)
    .service("SmsService", pC.SmsService)

    // Directives
    .directive("becCountrycode", pC.Countrycode.Factory())
    .directive("becCreditCardNumber", pC.CreditCardNumber.Factory())
    .directive("becCreditCardType", getFactoryFor(pC.CreditCardType))
    .directive("becMoveToNextOnValid", getFactoryFor(pC.MoveToNextOnValid))
    .directive("becPaymentCardCvc", pC.PaymentCardCvc.Factory())
    .directive("becPaymentCardExpiry", getFactoryFor(pC.PaymentCardExpiry))
    .directive("becPhonenumber", pC.Phonenumber.Factory())
    .directive("becShowOnParam", getFactoryFor(pC.ShowOnParam))
    .directive("becSvgCountrycode", getFactoryFor(pC.BecSvgCountrycode))
    .directive("becTermsUrl", getFactoryFor(pC.BecTermsUrl))
    .directive("becCardholderName", getFactoryFor(pC.CardHolderName));