import {
    Component,
    CustomerService
} from "../../core/index";
import { InvoiceService } from "../shared/index";

import ssnVerify from "./ssn-verify.scss";
import buttons from "../../styles/buttons.scss";

const styles = {
    ssnVerify: ssnVerify,
    buttons: buttons
};

@Component({
    selector: "becInvoiceSsnVerify",
    template: require("./ssn-verify.html"),
    bindings: {
        context: "<"
    }
})
export class SsnVerifyController {
    static $inject = [
        "InvoiceService",
        "CustomerService",
        "gettextCatalog"
    ];

    styles = styles;
    context: "invoice" | "partpayment" = "invoice";

    constructor(
        private invoice: InvoiceService,
        private customer: CustomerService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) { }

    $onChanges({context}) {
        if (context) {
            this.context = context.currentValue;
        }
    }

    get ssn() {
        return this.invoice.ssn;
    }

    set ssn(value) {
            this.invoice.ssn = value;
    }

    verifySsn() {
        this.invoice.verifySsn();
    }

    ssnSecuredPartLenghtText() {
        if (this.invoice.ssnSecuredPartLength === 4) { return this.gettextCatalog.getString("four");}
        if (this.invoice.ssnSecuredPartLength === 5) { return this.gettextCatalog.getString("five");}
    }

    get ssnBirthPart() {
        return this.customer.ssnBirthPart;
    }
}
