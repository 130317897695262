import * as angular from "angular";
import { States } from "../../app.states";
import {
    UrlService,
    SessionService,
    ExpressCheckoutService,
    ExternalAuthService,
    FeeService,
    IAuthorizeInfo,
    AppStateService
} from "../index";
import { PaymentCard } from "../interfaces/clientPaymentCard";
import { DeepLinker } from "../utilities/deep-linker";

export class AuthorizeService {
    static $inject: string[] = [
        "SessionService",
        "ExpressCheckoutService",
        "$q",
        "$state",
        "ExternalAuthService",
        "UrlService",
        "FeeService",
        "AppStateService"
    ];

    constructor(
        private sessionService: SessionService,
        private expressCheckoutService: ExpressCheckoutService,
        private $q: ng.IQService,
        private $state,
        private externalAuthService: ExternalAuthService,
        private urlService: UrlService,
        private feeService: FeeService,
        private appState : AppStateService
    ) {

    }

    setExpressCheckout(setexpresscheckoutrequest: Server.checkoutrequest, apikey: string): ng.
    IPromise<Server.expresstokenresponse> {
        return this.expressCheckoutService.setExpressCheckout(setexpresscheckoutrequest, apikey);
    }

    authorizeTruncatedCard(truncatedpaymentcard: Server.truncatedpaymentcard,
        authObject: IAuthorizeInfo,
        deleteuser: boolean): angular.IPromise<Server.authorizeresponse> {
        const confirmationCode = authObject.confirmationCode ? authObject.confirmationCode.join("") : null;

        const request: Server.truncatedpaymentcardauthorizerequest = {
            truncatedcardid: truncatedpaymentcard.paymentid,
            confirmationcode: confirmationCode,
            usertoken: authObject.usertoken,
            deleteuser: deleteuser,

            accepturl: this.$state.href(States.Session.PaymentCard.ReturnFrom3DAccept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true }),
            declineurl: this.$state.href(States.Session.PaymentCard.ReturnFrom3DDecline.name,
            { token: this.sessionService.sessionToken, status: "decline" },
            { absolute: true }),
            browserinfomation: this.getBrowserInformation()
        } as any;

        if (this.feeService.selectedPaymentType && this.feeService.selectedPaymentType.fee)
        {
            request.paymenttypefeeid = this.feeService.selectedPaymentType.fee.id
        }

        return this.expressCheckoutService.authorizeTruncatedCard(this.sessionService.sessionToken, request)
            .then(this.handleAuthorizeCallBack);
    }

    authorizePaymentCard(paymentCard: PaymentCard, rememberMe?: boolean, customer?: Server.customer): angular.
    IPromise<Server.authorizeresponse> {
        if (paymentCard && !paymentCard.expiry) {
            paymentCard.expiry = { month: 0, year: 0 };
        }

        const request: Server.paymentcardauthorizerequest = {
            paymentcard: {
                cardnumber: paymentCard ? paymentCard.cardnumber : "",
                cvc: paymentCard && paymentCard.cvc ? paymentCard.cvc : "",
                expmonth: (paymentCard && paymentCard.expiry.month ? paymentCard.expiry.month : 0).toString(),
                expyear: (paymentCard && paymentCard.expiry.year
                    ? (paymentCard.expiry.year > 2000 ? paymentCard.expiry.year - 2000 : paymentCard.expiry.year)
                    : 0).toString(),
                cardholder: {
                    name: paymentCard && paymentCard.cardholder && paymentCard.cardholder.name ? paymentCard.cardholder.name : null
                }
            },
            rememberme: rememberMe,
            customer: customer,
            accepturl: this.$state.href(States.Session.PaymentCard.ReturnFrom3DAccept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true, inherit: true }),
            declineurl: this.$state.href(States.Session.PaymentCard.ReturnFrom3DDecline.name,
            { token: this.sessionService.sessionToken },
            { absolute: true, inherit: true }),
            browserinformation: this.getBrowserInformation()
        } as any;

        if (this.feeService.selectedPaymentType && this.feeService.selectedPaymentType.fee)
        {
            request.paymenttypefeeid = this.feeService.selectedPaymentType.fee.id
        }

        return this.expressCheckoutService.authorizePaymentCard(this.sessionService.sessionToken, request)
            .then(this.handleAuthorizeCallBack);
    }

    private handleAuthorizeCallBack = (response: Server.authorizeresponse, forceRedirect: boolean = false, deepLinker?: DeepLinker): ng.IPromise<Server.authorizeresponse> => {
        if (response.meta.result) {
            return this.externalAuthService.checkIfExtraValidationIsNeeded(response, forceRedirect, deepLinker)
                .then(validationResponse => {
                    if (validationResponse == null) {
                      return;
                    }

                    if (validationResponse.finalRedirect) {
                      this.urlService.setUrlFromAuthorize(validationResponse, this.sessionService.sessionToken);
                      this.urlService.setAuthorizeParams(validationResponse.authorizeresponseurl, this.sessionService.sessionToken);
                    }

                    this.appState.windowState.dispatcher.updateOptions({ acceptUrl: this.urlService.acceptUrl });

                    if (validationResponse.meta.action.code === Server.ActionCodeTypeEnum.Success.toString()
                    || validationResponse.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString()) {
                        this.sessionService.status = "authorized"; // Override status
                    }

                    if (validationResponse.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString()) {
                        this.appState.switchState(States.Session.Accept.name);
                        this.appState.cancelLoading();
                    }

                    return validationResponse;
                });
        } else {
            if (response.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                this.sessionService.status = "expired";
                this.appState.switchState(States.Session.Expired.name);
                this.appState.cancelLoading();
            }

            return this.$q.when(response);
        }
    };

    authorizeInvoicePaymentOption(
        ssn: string,
        invoiceId: string,
        distributionMethod: string,
        paymentType: string,
        officialAddress: Server.address = null,
        customer: Server.customer = null,
        invoiceEmail: string = null,
        customerevaluation: Server.customerevaluationanswers,
        invoiceType: string = null,
        usemobileauthentication: boolean = false
    ): angular.IPromise<Server.authorizeresponse> {
        // Setting the right accept and decline state dependent on invoice context.
        let acceptUrlState:string = "";
        let declineUrlState: string = "";
        acceptUrlState = paymentType === "invoice" ? States.Session.Invoice.Accept.name : States.Session.PartPayment.Accept.name;
        declineUrlState = paymentType === "invoice" ? States.Session.Invoice.Decline.name : States.Session.PartPayment.Decline.name;

        const request: Server.invoiceauthorizerequest = {
            customer: customer,
            distributionmethod: distributionMethod,
            officialaddress: officialAddress,
            invoicepaymentoptionid: invoiceId,
            ssn: ssn,
            paymenttype: paymentType,
            invoiceemail: invoiceEmail,
            customerevaluation: customerevaluation,
            accepturl: this.$state.href(acceptUrlState,
            { token: this.sessionService.sessionToken },
            { absolute: true, inherit: true }),
            declineurl: this.$state.href(declineUrlState,
            { token: this.sessionService.sessionToken },
            { absolute: true, inherit: true }),
            paymenttypefeeid: null,
            type: invoiceType,
            usemobileauthentication: usemobileauthentication
        };
        return this.expressCheckoutService.authorizeInvoicePaymentOption(this.sessionService.sessionToken, request)
            .then(this.handleAuthorizeCallBack);
    }

    authorizeEkspresBank(): angular.IPromise<Server.authorizeresponse> {
      const request: Server.ekspresbankauthorizerequest = {
          accepturl: this.$state.href(States.Session.EkspresBank.Accept.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          declineurl: this.$state.href(States.Session.EkspresBank.Decline.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          paymenttypefeeid: null
      };
      return this.expressCheckoutService.authorizeEkspresBank(this.sessionService.sessionToken, request)
          .then(this.handleAuthorizeCallBack);
    }

    authorizeSwish(phonenumber: string, returnTo: "browser" | "app", mode: "poll" | "app", deepLinker?: DeepLinker): angular.IPromise<Server.authorizeresponse> {
      const acceptUrl = this.$state.href(States.Session.Swish.Accept.name,
        { token: this.sessionService.sessionToken },
        { absolute: true, inherit: true });

      const waitUrl = this.$state.href(States.Wait.name,
        null,
        { absolute: true, inherit: true });

      const request: Server.swishauthorizerequest = {
          accepturl: acceptUrl,
          declineurl: this.$state.href(States.Session.Swish.Decline.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          appreturnurl: returnTo === "app" ? this.appState.options.appReturnUrl : waitUrl,
          paymenttypefeeid: null,
          phonenumber: phonenumber,
          mode: mode
      };
      return this.expressCheckoutService.authorizeSwish(this.sessionService.sessionToken, request)
          .then(response => this.handleAuthorizeCallBack(response, false, deepLinker));
    }

    authorizeViaBill(): angular.IPromise<Server.authorizeresponse> {
      const request: Server.viabillauthorizerequest = {
          accepturl: this.$state.href(States.Session.ViaBill.Accept.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          declineurl: this.$state.href(States.Session.ViaBill.Decline.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          paymenttypefeeid: null,
          waiturl: null
      };
      return this.expressCheckoutService.authorizeViaBill(this.sessionService.sessionToken, request)
          .then(response => this.handleAuthorizeCallBack(response, true));
    }

    authorizeNordeaEPaymentFI(bank: string): angular.IPromise<Server.authorizeresponse> {
      const request: Server.nordeaepaymentfiauthorizerequest = {
          accepturl: this.$state.href(States.Session.NordeaEPaymentFI.Accept.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          declineurl: this.$state.href(States.Session.NordeaEPaymentFI.Decline.name,
          { token: this.sessionService.sessionToken },
          { absolute: true, inherit: true }),
          paymenttypefeeid: null,
          bank: bank
      };
      return this.expressCheckoutService.authorizeNordeaEPaymentFI(this.sessionService.sessionToken, request)
          .then(response => this.handleAuthorizeCallBack(response, true));
    }

    authorizeWalletStepUp(data: Server.walletthreedinitiateresponse): angular.IPromise<Server.authorizeresponse> {
        return this.externalAuthService.checkIfExtraValidationIsNeeded({
            authorizeresponseurl: data.redirecturl,
            meta: { action: { code: Server.ActionCodeTypeEnum.AuthorizeRequiresPostValidation.toString(), source: "", type: ""}, message: { enduser: "", merchant: ""}, paging: { itemsreturned: null, lastevaluatedkey: ""}, result: true},
            accepturl: "",
            declineurl: "",
            method: "POST",
            parameters: data.parameters.map((p) => { return {key: p.name, value: p.value}})
        });
    }

    getBrowserInformation(): Server.browserinformation {
        let browserInformation: Server.browserinformation = {
            javaenabled: false,
            javascriptenabled: true,
			language: navigator.language ? navigator.language : "en-US",
			colordepth: screen.colorDepth ? screen.colorDepth : 24,
			screenheight: screen.availHeight ? screen.availHeight : 800,
			screenwidth: screen.availWidth ? screen.availWidth : 600,
			timezoneoffset: new Date().getTimezoneOffset()
        }
        return browserInformation;
    }
}
