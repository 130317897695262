import * as _ from "underscore";



export class BecAccordionList {
    static $inject = [];

    require = "becAccordionList";

    public constructor() {}

    link(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $ngModel: BecAccordionListController
    ) {
        $scope.$on("destroy", () => {
            $element.off("keydown", onKeyDown);
        });

        $element.keydown(onKeyDown);

        function onKeyDown(event: JQueryEventObject) {
            switch (event.which) {
                // Up, left arrows
                case 37: case 38: {
                    if (event.target.hasAttribute("bec-accordion-panel")) {
                        event.preventDefault();
                        $ngModel.getPrevious().item.focus();
                    } else if (event.ctrlKey) {
                        event.preventDefault();
                        $ngModel.getCurrentOrDefault().item.focus();
                    }
                    break;
                }

                // Down, right arrows
                case 39: case 40: {
                    if (event.target.hasAttribute("bec-accordion-panel")) {
                        event.preventDefault();
                        $ngModel.getNext().item.focus();
                    } else if (event.ctrlKey) {
                        event.preventDefault();
                        $ngModel.getCurrentOrDefault().item.focus();
                    }
                    break;
                }

                // Home
                case 36: {
                    if (event.target.hasAttribute("bec-accordion-panel")) {
                        event.preventDefault();
                        $ngModel.getFirst().item.click().focus();
                    }
                    break;
                }

                // End
                case 35: {
                    if (event.target.hasAttribute("bec-accordion-panel")) {
                        event.preventDefault();
                        $ngModel.getLast().item.click().focus();
                    }
                    break;
                }
            }
        }
    };

    controller = BecAccordionListController;
}

export interface IAccordionPanel {
    item: ng.IAugmentedJQuery;
    next: IAccordionPanel;
    previous: IAccordionPanel;
}

export class BecAccordionListController {
    private _panels = [];

    addPanel(panel: ng.IAugmentedJQuery) {
        const firstItem = this._panels[0] || null;
        const lastItem = this._panels[this._panels.length - 1] || null;

        const newItem = {
            item: panel,
            previous: lastItem,
            next: firstItem
        };

        if (!newItem.previous) newItem.previous = newItem;
        if (!newItem.next) newItem.next = newItem;

        newItem.next.previous = newItem;
        newItem.previous.next = newItem;

        this._panels.push(newItem);
    }

    removePanel(panel: ng.IAugmentedJQuery) {
        const item = _.find(this._panels, rBR => rBR.item === panel);

        if (!item) return;

        item.previous.next = item.next;
        item.next.previous = item.previous;

        return _.reject(this._panels, rBR => rBR === item);
    }

    getCurrentOrDefault(): IAccordionPanel {
        const focused = _.find(this._panels, (panel: IAccordionPanel) =>
            document.activeElement === panel.item[0]
        );

        if (focused) return focused;

        const expanded = _.find(this._panels, (panel: IAccordionPanel) =>
            panel.item.attr("aria-selected") === "true"
        );

        if (expanded) return expanded;

        return this._panels[0];
    }

    getNext(): IAccordionPanel {
        const currentItem = this.getCurrentOrDefault();

        return currentItem.next;
    }

    getPrevious(): IAccordionPanel {
        const currentItem = this.getCurrentOrDefault();

        return currentItem.previous;
    }

    getFirst(): IAccordionPanel {
        return _.first(this._panels);
    }

    getLast(): IAccordionPanel {
        return _.last(this._panels);
    }
}

export class BecAccordionPanel {
    require = "^becAccordionList";

    link(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $ngModel: BecAccordionListController
    ) {
        $ngModel.addPanel($element);

        $scope.$on("destroy", () => {
            $ngModel.removePanel($element);
        });
    };
}