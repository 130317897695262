import {
    SessionService,
    UrlService,
    WindowStateService
} from "../../core/index";

export class Accept {
    // Constructor
    constructor(public session: SessionService, private urlService: UrlService, $interval: ng.IIntervalService, private gettextCatalog: ng.gettext.gettextCatalog, private windowState: WindowStateService) {
        if (session.secondsTillTimeOfDelayedRedirectToAccept) {
            const intervalPromise = $interval(
                () => { if (!session.secondsTillTimeOfDelayedRedirectToAccept) $interval.cancel(intervalPromise); },
                1000
            );
        }
    }

    showDetails = false;

    get hasAcceptUrl(): boolean {
        return this.urlService.acceptUrl != null;
    }

    get countDownLabel(): string {
        return this.gettextCatalog.getPlural(
            this.session.secondsTillTimeOfDelayedRedirectToAccept,
            "Redirecting in {{secondsTillTimeOfDelayedRedirectToAccept}} second...",
            "Redirecting in {{secondsTillTimeOfDelayedRedirectToAccept}} seconds...",
            { secondsTillTimeOfDelayedRedirectToAccept: this.session.secondsTillTimeOfDelayedRedirectToAccept }
        )
    }

    get immediateRedirectToAccept() {
        return this.hasAcceptUrl && this.session.immediateRedirectToAccept;
    }

    hasOrderLines(): boolean {
        return this.session.order.lines.length > 0 && !this.windowState.isInline();
    }

    onBackPressed($event) {
        this.session.cancel($event);
    }

    get merchantName() {
        return this.session.merchant.name;
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
        const showingDetails = this.showDetails;
        window.setTimeout(function ()
        {
            let buttonId = showingDetails ? "acceptHideDetailsButton" : "acceptShowDetailsButton";
            document.getElementById(buttonId).focus();
        }, 0);
    }
}
