import * as angular from "angular";
import * as _ from "underscore";
import { States } from "../../app.states";
import {
    ExpressCheckoutService,
    AppStateService,
    SessionService,
    ErrorService
} from "../../core/index";


export interface ICustomerDetails {
    phonenumber: string;
    phonenumbercountrycode: string;
    email: string;
}

export class CustomerService {
    // Data Members
    confirmationCode: number[] = [];
    details: ICustomerDetails = {
        phonenumber: null,
        phonenumbercountrycode: null,
        email: null
    };

    /**
        * SSN - Only used for invoicing
        */
    ssn = "";
    private _ssnBirthPart: string;

    get ssnBirthPart(): string {
        return this._ssnBirthPart;
    }

    /**
        *  Dictates if the user has been remembered and are now saved (before 
        */
    hasBeenRemembered = false;

    /**
        * True if the user has just been created 
        */
    customerCreatedInThisSession: boolean = false;

    /**
        * Can be checked of by recurring users if they wish to be deleted
        */
    keepClientDetails: boolean = true;

    /**
        *  
        */
    lastUsedPaymentType: string;


    static $inject: string[] = [
        "$q", "ExpressCheckoutService", "$sessionStorage", "AppStateService", "SessionService", "ErrorService",
        "gettextCatalog"
    ];

    constructor(
        private $q,
        private expressCheckoutService: ExpressCheckoutService,
        private $sessionStorage: any,
        private appState: AppStateService,
        private sessionService: SessionService,
        private errorService: ErrorService,
        private gettextCatalog: ng.gettext.gettextCatalog
    ) {}

    get hasCustomer(): boolean {
        return !_.isEmpty(this.details) && !_.isEmpty(this.details.email) && !_.isEmpty(this.details.phonenumber);
    }

    get hasBeenRememberedForInvoice(): boolean {
        return this.hasBeenRemembered && this.ssnBirthPart && this.ssnBirthPart.length >= 6 && this.sessionService.order.currency != 'NOK'; //Collector do not support get address for NOK so the backend flow will break on "Forget me"
    }

    get hasBeenRememberedForPaymentCard(): boolean {
        return this.hasBeenRemembered && this.sessionService.hasTruncatedCards();
    }

    /**
    * rememberMe states wheather a customer wants to be saved
    */
    get rememberMe(): boolean {
        // Using sessionToken in the id ensures that it won't be enabled again, mostly during debugging
        return this.$sessionStorage[this.sessionService.sessionToken + "rememberMe"];
    }

    set rememberMe(value: boolean) {
        this.$sessionStorage[this.sessionService.sessionToken + "rememberMe"] = value;
    }

    initialize(session: Server.checkoutresponse) {
        this.customerCreatedInThisSession = false;
        this.hasBeenRemembered = false;
        this._ssnBirthPart = "";
        this.updateClient(session.customer);
    }

    private setPhoneNumberCountryCode(): void {
        if (!this.details.phonenumbercountrycode) {
            this.getIpInfo(this.sessionService.sessionToken)
                .then((cc) => {
                    if (!this.details.phonenumbercountrycode) {
                        this.details.phonenumbercountrycode = cc.country;
                    }
                });
        }
    }

    private getIpInfo(token : string): angular.IPromise<Server.ipinforesponse> {
        return this.expressCheckoutService.getSessionIpInfo(token);
    }

    createCustomer(): angular.IPromise<Server.createcustomerresponse> {
        if (!this.sessionService.isAuthorized) {
            return null;
        }
        const request: Server.createcustomerrequest = {
            customer: this.details
        };

        let promise =  this.sessionService.createCustomer(request)
            .then((res: Server.createcustomerresponse) => {
                if (res.meta.result) {
                    this.updateClient(res.customer);
                    this.customerCreatedInThisSession = true;
                    this.hasBeenRemembered = res.customer.alreadyremembered;
                }
                return res;
            },
            (res) => {
                return res;
            })
            .then((res) => {
                this.appState.cancelLoading();
                return res;
            });

        return promise;


    }

    updateClient(customer: Server.responsecustomer) {
        if (customer && (customer.email || customer.phonenumber)) {
            this.details.phonenumber = customer.phonenumber;
            this.details.phonenumbercountrycode = customer.phonenumbercountrycode;
            this.details.email = customer.email;
            this.hasBeenRemembered = customer.verificationsmssend;
            this.lastUsedPaymentType = customer.lastusedpaymenttype;
            this._ssnBirthPart = customer.ssnbirthpart;
        }

        this.setPhoneNumberCountryCode();
    }


    deleteCustomer() {
        this.appState.startFullScreenLoadingWithMessage("");
        return this.sessionService.deleteCustomer()
            .then(() => this.sessionService.getSession(this.sessionService.sessionToken)
                .then((session) => {
                    this.appState.cancelLoading();
                    this.initialize(session);
                    this.appState.switchState(States.Session.PaymentCard.Start.name);
                }));
    }

    resetConfirmationCode() {
        this.confirmationCode = [];
    }
}