export class OffsetBroadcast {
    restrict = "A";

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {

        scope.$watch(
        () => element.offset().top,
        (val) => {
            scope.$root.$broadcast(`bec-position-update-${attrs["becOffsetBroadcast"]}`, val);
        });

    };
}

export class MatchOffsetTarget {
    restrict = "A";
    static $inject = ["$timeout"];

    constructor(private $timeout: ng.ITimeoutService) {}
    
    link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
        var debounceTime = 200;
        var timer: ng.IPromise<void>;
        scope.$root.$on(`bec-position-update-${attrs["becMatchOffsetTarget"]}`,
        (event, val) => {

            if (timer) {
                debounceTime = debounceTime > 100 ? 1 : debounceTime;
                this.$timeout.cancel(timer);
            }

            timer = this.$timeout(() => {
                    var marginTop = parseInt(element.css("margin-top").replace("px", ""));
                    var nextPos = val;

                    element.velocity("stop")
                        .velocity({ top: val - element.offset().top }, { duration: 400, easing: "ease-in-out" });

                },
                debounceTime);
        });
    };
}