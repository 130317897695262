import { LanguageService } from "../../core/index";


export class LanguageSelector {
    restrict = "AE";
    template = require("./language-selector.html");
    replace = false;
    
    constructor(private languageService: LanguageService) {}

    link = (scope, element: ng.IAugmentedJQuery, attrs) => {

        scope.currentLanguage = this.languageService.getCurrentLanguage();

        scope.languages = [
            {
                name: "English",
                code: ""
            }, {
                name: "Svenska",
                code: "sv-SE"
            }, {
                name: "Dansk",
                code: "da-DK"
            }, {
                name: "Norsk",
                code: "nb-NO"
            }, {
                name: "Suomi",
                code: "fi-FI"
            }, {
                name: "French",
                code: "fr-FR"
            }, {
                name: "Deutsch",
                code: "de-DE"
            }

        ];

        scope.changeLanguage = (lang: string) => {
            this.languageService.changeLanguage(lang);
        };
        scope.$watch("currentLanguage",
            (val) => {
                this.languageService.changeLanguage(scope.currentLanguage);
            },
            true);
    };
    
    static Factory() {
        const directive = (languageService) => {
            var c = new LanguageSelector(languageService);
            return c;
        };
        
        directive.$inject = ["LanguageService"];

        return directive;
    }
}