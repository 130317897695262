import {
    Component,
    ErrorService
} from "../../../core/index";

import styles from "./error-message.scss";

@Component({
    selector: "becErrorMessage",
    template: require("./error-message.html"),
    bindings: {
		messageStyle: "@",
		disableClose: "<"
    }
})
export class ErrorMessage {
    static $inject = ["ErrorService"];
    constructor(private errorService: ErrorService) { }

    styles = styles;

	messageStyle: string;
	disableClose: boolean;

    get errorMessageVisible() {
        return this.errorService.errorMessageVisible;
    }

	hideError() {
		if (!this.disableClose)
			this.errorService.hideError();
    }

	get errorMessage() {
        return this.errorService.errorMessage;
    }

    get messageStyleOrDefault() {
        if (!this.messageStyle) {
            this.messageStyle = "default";
        }
        return styles[this.messageStyle];
    }
}
